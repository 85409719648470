import Layout from "../Layout";
import "./checkout.css"
import {
    CourseBlock,
    HeaderBox, HeaderDescription, HeaderTitle, PageBlock, PrimaryText, SecondaryText
} from "./../../features/Atoms"
import { Grid, Box, Checkbox, FormControlLabel, Slider, Hidden, CircularProgress } from "@material-ui/core";
import coverImg from "./../../images/course_header.jpg";
import { useDispatch, useSelector } from 'react-redux'
import { loadAllMathsCourse } from "./../../DataStore/CourseReducer"
import { useEffect, useState } from "react";
import Data from "../../Data";
import course1 from "./../../images/course1.JPG"
import Api from "../../Api";
import myicon from "../../images/online-learning.png"
import deleteicon from "../../images/delete.png";
import axios from "axios";
import { Description, TextRotateUpOutlined } from "@material-ui/icons";
import { bookSchoolCourse } from "../../DataStore/SchoolCoursesReducer";
import mastercard from './masterCard.jpg'
import { Link } from 'react-router-dom'

export default function CheckOut(props) {



    useEffect(() => {

        const mydata = localStorage.getItem('Cartdata')
        console.log("this is my data ", data)


        if (mydata === null || mydata === `[]`) {
            setNoresult(true)
        } else {
            const ldata = JSON.parse([mydata])
            // setAuthdata(ldata)
            // const dodo = authdata.map((item)=> {
            //    return  item
            // })
            ldata.map((item) => {
                setNewdatame(item)
            });





            setData(ldata)

            console.log(ldata.length)
            if (ldata.length === 1) {

                const ids = ldata.map((item) => item.price);
                const sum = ids.reduce((a, b) => a + b, 0);
                setSubtotal(sum)
                const newt = subtotal
                setTotal(sum)
            } else {
                const ldata = JSON.parse([mydata])
                const mylastname = ldata[0].lastName
                const lastnames = ldata[1].lastName

                if (mylastname === lastnames) {
                    const ids = ldata.map((item) => item.price);
                    const sum = ids.reduce((a, b) => a + b, 0);
                    const adddiscount = ids.splice(1)
                    const sumdiscount = adddiscount.reduce((a, b) => a + b, 0);
                    const maindiscount = sumdiscount
                    // setDiscount(maindiscount)


                    // const percentage = maindiscount *5
                    const medis = 0 / 100 * sumdiscount
                    const newmedis = medis.toFixed(3)
                    setDiscount(newmedis)
                    setSubtotal(sum)
                    const mt = sum - newmedis
                    setTotal(mt)



                    console.log("my discount", sumdiscount, sum)












                } else {

                    const ldata = JSON.parse([mydata])
                    setData(ldata)
                    const ids = ldata.map((item) => item.price);
                    const sum = ids.reduce((a, b) => a + b, 0);
                    setSubtotal(sum)
                    const newt = subtotal
                    setTotal(sum)

                }


            }



        }



    }, [])



    const [filterQuery, setFilterQuery] = useState({});
    const courseListStatus = useSelector(state => state.course.loadAllMathsCourse && state.course.loadAllMathsCourse)
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false)
    const [data, setData] = useState([])
    const [subtotal, setSubtotal] = useState()
    const [discount, setDiscount] = useState()
    const [total, setTotal] = useState('')
    const [coupondata, setCoupondata] = useState(Data.Config.Coupon)

    const [cardName, setCardName] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [exp, setExp] = useState('')
    const [expmonth, setExpmonth] = useState('')
    const [expyear, setExpyear] = useState('')
    const [realexp, setRealexp] = useState('')
    const [scode, setScode] = useState('')
    const [noresult, setNoresult] = useState()
    const [discountlastname, setDiscountlastname] = useState()
    const [singlediscount, setSinglediscount] = useState()
    const [id, setId] = useState('')
    const [coupon, setCoupon] = useState('')
    const [showcouponbtn, setShowcouponbtn] = useState(true)
    const [errors, setErrors] = useState()
    const [mytitle, setMytitle] = useState(data.title)
    const [authdata, setAuthdata] = useState([])
    const [newdatame, setNewdatame] = useState()
    const [loader, setLoader] = useState('make payment')
    const [disable, setDisable] = useState(false)
    const [success, setSuccess] = useState(false)



    console.log("text", newdatame)

    // joint expmonth and expyear to make exp
    useEffect(() => {
        const newexp = expmonth + expyear
        setExp(newexp)
    }, [expmonth, expyear])
   



    const applyCoupon = (e) => {
        e.preventDefault();



        if (coupon === "abcd") {
            setTotal(total - 10)
            setShowcouponbtn(false)
        } else {
            alert("Coupon is invalid")
        }

    }


    const removeitem = () => {
        const index = data.findIndex(key => key.courseId === `${id}`);
        console.log(index)
        data.splice(index, 1);
        console.log(data);
        localStorage.setItem("allEntries", JSON.stringify(data));
        window.location.reload(false);


    }


    const makepayment = () => {


        let a = exp;
        var b = a.replace(/[/]/g, ''); // 1125, but a string, so convert it to number
        console.log(b)


        setLoader('processing payment...')
        const data = {
            cc: `${cardNumber}`,
            cvv: scode,
            expire: b,
            amount: `${total}`,
            cardName: cardName,
            description: newdatame.title,
            childName: newdatame.firstName,
            email: newdatame.parentEmail,


            firstName: newdatame.firstName,
            lastName: newdatame.lastName,
            userId: newdatame.userId,
            schoolId: newdatame.schoolId,
            courseId: newdatame.courseId,
            userId: newdatame.userId

        }
        console.log("this is working ", data)


        Api.Payment.create(data).then(function (res) {
            console.log("here", res.error)
            if (res.error) {
                console.log(res.error)
                res.error.map((items) => {
                    console.log(items.msg)
                    setErrors(items.msg)
                    setLoader('make payment')
                    setDisable(false)
                })
            } else {
                console.log(res.data)
                if (res.data === "Transaction was approved") {
                    console.log("Transaction was approved.")
                    setLoader('This transaction has been approved')

                    const data = {
                        "parentEmail": newdatame.parentEmail,
                        "firstName": newdatame.firstName,
                        "lastName": newdatame.lastName,
                        "userId": newdatame.userId,
                        "schoolId": newdatame.schoolId,
                        "courseId": newdatame.courseId,
                        "grade": newdatame.grade,
                        "addressLine1": newdatame.addressLine1,
                        "contactNo": newdatame.contactNo,
                        "studentAge": newdatame.studentAge
                    }

                    Api.Payment.bookslot(data).then(function (res) {
                        //console.log(res.data)
                        if (res.meta.message === "Course booked Successfully") {
                            setLoader('make payment')
                            alert("Thank you! Course booked Successfully")
                              setSuccess(true)
                            removeitem(setId(data.courseId))
                        }
                    })


                } else {
                    if (res.data === "Failed Transaction") {
                        console.log("This transaction was not approved.", res.data)
                        alert(`Your transaction was not Successful, ${res.meta.message}`)
                        setLoader('make payment')
                    }


                }
            }
        })



    }



    const calculatediscount = (item) => {
        //   const discountprice = item.price - discount
        //   setSinglediscount(discountprice)
    }

    useEffect(() => {
        if (!mounted) {
            dispatch(loadAllMathsCourse());
            setMounted(true)
            // alert("this is working")
        }
    }, [])

    const handleFilterChange = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        const newFilterQuery = { ...filterQuery }

        const queryFieldValues = filterQuery[fieldName] || []
        const newQueryFieldValues = e.target.checked
            ? [...queryFieldValues, fieldValue]
            : queryFieldValues.filter(item => item !== fieldValue);
        newFilterQuery[fieldName] = newQueryFieldValues;
        setFilterQuery({
            ...newFilterQuery
        })
        dispatch(loadAllMathsCourse(newFilterQuery));
    }

    return (
        <Layout className="detailsBlock" fillHeader style={{ background: '#f6f7f7', paddingTop: "7%" }}>



            {/* <div className="container uk-margin-large-top" >
                {
                    noresult === true ? (<div className="uk-height-medium uk-flex uk-flex-center uk-flex-middle">
                        <h3>No items in your cart</h3>
                    </div>

                    ) : (

                        <div className="row uk-margin-large-bottom">
                            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">


                                <h6>Order Summary</h6>
                                {
                                    data.map(item => (
                                        <div>

                                            <div class="uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-top uk-margin-small-bottom  " style={{ borderRadius: 10 }}>

                                                <div className="uk-grid" data-uk-grid>
                                                    <div className=" uk-width-1-5 " >
                                                        <div class="uk-text-left">
                                                            <img src={myicon} />
                                                        </div>
                                                    </div>

                                                    <div className=" uk-width-1-2 uk-margin-small-top " >
                                                        <div class=" uk-text-left ">
                                                            <div>

                                                                <p style={{ fontSize: 14 }}>{item.title}</p>
                                                            </div>

                                                            <div>

                                                                <p style={{ fontSize: 14 }}>  Grade: {item.grade}</p>
                                                            </div>

                                                            <div>
                                                                <p style={{ fontSize: 14 }}>  Student name: {item.firstName} {item.lastName} </p>
                                                            </div>

                                                            <p style={{ color: 'red', fontSize: 14 }} onClick={() => removeitem(
                                                                setId(item.courseId)
                                                            )} class="uk-text-left">
                                                                Delete item
                                                            </p>


                                                        </div>
                                                    </div>

                                                    <div className=" uk-text-center uk-margin-small-top " >
                                                        <p>${item.price}
                                                            {
                                                                discountlastname === item.lastName ? (
                                                                    calculatediscount(item)


                                                                ) : (null)
                                                            }


                                                        </p>
                                                    </div>

                                                    <div className=" uk-width-1-3  uk-margin-small-top " >


                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    )

                                    )
                                }



                            </div>


                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                               
                                <div>
                                    <div class="uk-grid-small" data-uk-grid>
                                        <p style={{ color: 'red', fontSize: 14 }}> {errors}</p>
                                        <div class="uk-width-1-1">
                                            <p className="fieldTitle">Card Number</p>
                                            <input
                                                value={cardNumber}
                                                onChange={(e) => setCardNumber(e.target.value)} className="uk-input customInput fieldTitle" type="text" placeholder="Card number" />
                                        </div>
                                        <div class="uk-width-1-1">
                                            <p className="fieldTitle"> Card Name</p>
                                            <input
                                                value={cardName}
                                                onChange={(e) => setCardName(e.target.value)} className=" fieldTitle uk-input customInput" type="text" placeholder="Card name" />
                                        </div>





                                        <div class="uk-width-1-2@s">
                                            <p className="fieldTitle">Expiration Date</p>
                                            <input
                                                value={exp}
                                                onChange={(e) => setExp(e.target.value)} className=" fieldTitle uk-input customInput" type="text" placeholder="05/22" />
                                        </div>
                                        <div class="uk-width-1-2@s">
                                            <p className="fieldTitle">Security Number</p>
                                            <input
                                                value={scode}
                                                onChange={(e) => setScode(e.target.value)} className="fieldTitle uk-input customInput" type="password" placeholder="CVV" />
                                        </div>

                                        <div className="uk-width-1-1@s uk-margin-top">
                                            <button onClick={makepayment} style={{ fontSize: '14px', backgroundColor: 'green', color: 'white', borderRadius: 5 }} class="uk-button  uk-width-1-1">{loader}</button>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>



                    )}

            </div> */}





     {
        success === true ? (
            <section>

    
            <div className='uk-container uk-margin-large-top uk-margin-large-bottom'>
                <div className='uk-flex-center uk-flex uk-margin-top'>
               <div className='formGrayBg  coursInfo'>
                    
          
  
            
  
  
            
                <div>
                <h6 className='uk-text-center'> Hello your purchase was successful</h6>
                </div>
  
                 <hr className='formLine '></hr>
  
                 
                <div className='uk-margin-large-top uk-text-center'>
                     
  
                </div>
  
  
                   <div className='uk-margin-large-top uk-margin-large-bottom'>
                 <div class="uk-flex-center  uk-flex ">
                       <Link to="/schools"><div class="btn  deepBlue uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Back to Courses</div> </Link>
                      <Link to='/'> <div class=" btn  uk-text-center uk-margin-left" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Exit</div></Link>
                      
                    </div>
                    </div>
            
                
                
  
  
  
               </div>
               </div>
            </div>
  
       
  
  
  
          </section>

        ) : (




            <div className="uk-margin-large-top  uk-container">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 uk-margin-top uk-margin-bottom" style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                <h5 className='uk-text-bold uk-margin-top' style={{textAlign:'left'}}>Make Payment</h5>
                <hr className='formLine '></hr>
                <div className='uk-flex-center uk-flex'><img src= {mastercard} className="masterCard"/></div>

                <div className="uk-margin-top uk-margin-bottom" style={{backgroundColor:'#ffffff', borderRadius:10}}>
                <p style={{ color: 'red', fontSize: 14 ,  marginRight:10 ,marginLeft:10, marginTop:10}}> {errors}</p>

                   <div class="input-container ic2 uk-margin-left uk-margin-right">
                    <input value={cardName}
                      onChange={(e) => setCardName(e.target.value)} id="cardname" class="input" type="text" placeholder=" " />
                      <div class="cut cut-short"></div>
                   <label for="email" class="placeholder">Name on Card</label>
                 </div>

                   <div class="input-container ic2 uk-margin-left uk-margin-right">
                    <input value={cardNumber}
                     onChange={(e) => setCardNumber(e.target.value)} id="cardnumber" class="input" type="number" placeholder=" " />
                    <div class="cut cut-short"></div>
                    <label for="FirstName" class="placeholder">Card Number</label>
                 </div>


                    <div className='uk-grid' data-uk-grid>
      <div className='uk-width-1-2 '>
             <div class="input-container ic2 uk-margin-left">
             <input value={expmonth}
            onChange={(e) => setExpmonth(e.target.value)}  id="email" class="input2" type="number" placeholder="MM" />
            <div class="cut cut-short"></div>
            <label for="lastName" class="placeholder2">Expiration Month</label>
           </div>
     </div>

     <div className='uk-width-1-2'>
                <div class="input-container ic2 uk-margin-right">
                <input value={expyear}
            onChange={(e) => setExpyear(e.target.value)}  id="email" class="input2" type="number" placeholder="YY" />
                <div class="cut cut-short"></div>
                <label for="lastName" class="placeholder2">Expiration Year</label>
            </div>
      </div>

        </div>

        <div className='uk-grid uk-margin-remove-top' data-uk-grid>

        <div className='uk-width-1-1'>
             <div class="input-container ic2 uk-margin-left uk-margin-right">
            <input value={scode}
            onChange={(e) => setScode(e.target.value)} id="email" class="input" type="password" placeholder=" " />
            <div class="cut cut-short"></div>
            <label for="lastName" class="placeholder">CVV</label>
           </div>
     </div>


        <div className='uk-flex uk-flex-center'>
   <div className=' submitDesc'>
       <h3>Your Total: ${total}  </h3>
       <p style={{fontSize:"14px",marginTop:"10px", lineHeight:"24px"}}>By clicking submit below you agree to the Cyberteck Terms & Conditions 
           and authorize payment for the amount listed above and any future payments</p>
   </div>
   </div>

        </div>

  

  

               <div className="uk-margin-left uk-margin-right uk-margin-bottom">
               <button onClick={makepayment}  value="Submit" className="submit">{loader}</button>
                
                </div>  
            

                </div>
                
                    
                </div>
                <div className="col-lg-3"></div>

            </div>


//           <div className='uk-container uk-margin-top'>
//           <div className='uk-flex-center uk-flex'>
//          <div className='formGrayPayment  coursInfo'>
           
//              <div className='uk-text-left'>
//               <h5 className='uk-text-bold '>Make Payment</h5>
//               <hr className='formLine '></hr>

//     <div className='uk-flex-center uk-flex'><img src= {mastercard} className="masterCard"/></div>


               
// <div class="form formWhitePayment uk-margin-top" >

// <p style={{ color: 'red', fontSize: 14 }}> {errors}</p>
//   <div class="input-container ic2">
//     <input value={cardName}
//     onChange={(e) => setCardName(e.target.value)} id="cardname" class="input" type="text" placeholder=" " />
//     <div class="cut cut-short"></div>
//     <label for="email" class="placeholder">Name on Card</label>
//   </div>

//   <div class="input-container ic2">
//     <input value={cardNumber}
//      onChange={(e) => setCardNumber(e.target.value)} id="cardnumber" class="input" type="number" placeholder=" " />
//     <div class="cut cut-short"></div>
//     <label for="FirstName" class="placeholder">Card Number</label>
//   </div>

  

//   <div className='uk-grid' data-uk-grid>
//      <div className='uk-width-1-2'>
//             <div class="input-container ic2">
//             <input value={expmonth}
//             onChange={(e) => setExpmonth(e.target.value)}  id="email" class="input2" type="number" placeholder="MM" />
//             <div class="cut cut-short"></div>
//             <label for="lastName" class="placeholder2">Expiration Month</label>
//            </div>
//      </div>

//      <div className='uk-width-1-2'>
//                 <div class="input-container ic2">
//                 <input value={expyear}
//             onChange={(e) => setExpyear(e.target.value)}  id="email" class="input2" type="number" placeholder="YY" />
//                 <div class="cut cut-short"></div>
//                 <label for="lastName" class="placeholder2">Expiration Year</label>
//             </div>
//       </div>

//   </div>



//   <div className='uk-grid uk-margin-remove-top' data-uk-grid>
//      <div className='uk-width-1-1'>
//             <div class="input-container ic2">
//             <input value={scode}
//             onChange={(e) => setScode(e.target.value)} id="email" class="input" type="password" placeholder=" " />
//             <div class="cut cut-short"></div>
//             <label for="lastName" class="placeholder">CVV</label>
//            </div>
//      </div>

    

//   <div className='uk-flex uk-flex-center'>
//   <div className=' submitDesc'>
//       <h3>Your Total: ${total}  </h3>
//       <p style={{fontSize:"14px",marginTop:"10px", lineHeight:"24px"}}>By clicking submit below you agree to the Cyberteck Terms & Conditions 
//           and authorize payment for the amount listed above and any future payments</p>
//   </div>
//   </div>



//   </div>
 

//   <button onClick={makepayment}  value="Submit" class="submit">{loader}</button>
// </div> 


//              </div>
        
      
//          </div>
//          </div>
//       </div>
        )
     }






        </Layout>
    );
}






