
import windows_icon from "./../images/windows-380x380.png"
import webcam_icon from "./../images/webCam-380x380.png"
import minecraft_icon from "./../images/mincraft-380x380.png"
import internate_icon from "./../images/internet-380x380.png"
import axios from 'axios'

export default {

   


    get: (type, key, field) => {
        return type[key]
            ? type[key][field] || null
            : null;
    },
    Strip: {
        publicKey: //'pk_live_51J0SkDIJV61VXnla9AfsFZuei5jPHf4SFtBFrOultPbhGR1OEfe8A7yVszczhKAN99wSZfr0SnGhjpBMTq66xE7200G3zxWRSn'
        'pk_test_51J0SkDIJV61VXnla2zDtUjuA3HKJ35SWDFXEgAAo3fdOPpk4wX8BByoPCql810KdIlBQzAkqyU2GSDRmCUrWVkV900Aqh2Qlzj'
    },

    Course_name: {
        'Lego Robotics Grades ( k-2)': {
            displayName: 'Lego Robotics Grades ( k-2)',
            
        },
        'Visual Programming with Scratch Grades (3-6)': {
            displayName: 'Visual Programming with Scratch Grades (3-6)',
            
        },
            '3D Animation with Minecraft 1-3': {
            displayName: '3D Animation with Minecraft (Grade 1-3)',
            
        },
        '3D Animation with Minecraft 4-7': {
            displayName: '3D Animation with Minecraft (Grade 4-7)',
           
        },
        '3D Character Modeling & Sculpting 1-3': {
            displayName: '3D Character Modeling & Sculpting (Grade 1-3)',
        },
        '3D Character Modeling & Sculpting 4-7': {
            displayName: '3D Character Modeling & Sculpting (Grade 4-7)',
        },
        '3D Game Production & Design 1-3': {
            displayName: '3D Game Production & Design (Grade 1-3)',
        },
        '3D Game Production & Design 4-7': {
            displayName: '3D Game Production & Design (Grade 4-7)',
        },
        'Adventure Game Coding with Scratch 1-3': {
            displayName: 'Adventure Game Coding with Scratch (Grade 1-3)',
        },
        'Adventure Game Coding with Scratch 4-7': {
            displayName: 'Adventure Game Coding with Scratch (Grade 4-7)',
        },
        'Arcade Game Coding with Scratch 1-3': {
            displayName: 'Arcade Game Coding with Scratch (Grade 1-3)',
        },
        'Arcade Game Coding with Scratch 4-7': {
            displayName: 'Arcade Game Coding with Scratch (Grade 4-7)',
        },
        'Game Design and C# Coding in Unity 4-7': {
            displayName: 'Game Design and C# Coding in Unity (Grade 4-7)',
        },
        'Minecraft Modding 1-3': {
            displayName: 'Minecraft Modding (Grade 1-3 )',
        },
        'Minecraft Modding 4-7': {
            displayName: 'Minecraft Modding (Grade 4-7 )',
        },
        'Roblox Adventure Game Creation 1-3': {
            displayName: 'Roblox Adventure Game Creation (Grade 1-3)',
        },
        'Roblox Adventure Game Creation 4-7': {
            displayName: 'Roblox Adventure Game Creation (Grade 4-7)',
        },
        'Roblox Obby Creation 1-3': {
            displayName: 'Roblox Obby Creation (Grade 1- 3)',
        },
        'Roblox Obby Creation 4-7': {
            displayName: 'Roblox Obby Creation (Grade 4- 7)',
        },
        'Roblox Imaginative Game Design 1-3': {
            displayName: 'Roblox Imaginative Game Design (Grade 1-3)',
        },
        
        'Roblox Imaginative Game Design 4-7': {
            displayName: 'Roblox Imaginative Game Design (Grade 4-7)',
        },
       
    },

   





    Location_type: {
        'ONLINE': {
            id: 'online',
            displayName: 'Online'
        },
        'OFFLINE': {
            id: 'offline',
            displayName: 'Offline'
        },
        'LIVE-INSTRUCTOR': {
            id: 'Live Instructor',
            displayName: 'Live Instructor'
        },
    },

    Coupon: {
        'abcd': {
            id: 'abcd',
            amount: '10'
        },
        'efgh': {
            id: 'efgh',
            amount: ''
        },
        
    },



    CourseType: {
        'VIRTUAL_ONE_ON_ONE': {
            id: 'VIRTUAL_ONE_ON_ONE',
            displayName: 'Virtual One on One'
        },
        'VIRTUAL_TECH_CAMP': {
            id: 'VIRTUAL_TECH_CAMP',
            displayName: 'Virtual Tech Camp'
        },
        'VIRTUAL_SMALL_GROUP': {
            id: 'VIRTUAL_SMALL_GROUP',
            displayName: 'Virtual Small Group'
        },
        'AT_SCHOOL_LOCATION': {
            id: 'AT_SCHOOL_LOCATION',
            displayName: 'At School Location'
        }
    },
    CourseCategory: {
        'COMPUTER_COURSE': {
            id: 'COMPUTER_COURSE',
            displayName: 'Computer Course'
        },
        'TECH_CAMP': {
            id: 'TECH_CAMP',
            displayName: 'Tech Camp'
        },
        'CODING_COURSE': {
            id: 'CODING_COURSE',
            displayName: 'Coding course'
        },
        'GAME_DEV_COURSE': {
            id: 'GAME_DEV_COURSE',
            displayName: 'Game dev course'
        },
        'MATHS_COURSE': {
            id: 'MATHS_COURSE',
            displayName: 'Maths course'
        }

    },
    ContactUsOptions: {
        'GENERAL': {
            id: 'GENERAL',
            displayName: 'General'
        }
    },
    SkillLevel: {
        'BEGINNER': {
            id: 'BEGINNER',
            displayName: 'Beginner'
        },
        'INTERMEDIATE': {
            id: 'INTERMEDIATE',
            displayName: 'Intermediate'
        },
        'EXPART': {
            id: 'EXPART',
            displayName: 'Expert'
        },
        'BEGINNER_INTERMEDIATE': {
            id: 'BEGINNER_INTERMEDIATE',
            displayName: 'Beginner-Intermediate'
        },
    },
    Grade: {
        '1-3': {
            id: '1-3',
            displayName: '1-3'
        },
        // 'k-2': {
        //     id: 'k-2',
        //     displayName: 'k-2'
        // },
        // '3-6': {
        //     id: '3-6',
        //     displayName: '3-6'
        // },
        // '4-5': {
        //     id: '4-5',
        //     displayName: '4-5'
        // },
        '4-7': {
            id: '4-7',
            displayName: '4-7'
        },
        // '8-13': {
        //     id: '8-13',
        //     displayName: '8-13'
        // }
    },
    

    Days: {
            'MONDAYS': {
            id: 'MONDAYS',
            icon: windows_icon,
            displayName: 'Mondays'
        },
        'TUESDAYS': {
            id: 'TUESDAYS',
            icon: windows_icon,
            displayName: 'Tuesdays'
        },
        'WESNESDAYS': {
            id: 'WESNESDAYS',
            icon: windows_icon,
            displayName: 'Wednesdays'
        },
        'THURDAYS': {
            id: 'THURDAYS',
            icon: windows_icon,
            displayName: 'Thurdays'
        },
        'FRIDAYS': {
            id: 'FRIDAYS',
            icon: windows_icon,
            displayName: 'Fridays'
        },

        'SATURDAYS': {
            id: 'SATURDAYS',
            icon: windows_icon,
            displayName: 'Saturdays'
        },
        'SUNDAYS': {
            id: 'SUNDAYS',
            icon: windows_icon,
            displayName: 'Sundays'
        },
    },


    Location: {
        'MONDAYS': {
        id: 'MONDAYS',
        icon: windows_icon,
        displayName: 'Mondays'
    },
    'TUESDAYS': {
        id: 'TUESDAYS',
        icon: windows_icon,
        displayName: 'Tuesdays'
    },
    'WESNESDAYS': {
        id: 'WESNESDAYS',
        icon: windows_icon,
        displayName: 'Wednesdays'
    },
    'THURDAYS': {
        id: 'THURDAYS',
        icon: windows_icon,
        displayName: 'Thurdays'
    },
    'FRIDAYS': {
        id: 'FRIDAYS',
        icon: windows_icon,
        displayName: 'Fridays'
    },

    'SATURDAYS': {
        id: 'SATURDAYS',
        icon: windows_icon,
        displayName: 'Saturdays'
    },
    'SUNDAYS': {
        id: 'SUNDAYS',
        icon: windows_icon,
        displayName: 'Sundays'
    },
},

    Requirements: {
        'WIN_MAC': {
            id: 'WIN_MAC',
            icon: windows_icon,
            displayName: 'Windows or MAC PC Only'
        },
        "INTERNATE": {
            id: 'INTERNATE',
            icon: internate_icon,
            displayName: 'Stable Internate Connection'
        },
        "WEBCAM": {
            id: 'WEBCAM',
            icon: webcam_icon,
            displayName: 'Webcam'
        },
        "MINCRAFT_JAVA": {
            id: 'MINCRAFT_JAVA',
            icon: minecraft_icon,
            displayName: 'Minecraft Java Edition'
        },
        "Roblox_Studio": {
            id: 'Roblox_Studio ',
            icon: minecraft_icon,
            displayName: 'Roblox Studio '
        },
    },
    State: {
        'DC':{
            id: 'DC',
            displayName: 'DC'
        },
        'VIRGINIA':{
            id: 'VIRGINIA',
            displayName: 'Virginia'
        },
        'FAIRFAX':{
            id: 'FAIRFAX',
            displayName: 'Fairfax'
        },
        'MONTGOMERY_COUNTY':{
            id: 'MONTGOMERY_COUNTY',
            displayName: 'Montgomery County'
        },
        'HOWARD_COUNTY':{
            id: 'HOWARD_COUNTY',
            displayName: 'Howard County'
        },
        'PRINCE_WILLIAM_COUNTY':{
            id: 'PRINCE_WILLIAM_COUNTY',
            displayName: 'Prince William County'
        },
    },
    BlogCategory: {
        'UNCATEGORIZED': {
            id: 'UNCATEGORIZED_BLOG',
            displayName: 'Uncategorized'
        },
        'MINECRAFT': {
            id: 'MINECRAFT_BLOG',
            displayName: 'Minecraft'
        },
        'MATH': {
            id: 'MATH',
            displayName: 'Math'
        },
        'ROBLOX': {
            id: 'ROBLOX',
            displayName: 'Roblox'
        },
        'COMPUTER_COURSES': {
            id: 'COMPUTER_COURSES',
            displayName: 'Computer Courses'
        },
        'BENIFITS_OF_ONLINE_EDUCATION': {
            id: 'BENIFITS_OF_ONLINE_EDUCATION',
            displayName: 'Benefits of Online Education'
        },
        'HOW_TO_LEARN': {
            id: 'HOW_TO_LEARN',
            displayName: 'How to learn'
        },
        'TUTORING': {
            id: 'TUTORING',
            displayName: 'Tutoring'
        }
    },

    
        Schools: {
            'ALEXANDRIA COUNTRY DAY SCHOOL':{id: 'ALEXANDRIA_COUNTRY_DAY_SCHOOL', displayName:'ALEXANDRIA COUNTRY DAY SCHOOL'},
            'ARLINGTON SCIENCE FOCUS':{id: 'ARLINGTON_SCIENCE_FOCUS', displayName:'ARLINGTON SCIENCE FOCUS'},
            'ATHOLTON':{id: 'ATHOLTON', displayName:'ATHOLTON'},
            'BALLS BLUFF ELEMENTARY':{id: 'BALLS_BLUFF_ELEMENTARY', displayName:'BALLS BLUFF ELEMENTARY'},
            'BANNACKBURN ELEMENTARY':{id: 'BANNACKBURN ELEMENTARY', displayName:'BANNACKBURN ELEMENTARY'},
            'BELLE VIEW':{id: 'BELLE_VIEW', displayName:'BELLE VIEW'},
            'BELLOWS SPRING':{id: 'BELLOWS_SPRING', displayName:'BELLOWS SPRING'},
            'BEN LOMOND':{id: 'BEN_LOMOND', displayName:'BEN LOMOND'},
            'BETHESDA':{id: 'BETHESDA', displayName:'BETHESDA'},
            'BEVERLY FARMS':{id: 'BEVERLY_FARMS', displayName:'BEVERLY FARMS'},
            'BRADLEY HILLS':{id: 'BRADLEY_HILLS', displayName:'BRADLEY HILLS'},
            'BRENT':{id: 'BRENT', displayName:'BRENT'},
            'BRENT PLACE APARTMENT':{id: 'BRENT_PLACE_APARTMENT', displayName:'BRENT PLACE APARTMENT'},
            'BRYANT WOODS':{id: 'BRYANT_WOODS', displayName:'BRYANT WOODS'},
            'BURNING TREE':{id: 'BURNING_TREE', displayName:'BURNING TREE'},
            'BURNT MILLS':{id: 'BURNT_MILLS', displayName:'BURNT MILLS'},
            'BUSHY PARK':{id: 'BUSHY_PARK', displayName:'BUSHY PARK'},
            'CANTERBURY WOODS ELEMENTARY':{id: 'CANTERBURY_WOODS_ELEMENTARY', displayName:'CANTERBURY WOODS ELEMENTARY'},
            'CARDEROCK SPRINGS ELEMENTARY':{id: 'CARDEROCK_SPRINGS_ELEMENTARY', displayName:'CARDEROCK SPRINGS ELEMENTARY'},
            'CHARLES BARRETELEMENTARY':{id: 'CHARLES_BARRET_ELEMENTARY', displayName:'CHARLES BARRET ELEMENTARY'},
            'CHARLES BARRETT RECREATION CENTER':{id: 'CHARLES_BARRETT_RECREATION_CENTER', displayName:'CHARLES BARRETT RECREATION CENTER'},
            'Chevy Chase':{id: 'Chevy_Chase', displayName:'Chevy Chase'},
            'CHURCHILL':{id: 'CHURCHILL', displayName:'CHURCHILL'},
            'CHURCHILL FALL':{id: 'CHURCHILL FALL', displayName:'CHURCHILL FALL'},
            'Claremont Immersion School':{id: 'Claremont Immersion School', displayName:'Claremont Immersion School'},
            'Cleveland Park':{id: 'Cleveland Park', displayName:'Cleveland Park'},
            'COLLEGE GARDENS':{id: 'COLLEGE GARDENS', displayName:'COLLEGE GARDENS'},
            'CREIGHTONS CORNER':{id: 'CREIGHTONS CORNER', displayName:'CREIGHTONS CORNER'},
            'Cresthaven Elem':{id: 'Cresthaven Elem', displayName:'Cresthaven Elem'},
            'CROSSFIELD ELEMENTARY':{id: 'CROSSFIELD ELEMENTARY', displayName:'CROSSFIELD ELEMENTARY'},
            'DALY ELEMENTARY':{id: 'DALY ELEMENTARYY', displayName:'DALY ELEMENTARY'},
            'Dayton Oaks':{id: 'Dayton Oaks', displayName:'Dayton Oaks'},
            'DIAMOND':{id: 'DIAMOND', displayName:'DIAMOND'},
            'DISCOVERY - ARLINGTON':{id: 'DISCOVERY - ARLINGTON', displayName:'DISCOVERY - ARLINGTON'},
            'DOMINION TRAIL':{id: 'DOMINION TRAIL', displayName:'DOMINION TRAIL'},
            'Dulles South Recreation & Community Center':{id: 'Dulles South Recreation & Community Center', displayName:'Dulles South Recreation & Community Center'},
            'ELKRIDGE':{id: 'ELKRIDGE', displayName:'ELKRIDGE'},
            'EXCELLENCE CHRISTIAN SCHOOL':{id: 'EXCELLENCE CHRISTIAN SCHOOL', displayName:'EXCELLENCE CHRISTIAN SCHOOL'},
            'FALLS CHURCH CMMUNITY CENTER':{id: 'FALLS CHURCH CMMUNITY CENTER', displayName:'FALLS CHURCH CMMUNITY CENTER'},
            'Ferdinand T Day':{id: 'Ferdinand T Day', displayName:'Ferdinand T Day'},
            'Fifer Learning Center':{id: 'Fifer Learning Center', displayName:'Fifer Learning Center'},
            'Fleet Elementary School':{id: 'Fleet Elementary School', displayName:'Fleet Elementary School'},
            'FOREST EDGE':{id: 'FOREST EDGE', displayName:'FOREST EDGE'},
            'Frances Hammond Middle':{id: 'Frances Hammond Middle', displayName:'Frances Hammond Middle'},
            'FRANCIS HAZEL REID':{id: 'FRANCIS HAZEL REID', displayName:'FRANCIS HAZEL REID'},
            'Gaithersburg':{id: 'Gaithersburg', displayName:'Gaithersburg'},
            'GORMAN CROSSING':{id: 'GORMAN CROSSING', displayName:'GORMAN CROSSING'},
            'GREAT FALLS ELEMENTARY':{id: 'GREAT FALLS ELEMENTARY', displayName:'GREAT FALLS ELEMENTARY'},
            'Harmony':{id: 'Harmony', displayName:'Harmony'},
            'HARMONY MIDDLE':{id: 'HARMONY MIDDLE', displayName:'HARMONY MIDDLE'},
            'HEARST':{id: 'HEARST', displayName:'HEARST'},
            'HILL CENTER':{id: 'HILL CENTER', displayName:'HILL CENTER'},
            'HILLSIDE ELEMENTARY':{id: 'HILLSIDE ELEMENTARY', displayName:'HILLSIDE ELEMENTARY'},
            'Hoffman-Boston Elementary School':{id: 'Hoffman-Boston Elementary School', displayName:'Hoffman-Boston Elementary School'},
            'Howard County RECenter':{id: 'Howard County RECenter', displayName:'Howard County RECenter'},
            'Howard County RECenter':{id: 'Howard County RECenter', displayName:'Howard County RECenter'},
            'HUNT VALLEY':{id: 'HUNT VALLEY', displayName:'HUNT VALLEY'},
            'IDA LEE':{id: 'IDA LEE', displayName:'IDA LEE'},
            'Janney Elementary':{id: 'Janney Elementary', displayName:'Janney Elementary'},
            'KENT GARDENS':{id: 'KENT GARDENS', displayName:'KENT GARDENS'},
            'LAFAYETTE ELEMENTARY':{id: 'LAFAYETTE ELEMENTARY', displayName:'LAFAYETTE ELEMENTARY'},
            'LANE ELEMENTARY':{id: 'LANE ELEMENTARY', displayName:'LANE ELEMENTARY'},
            'Laurel Ridge Elementary School':{id: 'Laurel Ridge Elementary School', displayName:'Laurel Ridge Elementary School'},
            'LIBERTY':{id: 'LIBERTY', displayName:'LIBERTY'},
            'LISBON':{id: 'LISBON', displayName:'LISBON'},
            'LONGFELLOW':{id: 'LONGFELLOW', displayName:'LONGFELLOW'},
            'LOUDOUN VALLEY COMMUNITY CENTER':{id: 'LOUDOUN VALLEY COMMUNITY CENTER', displayName:'LOUDOUN VALLEY COMMUNITY CENTER'},
            'LUCKETTS':{id: 'LUCKETTS', displayName:'LUCKETTS'},
            'MACARTHUR':{id: 'MACARTHUR', displayName:'MACARTHUR'},
            'Manor Woods':{id: 'Manor Woods', displayName:'Manor Woods'},
            'MARET':{id: 'MARET', displayName:'MARET'},
            'MATTAPONI ES':{id: 'MATTAPONI ES', displayName:'MATTAPONI ES'},
            'MAURY':{id: 'MAURY', displayName:'MAURY'},
            'MCKINLEY ELEMENTARY':{id: 'MCKINLEY ELEMENTARY', displayName:'MCKINLEY ELEMENTARY'},
            'MERCER MIDDLE SCHOOL':{id: 'MERCER MIDDLE SCHOOL', displayName:'MERCER MIDDLE SCHOOL'},
            'Montessori Public School of Arlington':{id: 'Montessori Public School of Arlington', displayName:'Montessori Public School of Arlington'},
            'MOOREFIELD ELEMENTARY':{id: 'MOOREFIELD ELEMENTARY', displayName:'MOOREFIELD ELEMENTARY'},
            'MOUNT VERNON COMMUNITY SCHOOL':{id: 'MOUNT VERNON COMMUNITY SCHOOL', displayName:'MOUNT VERNON COMMUNITY SCHOOL'},
            'MT Vernon':{id: 'MT Vernon', displayName:'MT Vernon'},
            'NANNIE J LEE RECREATION CENTER':{id: 'NANNIE J LEE RECREATION CENTER', displayName:'NANNIE J LEE RECREATION CENTER'},
            'New Hampshire':{id: 'New Hampshire', displayName:'New Hampshire'},
            'NEW MARKET':{id: 'NEW MARKET', displayName:'MARNEW MARKETET'},
            'NORTH BETHESDA':{id: 'NORTH BETHESDA', displayName:'NORTH BETHESDA'},
            'NORTH BETHESDA Middle':{id: 'NORTH BETHESDA Middle', displayName:'NORTH BETHESDA Middle'},
            'NORTHFIELD':{id: 'NORTHFIELD', displayName:'NORTHFIELD'},
            'NOTTINGHAM':{id: 'NOTTINGHAM', displayName:'NOTTINGHAM'},
            'OAKLAND TERRACE':{id: 'OAKLAND TERRACE', displayName:'OAKLAND TERRACE'},
            'Oakton':{id: 'Oakton', displayName:'Oakton'},
            'Oakview':{id: 'Oakview', displayName:'Oakview'},
            'OLDE CREEK':{id: 'OLDE CREEK', displayName:'OLDE CREEK'},
            'OYSTER':{id: 'OYSTER', displayName:'OYSTER'},
            'Patrick Henry':{id: 'Patrick Henry', displayName:'Patrick Henry'},
            'PHILOMONT COMMUNITY CENTER':{id: 'PHILOMONT COMMUNITY CENTER', displayName:'PHILOMONT COMMUNITY CENTER'},
            'POINTERS RUN':{id: 'POINTERS RUN', displayName:'POINTERS RUN'},
            'POLK ELEMENTARY':{id: 'POLK ELEMENTARY', displayName:'POLK ELEMENTARY'},
            'POTOMAC COMMUNITY CENTER':{id: 'MAPOTOMAC COMMUNITY CENTERRET', displayName:'POTOMAC COMMUNITY CENTER'},
            'POTOMAC ELEMENTARY':{id: 'POTOMAC ELEMENTARY', displayName:'POTOMAC ELEMENTARY'},
            'PRAGATHI VIDYALAYA- Rachel Carson':{id: 'PRAGATHI VIDYALAYA- Rachel Carson', displayName:'PRAGATHI VIDYALAYA- Rachel Carson'},
            'PRAGATHI VIDYALAYA- Stone Hill':{id: 'PRAGATHI VIDYALAYA- Stone Hill', displayName:'PRAGATHI VIDYALAYA- Stone Hill'},
            'PYLE MIDDLE':{id: 'PYLE MIDDLE', displayName:'PYLE MIDDLE'},
            'RITCHIE PARK':{id: 'RITCHIE PARK', displayName:'RITCHIE PARK'},
            'ROCK CREEK FOREST':{id: 'ROCK CREEK FOREST', displayName:'ROCK CREEK FOREST'},
            'ROCKBURN':{id: 'ROCKBURN', displayName:'ROCKBURN'},
            'Roscoe Nis':{id: 'Roscoe Nis', displayName:'Roscoe Nis'},
            'Roscoe Nix':{id: 'Roscoe Nix', displayName:'Roscoe Nix'},
            'ROSE VALLEY':{id: 'ROSE VALLEY', displayName:'ROSE VALLEY'},
            'Rouse Center':{id: 'Rouse Center', displayName:'Rouse Center'},
            'SAMUEL W TUCKER ELEMENTARY':{id: 'SAMUEL W TUCKER ELEMENTARY', displayName:'SAMUEL W TUCKER ELEMENTARY'},
            'ROSE VALLEY':{id: 'ROSE VALLEY', displayName:'ROSE VALLEY'},
            'Rouse Center':{id: 'Rouse Center', displayName:'Rouse Center'},
            'SAMUEL W TUCKER ELEMENTARY':{id: 'SAMUEL W TUCKER ELEMENTARY', displayName:'SAMUEL W TUCKER ELEMENTARY'},
            'SELDENS LANDING':{id: 'SELDENS LANDING', displayName:'SELDENS LANDING'},
            'SLEEPY HOLLOW':{id: 'SLEEPY HOLLOW', displayName:'SLEEPY HOLLOW'},
            'Somerset':{id: 'Somerset', displayName:'Somerset'},
            'SOUTH RUN REC CENTER':{id: 'SOUTH RUN REC CENTER', displayName:'SOUTH RUN REC CENTER'},
            'SPRING HILL COMMUNITY CENTER':{id: 'SPRING HILL COMMUNITY CENTER', displayName:'SPRING HILL COMMUNITY CENTER'},
            'ROCKBST ANN SCHOOLURN':{id: 'ST ANN SCHOOL', displayName:'ST ANN SCHOOL'},
            'ST PETERS':{id: 'ST PETERS', displayName:'ST PETERS'},
            'STEUART WELLER':{id: 'STEUART WELLER', displayName:'STEUART WELLER'},
            'STONE MILL':{id: 'STONE MILL', displayName:'STONE MILL'},
            'TAYLOR ELEMENTARY':{id: 'TAYLOR ELEMENTARY', displayName:'TAYLOR ELEMENTARY'},
            'THE LAB SCHOOL OF WASHINGTON':{id: 'THE LAB SCHOOL OF WASHINGTON', displayName:'THE LAB SCHOOL OF WASHINGTON'},
            'THE NEWTON SCHOOL':{id: 'THE NEWTON SCHOOL', displayName:'THE NEWTON SCHOOL'},
            'THOMAS JEFFERSON':{id: 'THOMAS JEFFERSON', displayName:'THOMAS JEFFERSON'},
            'THUNDER HILL':{id: 'THUNDER HILL', displayName:'THUNDER HILL'},
            'Timber Lane':{id: 'Timber Lane', displayName:'Timber Lane'},
            'TRAVILAH':{id: 'TRAVILAH', displayName:'TRAVILAH'},
            'Victory Christian School':{id: 'Victory Christian School', displayName:'Victory Christian School'},
            'VIENNA REC CENTER':{id: 'VIENNA REC CENTER', displayName:'VIENNA REC CENTER'},
            'VIRGINIA ACADEMY':{id: 'VIRGINIA ACADEMY', displayName:'VIRGINIA ACADEMY'},
            'WAPLES MILL FALL':{id: 'WAPLES MILL FALL', displayName:'ROCWAPLES MILL FALLBURN'},
            'WAPLES MILL FALL':{id: 'WAPLES MILL FALL', displayName:'WAPLES MILL FALL'},
            'WATKINS':{id: 'WATKINS', displayName:'WATKINS'},
            'WAVERLY':{id: 'ROCKWAVERLYBURN', displayName:'WAVERLY'},
            'Weller Road':{id: 'Weller Road', displayName:'Weller Road'},
            'WESTBROOK ELEMENTARY':{id: 'WESTBROOK ELEMENTARY', displayName:'WESTBROOK ELEMENTARY'},
            'WESTGATE':{id: 'WESTGATE', displayName:'WESTGATE'},
            'WESTLAND MIDDLE':{id: 'WESTLAND MIDDLE', displayName:'WESTLAND MIDDLE'},
            'Wheaton Woods Elem':{id: 'Wheaton Woods Elem', displayName:'Wheaton Woods Elem'},
            'Whetstone':{id: 'Whetstone', displayName:'Whetstone'},
            'WOOD MIDDLE':{id: 'WOOD MIDDLE', displayName:'WOOD MIDDLE'},
            'WOODFIELD':{id: 'WOODFIELD', displayName:'WOODFIELD'},
            'WOODLIN ELEMENTARY':{id: 'WOODLIN ELEMENTARY', displayName:'WOODLIN ELEMENTARY'},
            'Yellow Springs Elementary':{id: 'Yellow Springs Elementary', displayName:'Yellow Springs Elementary'},
        }
    

};
