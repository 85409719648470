import React from "react";



const ThirdPane = () => (

    <div className="uk-container">

           <div className="uk-grid uk-grid-collapse" data-uk-grid>
                <div className="uk-width-1-5@s">

                </div>
                <div className="uk-width-3-5@s">
                   <div className="uk-margin-large-top">
                   <h3  className="header3 uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                      Why parents choose CyberTeck Academy to empower their children</h3>
            <p className="subtext uk-text-center uk-margin-top">We cater to your specific needs regarding tech education. Your time, 
            your needs, your money: we put a lot of thought into our products 
            and we’re sure you will find a fitting solution with us.
            </p>
                   </div>
              </div>

            <div className="uk-width-1-5@s">

           </div>
           </div>




</div>
    
)



export  default  ThirdPane