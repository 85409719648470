
import react from 'react';
import introImg1Tech from './../../../images/intro_img4.png';
import introImg2Tech from './../../../images/intro_img5.png';
import intro_img3 from './../../../images/intro_img3.png';
import intro_imgAtLoc from './../../../images/intro_imgAtLoc.JPG'
import  { Link } from 'react-router-dom'



const Second_Pane = () => (


    <div className="uk-container ">
                   {/****DESKTOP****/}
                  <div className="uk-visible@s  uk-margin-xlarge-top">

                  <div class="uk-text-center " data-uk-grid>
                        <div class="uk-width-1-2@s">
                        
                        <div class="introImg2Tech"><img src={introImg2Tech}  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/></div>
                        
                        </div>
                        <div class="uk-width-1-2@s "> 
                           
                        </div>
                  </div>

                  <div class="uk-text-center " data-uk-grid>
                        <div class="uk-width-1-3@s">
                        
                        
                        
                        </div>
                        <div class="uk-width-2-3@s uk-margin-small-top">
                            <div class="introImg1Tech uk-margin-small-top"><img src={introImg1Tech} uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/></div>
                        </div>
                  </div>

                  </div>




                  {/*****MOBILE******/}
                  <div className="uk-hidden@s  uk-margin-large-top uk-margin-large-bottom">
                              
                         <div className=''>
                            <img src= {intro_imgAtLoc} />
                         </div>
                          

                            </div>
</div>
);



export default Second_Pane;