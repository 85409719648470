import React, { useEffect, useState } from "react";
import { IconButton, Menu, MenuItem, Button } from "@material-ui/core";
import { connect } from 'react-redux'
import "./authentication.css";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FaceIcon from '@material-ui/icons/Face';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { login, logout, resumeSession } from "./../../DataStore/AuthReducer";
import AuthenticationModal from "./AuthenticationModal";
import { AsynCallLoading } from './../Atoms';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import QueueTwoToneIcon from '@material-ui/icons/QueueTwoTone';
import Api from '../../Api/index';

const Authentication = (props) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userInfo, setUserInfo] = useState(null)
  const [userType, setUserType] = useState(null)








 // console.log("this is loginData", userInfo)

  // const newdata ={
  //   name:userInfo.name,
  //   email:userInfo.email,
  //   userId:userInfo.sub,
  //   userType:userType
  // }

  // console.log("this is new data", newdata)

  // const setJwtToLocal = (newdata) => {
  //   localStorage.setItem("cyberteck_login", JSON.stringify(newdata));
  // };

  



  useEffect(() => {
    props.resumeSession()
  }, [])

  const handleUserMenuClick = (event) => {
    setShowUserMenu(!showUserMenu);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowUserMenu(false);
  };

  const loginSubmit = (values, action) => {
    setShowAuthModal(false);
    props.doLogin(values);
    action.setSubmitting(false);
  };

  const handleLogout = () => {
    handleClose();
    props.doLogout();
  }
  return (
    <>
   
            {/* <IconButton
              id="authButton"
              className="navLink"
              onClick={() => {
                setShowAuthModal(!showAuthModal);
              }}
            > */}
              <button
              onClick={() => {
                setShowAuthModal(!showAuthModal);
              }}
              className={props.className}
              style={props.style}
              >{props.label}
              </button>


            {/* </IconButton> */}
            <AuthenticationModal
              show={showAuthModal}
              onClose={() => { setShowAuthModal(false) }}
              loginSubmit={loginSubmit}
              loginStatus={props.loginStatus}
              error={props.loginStatus.error}
            />
          
      
      <AsynCallLoading open={(props.loginStatus.status === 'PENDING')} />
    </>
  );
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth && state.auth.userInfo &&
      Object.keys(state.auth.userInfo).length > 0 && state.auth.userInfo,
    loginStatus: state.auth && state.auth.login
  };
};
const mapDispatchToProps = dispatch => {
  return {
    doLogout: () => dispatch(logout()),
    doLogin: (payload) => dispatch(login(payload)),
    resumeSession: () => dispatch(resumeSession()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Authentication);