import react from "react"
import TeacherPage from "../teacherPage";
import Teachers_page from "./../../../images/Teachers_page.jpg"
import img_teacher from "./../../../images/img_teacher.png"
import logos from "./../../../images/logos.PNG"
import teacher_student from "./../../../images/teacher_student.png"
import check from "./../../../images/check.png"
import  {Link } from 'react-router-dom'


import relatedProgImg1 from  "./../../../images/relatedProgImg1.png"
import relatedProgImg2 from  "./../../../images/relatedProgImg2.png"
import relatedProgImg3 from  "./../../../images/relatedProgImg3.png"
import circleBg  from './../../../images/circleBg.png'

const Third_Pane = () =>{
    return(

        <div>

        <div className="uk-container uk-margin-large-top uk-margin-large-bottom">
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <div>
                              <img src= {Teachers_page}  alt= "On-Campus Program" />
                          </div>
                     </div>

                     <div className="uk-width-1-2@s uk-margin-large-top">
                          <div>
                              <h3 className="header3 uk-text-left" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">To be an example, teachers need to go through training</h3>
                              <p className="uk-text-left uk-margin-top" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">This is our philosophy. That is why our teachers follow a
                               training program, better themselves and bring their A game to class. To answer the current situation, they train to deliver the same quality for In-person 
                               as well as for Virtual teaching.</p>
                          </div>
                     </div>
                </div>




                <div className="uk-grid uk-margin-small-bottom uk-margin-large-top" data-uk-grid>
            
                     {/***DESKTOP***/}
                     <div className="uk-width-1-2@s uk-margin-large-top uk-visible@s">
                          
                          <div className="">
                              <h3 className="header3 uk-text-left" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Every students gets his time with the teacher</h3>
                              <p className="uk-text-left uk-margin-top" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Be it 1-on-1, Small Group or Tech Camp, each student is entitled to
                               their time and attention from the teacher. This is why we keep our group small. Teachers ensure every student gets the tools they need and create the space to interact
                                with other students and develop social skills.</p>
                          </div>

                         
                     </div>

                             {/***MOBILE***/}
                             <div className="uk-width-1-2@s  uk-hidden@s">
                          
                          <div className="">
                              <h3 className="header3 uk-text-left" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Every students gets his time with the teacher</h3>
                              <p className="uk-text-left uk-margin-top" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Be it 1-on-1, Small Group or Tech Camp, each student is entitled to
                               their time and attention from the teacher. This is why we keep our group small. Teachers ensure every student gets the tools they need and create the space to interact
                                with other students and develop social skills.</p>
                          </div>

                         
                     </div>



                     <div className="uk-width-1-2@s" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                          <div>
                              <img src= {img_teacher}  alt="CyberTech Small Group Class"/>
                          </div>
                     </div>
                </div>



                </div>



             <div className=""
             
             style={{ backgroundImage: `url(${circleBg})`,
             backgroundRepeat: 'no-repeat',
             backgroundSize: 'cover',
             paddingBottom: '100px'
         
             
         
               }}
             
             
             
             
             
             >
                  <div className="uk-container">
                   <div className=" uk-margin-large-top">
                   <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <div>
                              <img src= {logos} />
                          </div>
                     </div>

                     <div className="uk-width-1-2@s uk-margin-large-top">
                          <div>
                              <h3 className="header3 uk-text-left" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Expertise and Experience</h3>
                              <p className="uk-text-left uk-margin-top" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Expertise in their field is essential for teachers
                               who join us. They get to apply what they studied and applied at top universities and we get the best. We value experience in teaching young minds, 
                               delivered in a fun, interesting way.</p>
                          </div>
                     </div>
                </div>
                   </div>
                   </div>
           
                   </div>



                   <div className = "uk-container uk-margin-large-top" >
                       <div className="uk-grid" data-uk-grid>
                             <div className="uk-width-1-2@s">
                                   <div className="" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                      <img src = {teacher_student} alt="Cybertech Academy" />
                                   </div>
                             </div>

                             <div className="uk-width-1-2@s">
                                   <div>
                                       

                                   <div className="uk-margin-large-top">
                               
                               <h3  className=" " uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">Always open to improvement <span className="greenText">
                               build a creative hub </span>   </h3>
                               <p className="subText uk-text-left uk-margin-top "  uk-scrollspy="cls: uk-animation-; repeat: true">A growth mindset is essential to bringing quality educational services to your children. You are entitled to expect it, and we strive to deliver it. To this end:
</p>
                                   
                                  <div className="uk-grid uk-grid-collapse uk-margin-medium-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                               <img src= {check}  className="iconCheck"/>
                                          </div>

                                          <div className="uk-width-5-6">
                                               <p className="check_text">Teachers undergo training sessions and periodical teaching techniques updates</p>
                                          </div>
                                  </div>


                                  <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                               <img src= {check}  className="iconCheck"/>
                                          </div>

                                          <div className="uk-width-5-6">
                                               <p className="check_text">We keep our courses up to date to make sure our additional education stays relevant</p>
                                          </div>
                                  </div>




                                  <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                               <img src= {check}  className="iconCheck"/>
                                          </div>

                                          <div className="uk-width-5-6">
                                               <p className="check_text">We welcome your feedback and make our best to answer your specific needs regarding tech education</p>
                                          </div>
                                  </div>





                                  <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                               <img src= {check}  className="iconCheck"/>
                                          </div>

                                          <div className="uk-width-5-6">
                                               <p className="check_text">We select our talent from top universities and help them advance/ acquire top experience</p>
                                          </div>
                                  </div>


                                 



                               
                               
                               </div>
                                   </div>
                             </div>
                       </div>


                   </div>




                   <div className="uk-container uk-margin-large-top">
                           <div className="uk-width-1-1">
                               <div className="uk-flex-center uk-flex">
                                  <div className="mediumContainer">
                                      <h1 className="header1 uk-text-center">Now that you have met our staff, you're ready to get started!</h1>
                                      <h5 className="uk-text-center">Choose the perfect program for your child.</h5>
                                  </div>
                                  </div>
                           </div>

                     <div className="uk-flex uk-flex-center">
                    <div className="">
                    <div className=" uk-margin-large-bottom uk-margin-large-top" data-uk-grid> 
                         <div className="uk-width-1-3@s">
                              <div className="">
                                  <img src={relatedProgImg1} uk-scrollspy="cls: uk-animation-slide-left; repeat: true" />
                                  <h4 className="imgHeader  uk-text-center">Virtual Tech Camp</h4>
                                  <p className="subText  withPadRightLeft uk-text-center" >  Take advantage of time off school to build coveted tech skills </p>
                                       
                                  <div class="uk-flex uk-flex-center uk-margin-top">
                              <Link to ="/virtual-tech-camp">  <div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Learn More</div></Link> 
                        
                                 </div>
                                     </div>
                         </div>


                         <div className="uk-width-1-3@s">
                                    <div>
                                        <img src={relatedProgImg2} uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                        <h4 className="imgHeader  uk-text-center">Small Group Courses</h4>
                                            <p className="subText  withPadRightLeft uk-text-center" > Join other tech savvys to challenge each other and advance together </p>
                                        
                                            <div class="uk-flex uk-flex-center uk-margin-top">
                                         <Link to ="/VirtualSmallGroup">  <div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Learn More</div> </Link> 
                                    
                                            </div>
                                         </div>
                         </div>






                         <div className="uk-width-1-3@s">
                             <div>
                                <img src={relatedProgImg3}  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                <h4 className="imgHeader  uk-text-center"> 1-on-1 Virtual Courses</h4>
                                  <p className="subText  withPadRightLeft uk-text-center" > Enjoy exclusive personalized courses for a fast track to performance </p>
                                  <div class="uk-flex uk-flex-center uk-margin-top">
                                 <Link to ="/virtualcourses"><div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Learn More</div></Link>
                        
                                    </div>
                                </div>
                         </div>
                    </div>
                         </div>


                         </div>
                   </div>







        </div>
    )
}


export default Third_Pane;