import react from 'react'
import Testimonial from './../../HomePage/Blocks/TestimonialBlock';

import increase from './../../../images/increase.png'
import increase2 from './../../../images/increase2.png'
import modsProjects from './../../../images/92percent.png'
import atSchoolLocation from  './../../../images/img_at_location.png'

import check from  './../../../images/check.png'
import { Link } from 'react-router-dom'
import bgTechCampAtLocation from './../../../images/bgcourses_techAtLocation.png'






const Seventh_Pane = () => (

            <section className = ""
            
            
            style={{ backgroundImage: `url(${bgTechCampAtLocation})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '100px',
            width: '100%'
        
            
        
              }}   
            
            
            
            
            >
                   
                <div className="uk-container">

                    <div className="">
                    <Testimonial />
                    </div>



                     <section className="">
                    <div className="smallContaner">
                         

                                  <div className=" uk-flex uk-flex-center uk-text-center">
                                       <div className="mediumContainer">
                                            <h1 className="header1">Camp boosts results in School and more</h1>
                                            <p>Confidence, tech abilities and problem solving skills bring your 
                                                child ahead, in general and on their academic path</p>
                                       </div>
                                    </div>




                                        {/** COUNTER  */}
                                        <div className="uk-margin-medium-top">
                                            
                                            <div className="uk-flex uk-flex-center ">
                                            <div className="counterCCP ">
                                                <div class="" data-uk-grid>

                                                <div class="uk-width-1-3@s  ">
                                                        <div>
                                                           <div className="uk-flex uk-flex-center ">   
                                                               <img src ={modsProjects}  className="iconBig  "/>
                                                            </div>
                                                            <p className="iconParagraphSmall uk-text-center uk-text-bold"> Of CyberTeck students attend undergraduate studies </p>

                                                        </div>
                                                          
                                                </div>

                                                <div class="uk-width-1-3@s ">
                                                        <div>
                                                           <div className="uk-flex uk-flex-center ">   
                                                               <img src ={increase}  className="iconBig  "/>
                                                            </div>
                                                            <p className="iconParagraphSmall uk-text-center uk-text-bold"> Increase in academic outcomes when gamification is paired with study </p>

                                                        </div>
                                                          
                                                </div>



                                                <div class="uk-width-1-3@s  ">
                                                        <div>
                                                           <div className="uk-flex uk-flex-center ">   
                                                               <img src ={increase2}  className="iconBig  "/>
                                                            </div>
                                                            <p className="iconParagraphSmall uk-text-center uk-text-bold"> Increase in academic results   </p>

                                                        </div>
                                                          
                                                </div>
                                      </div></div>
                                        </div>
                                        </div>
                          
                    </div>
                    </section>






                         <div className = "uk-container uk-margin-xlarge-top">
                             <div className='uk-grid' data-uk-grid>
                                 <div className='uk-width-1-5@s'>

                                 </div>
                                 <div className='uk-width-3-5@s'>
                                    
                                 <h3 className='header3'>You can also go for Computer Courses at Parks & Rec Centers and School Locations</h3>
                                  </div>

                                  <div className='uk-width-1-5@s'>

                                  </div>
     
                             </div>
                            <div className= "uk-grid" data-uk-grid>
                                 <div className="uk-width-1-2@s">

                         
                                 <div className=" uk-margin-medium-top">


                                    <div className="uk-grid-collapse" data-uk-grid>
                                        <div className="uk-width-1-6 uk-text-center">
                                            <img src ={check} className="iconVirt"/>
                                        </div>

                                        <div className="uk-width-5-6">
                                            <p className=" ">We partnered up with Schools to bring in-demand computer courses to develop tech skills for students K-8
</p>
                                        </div>
                                    </div>


                                    <div className="marginTopSmall">
                                    <div className="uk-grid-collapse " data-uk-grid>
                                        
                                        <div className="uk-width-1-6 uk-text-center">
                                            <img src ={check} className="iconVirt"/>
                                        </div>

                                        <div className="uk-width-5-6">
                                            <p className=" ">You can choose Schools and Parks & Rec Centers that fit your schedule and mobility</p>
                                        
                                        </div>
                                    </div>
                                    </div>



                                    <div className="marginTopSmall">
                                                <div className="uk-grid-collapse" data-uk-grid>
                                                    <div className="uk-width-1-6 uk-text-center">
                                                        <img src ={check} className="iconVirt"/>
                                                    </div>

                                                            <div className="uk-width-5-6 uk-margin-left-remove">
                                                                <p className=" ">Find a location that suits you best and register your child for as many hours as you need
</p>
                                                                </div>
                                                </div>
                                            </div>



                            <div className="marginTopSmall">
                            <div className="uk-grid-collapse" data-uk-grid>
                                <div className="uk-width-1-6 uk-text-center">
                                    <img src ={check} className="iconVirt"/>
                                </div>

                                <div className="uk-width-5-6">
                                    <p className=" ">Is your location not on our list? Recommend us, and we can partner up with schools or centers to reach every student fond of tech.
                                            </p>
                                </div>
                            </div>
                            </div>


                            <div class="uk-flex withPadding ">
                            <Link to="/#"><div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Learn More </div> </Link>

                            </div>

                            </div>
                            
                                 </div>

                                 <div className="uk-width-1-2@s">
                                   <img src={atSchoolLocation} className=""/>
                                 </div>
                            </div>
                       </div>

                </div>

        </section>

);


export default Seventh_Pane;