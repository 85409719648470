import react from 'react';
import { Link } from "react-router-dom";
import './../teachers.css'
import teachersPageBg from './../../../images/staff-min.PNG'



const First_Pane = () => (
         <div className="">

              {/***DESKTOP VIEW***/}
    <div className=""
    
    
    style={{ backgroundImage: `url(${teachersPageBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: '100px'

      }}
 
    >
            
    <div className="uk-container " >
    <div className="uk-grid  " data-uk-grid>
         <div className="uk-width-3-4@s">
              <div className="uk-margin-xlarge-top " >
                  <h1 className="header1 light withPadding" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> Expert Teachers for future Tech experts</h1>
                  <p className="subTitleText" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Great teachers make the difference between a simple computer course and
                                                                                                   a learning experience. With more than 10 years experience, trust us to select
                                                                                                    the best in their fields and to bring you top tech education.</p>
              </div>

              <div class="uk-flex withPadding ">
                 <Link to="/courses"><div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">See All Courses  </div> </Link>
                
              </div>

         </div>

         <div className="uk-width-1-4@s">
             
         </div>
    </div>
    </div>
</div>



          <div className="uk-container teachersIntroDiv">
              <div className="uk-width-1-1">
                <div className="uk-flex uk-flex-center">
                     <div className="introDivTT uk-padding">
                            <h3 className="header3 uk-text-center"> Passion for learning is set by example</h3>
                            <p className="uk-text-center uk-margin-top">Students bond better with and learn faster from educators
                             who dedicate themselves to their field. Our teachers put their expertise where their passion is. 
                             Selected from tech universities, IT experts are here to awaken your child’s interest in the 
                             world of tech. Their secret power? To make understanding technology fun and easy. Your child 
                             won’t be able to resist learning.</p>
                     </div>
                </div>
              </div>

          </div>



</div>
);


export default First_Pane