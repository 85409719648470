import Layout from "../Layout";
import "./contactUsPage.css";
import { BodyText, PageBlock, PrimaryButton, PrimaryText, SecondaryText, TextInputBox } from "../../features/Atoms";
import { Grid, Paper, Typography, ButtonBase, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { Field, Formik } from "formik";
import cu_bg from "./../../images/cu_bg-1024x782.png"
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { QuestionBlock } from "./../FaqPage/Block/faqSection"
import whychooseicon5 from "./../../images/whychooseicon5.png"


import contactIcon1 from "./../../images/contactIcon1.png"
import contactIcon2 from "./../../images/contactIcon2.png"
import contactIcon3 from "./../../images/contactIcon3.png"
import message  from "./../../images/message.png"
import heroBgContact from './../../images/contact_us.jpg'
import Contact_UsBg from './../../images/contact_usBg.jpg'




import faqdata from "../FaqPage/faqdata";
import { useDispatch, useSelector } from "react-redux";
import { contactUs } from "./../../DataStore/AuthReducer"



export default function ContactUsPage(props) {

    const contactUsStatus = useSelector(state => state.auth.contactUs);
    const dispatch = useDispatch();

    return (

        <Layout fillHeader >
               <section className=""
               
               
               style={{ backgroundImage: `url(${heroBgContact})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: '100px',
    paddingTop: '100px',
    

    

      }}
               
               
               
               
               
               >
                 <div className="uk-container" >
                   <div className="uk-grid  " data-uk-grid>
                     <div className="uk-width-3-4@s">
                      <div className="uk-margin-xlarge-top " >
                          <h1 className="header1 light withPadding" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Contact CyberTeck Academy</h1>
                          <p className="subTitleText" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Your success elevated, one email away. </p>
                      </div>

                      
  
                    </div>

                       <div className="uk-width-1-4@s">
                     
                      </div>
                   </div>
                  </div>  
                 
                 </section>   


                  {/***DESKTOP***/}
                <section className="uk-visible@s"
                
                style={{ backgroundImage: `url(${Contact_UsBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '100px',
                paddingTop: '120px',
                height: '105vh'
            
                
            
                  }}
                
                
                >
               <div className="uk-container uk-margin-medium-bottom">
                     <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-2@s">
                                  <div className="uk-padding">
                                   <p className="uk-text-left">Our goal is to bring you the best services in education. 
                                   If you have any questions or want to know more about our courses, drop us an email. 
 </p>
                                        <p className="uk-text-left uk-margin-top">We’d like to hear from you. Suggestions or
                                         recommendations for new partner locations are welcomed as well.</p>
                                </div>

                                <div className=" uk-padding">
                                      <div className="uk-flex ">
                                          <div className="icon" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                              <img src= {message} />
                                           </div>

                                          <div className="uk-margin-left" >
                                              <h6 className="uk-text-bold" style={{position:"relative",bottom:-14}} uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Email</h6>
                                              <p className="uk-margin-top" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">info@cyberteck.com</p>
                                          </div>
                                          
                                      </div>        
                                </div>

                              </div>

                              <div className="uk-width-1-2@s">

                             <div uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"> 
                              <Grid >
                               <Paper style={{ padding: '6% ' }}>
                             <Formik
                            enableReinitialize
                            initialValues={{
                                name: '', email: '', subject: '', message: ''
                            }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.name) {
                                    errors.name = "Required";
                                }
                                if (!values.email) {
                                    errors.email = "Required";
                                }
                                if (!values.subject) {
                                    errors.subject = "Required";
                                }
                                if (!values.message) {
                                    errors.message = "Required";
                                }
                                return errors;
                            }}
                            onSubmit={(data, action) => {
                                dispatch(contactUs(data));
                                action.setSubmitting(false);
                                action.resetForm();
                            }}
                        >
                            {({ handleSubmit, isSubmitting, setFieldValue }) => (
                                <form onSubmit={handleSubmit} >
                                    {
                                        contactUsStatus.status === 'REJECTED' &&
                                        <Alert severity="error">
                                            {contactUsStatus.error.message}
                                        </Alert>
                                    }
                                    {
                                        contactUsStatus.status === 'FULFILLED' &&
                                        <Alert severity="success">
                                            Message sent successfully
                                        </Alert>
                                    }
                                    <TextInputBox name="name" label="Name"
                                        disabled={isSubmitting} />
                                    <TextInputBox type="email" name="email" label="Email Address"
                                        disabled={isSubmitting} />
                                    <TextInputBox name="subject" label="Subject"
                                        disabled={isSubmitting} />
                                    <TextInputBox name="message" label="Message" multiline
                                        disabled={isSubmitting} rows={4} />
                                    <PrimaryButton type="submit">Submit</PrimaryButton>
                                </form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
                    </div>
                              </div>
                    </div>
               </div>
          </section>







          

                  {/***MOBILE***/}
                  <section className="uk-hidden@s"
                
                style={{ backgroundImage: `url(${Contact_UsBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '100px',
                paddingTop: '120px',
                height: '165vh'
            
                
            
                  }}
                
                
                >
               <div className="uk-container  uk-margin-medium-bottom">
                     <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-2@s">
                                  <div className="uk-padding negativeTopMarg">
                                  <p className="uk-text-left ">Our goal is to bring you the best services in education. 
                                   If you have any questions or want to know more about our courses, drop us an email. 
 </p>
                                        <p className="uk-text-left uk-margin-top">We’d like to hear from you. Suggestions or
                                         recommendations for new partner locations are welcomed as well.</p>
                                </div>

                                <div className=" uk-padding">
                                      <div className="uk-flex ">
                                          <div className="icon" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                              <img src= {message} />
                                           </div>

                                          <div className="uk-margin-left" >
                                              <h6 className="uk-text-bold "  uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Email</h6>
                                              <p className="uk-margin-small-top" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">info@cyberteck.com</p>
                                          </div>
                                          
                                      </div>        
                                </div>

                              </div>

                              <div className="uk-width-1-2@s">

                             <div uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"> 
                              <Grid >
                               <Paper style={{ padding: '6% ' }}>
                             <Formik
                            enableReinitialize
                            initialValues={{
                                name: '', email: '', subject: '', message: ''
                            }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.name) {
                                    errors.name = "Required";
                                }
                                if (!values.email) {
                                    errors.email = "Required";
                                }
                                if (!values.subject) {
                                    errors.subject = "Required";
                                }
                                if (!values.message) {
                                    errors.message = "Required";
                                }
                                return errors;
                            }}
                            onSubmit={(data, action) => {
                                dispatch(contactUs(data));
                                action.setSubmitting(false);
                                action.resetForm();
                            }}
                        >
                            {({ handleSubmit, isSubmitting, setFieldValue }) => (
                                <form onSubmit={handleSubmit} >
                                    {
                                        contactUsStatus.status === 'REJECTED' &&
                                        <Alert severity="error">
                                            {contactUsStatus.error.message}
                                        </Alert>
                                    }
                                    {
                                        contactUsStatus.status === 'FULFILLED' &&
                                        <Alert severity="success">
                                            Message sent successfully
                                        </Alert>
                                    }
                                    <TextInputBox name="name" label="Name"
                                        disabled={isSubmitting} />
                                    <TextInputBox type="email" name="email" label="Email Address"
                                        disabled={isSubmitting} />
                                    <TextInputBox name="subject" label="Subject"
                                        disabled={isSubmitting} />
                                    <TextInputBox name="message" label="Message" multiline
                                        disabled={isSubmitting} rows={4} />
                                    <PrimaryButton type="submit">Submit</PrimaryButton>
                                </form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
                    </div>
                              </div>
                    </div>
               </div>
          </section>


          <div className="uk-container uk-margin-large-top uk-margin-large-bottom">
                <div className="uk-flex uk-flex-center">
                      <div className="mediumContainerLarge">
                      <div class="uk-text-center" data-uk-grid>
                                        <div class="uk-width-1-3@s" uk-scrollspy="cls: uk-animation-slide-top; repeat: true">
                                            <div class="uk-card uk-card-default uk-card-body">
                                                <div className="uk-flex-center uk-flex">
                                                    <div className="iconDiv">
                                                            <img src={contactIcon1} className="iconBig"/>
                                                            <h6 className="uk-margin-remove uk-text-bold">Computer Courses</h6>
                                                            <p className="iconParagraphSmall uk-padding-small">See available courses, 1-on-1 and Small Group</p>
                                                            <Link to="/courses"><div class=" uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">See all Courses</div> </Link>
                                    
                                                    </div>
                                                </div>
                                                
                                                </div>
                                        </div>


                                            <div class="uk-width-1-3@s" wuk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                                                <div class="uk-card uk-card-default uk-card-body">
                                                    <div className="uk-flex-center uk-flex">
                                                        <div className="iconDiv">
                                                                <img src={contactIcon2} className="iconBig"/>
                                                                <h6 className="uk-margin-remove uk-text-bold">Partner Locations</h6>
                                                                <p className="iconParagraphSmall uk-padding-small">Access courses and camps at our partner location near you</p>
                                                                <Link to="/schools"><div class=" uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Learn More</div> </Link>
                                        
                                                        </div>
                                                    </div>
                                                    
                                                    </div>
                                            </div>





                                            <div class="uk-width-1-3@s" uk-scrollspy="cls: uk-animation-slide-top; repeat: true">
                                                <div class="uk-card uk-card-default uk-card-body">
                                                    <div className="uk-flex-center uk-flex">
                                                        <div className="iconDiv">
                                                                <img src={contactIcon3} className="iconBig"/>
                                                                <h6 className="uk-margin-remove uk-text-bold">Schedules and Pricing</h6>
                                                                <p className="iconParagraphSmall uk-padding-small">Check what fits your program and availability </p>
                                                                <Link to="/schools"><div class=" uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Register and Schedule now</div> </Link>
                                        
                                                        </div>
                                                    </div>
                                                    
                                                    </div>
                                            </div>
                     </div>
                      </div>
                </div>

          </div>











          <section className="blue uk-padding uk-margin-medium-top">
                    <div className="uk-container">
                         <div className=" uk-grid" data-uk-grid>
                                <div className="uk-width-1-2@s uk-margin-small-top">
                                     <h3 className="light header3 uk-text-bold">Want quick answers to questions?</h3>
                                </div>

                                <div className="uk-width-1-2@s ">
                                <div class="uk-flex  uk-margin-small-top">
                                   <Link to="/#"><div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Visit FAQ Page</div> </Link>
                                    <Link to='/courses'> <div class=" uk-flex-left@s btn-ghost uk-text-center uk-margin-left" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Our Courses</div></Link>
                               </div>
                                </div>
                         </div>
                    </div>
          </section>




 





        </Layout>
    );
}
