import react from 'react';
import blocksBg from './../../../images/bg_blocks_virtual.jpg'





export default function () {
    return (

        <section className=""
        
        
        style={{ backgroundImage: `url(${blocksBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '20px'
    
        
    
          }}
        
        
        
        
        
        
        >
        <div className="uk-container uk-margin-large-top">
           
    
              


                <div className='uk-grid uk-grid-collapse' data-uk-grid>
                    <div className='uk-width-1-5@s'>

                    </div>

                    <div className='uk-width-3-5@s'>
                             <h1 className=" header1 uk-text-center">Why choose <span className="greenText">Virtual 1-on-1</span> Computer Courses</h1>
                                <p className="uk-text-center normalPaddingLR">Let us boost your child’s creativity and tech skill set to design their
                                     own games or video animation. Students dive into the universe of Minecraft,
                                    Roblox or Scratch to explore 3D architecture and game challenges. Get them 
                                    ready with personalized tech education.</p>
                    </div>

                    <div className='uk-width-1-5@s'>

                    </div>
                </div>





                  
                  
                       
                 <div className="uk-flex uk-flex-center uk-margin-large-top ">
                    <div className="mediumContainerLarge">
                        <div class="uk-child-width-expand@s uk-text-center" data-uk-grid>

                        <div>
                            <div class="uk-card height350 uk-card-default borderRadius  uk-card-body">
                                <h6 className="uk-text-bold uk-text-center uk-margin-top greenText">Tech Expert Teachers</h6>
                                <p className="uk-text-center smallText uk-margin-top">Our teachers come with expertise and experience and put it in the 
                                    service of your child. Their passion for tech boosts results and makes learning fun.</p>
                            </div>
                        </div>


                        <div>
                            <div class="uk-card height350 uk-card-default borderRadius uk-card-body">
                            <h6 className="uk-text-bold uk-text-center uk-margin-top greenText">Customized tech pathway for your child</h6>
                                <p className="uk-text-center smallText uk-margin-top">1-on-1 time and attention to create the best and fastest way of building in-demand 
                                tech skills. Many course options tailored to fit every student that joins us.</p>   
                            </div>
                        </div>


                        <div>
                            <div class="uk-card height350 uk-card-default borderRadius  uk-card-body">

                            <h6 className="uk-text-bold uk-text-center  uk-margin-top greenText">Flexible scheduling and financial options</h6>
                                <p className="uk-text-center smallText uk-margin-top">Enjoy rates and time slots to fit your demands. Get as many hours as you want, when
                                 you need them. Go Virtual for ultimate mobility.</p>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>




                     {/***Second row**/}
                     
                 <div className="uk-flex uk-flex-center uk-margin-medium-top uk-margin-large-bottom">
                    <div className="mediumContainerLarge">
                        <div class="uk-child-width-expand@s uk-text-center" data-uk-grid>

                        <div>
                            <div class="uk-card height350 uk-card-default borderRadius uk-card-body">
                                <h6 className="uk-text-bold uk-text-center uk-margin-top greenText">The future is Tech and fun</h6>
                                <p className="uk-text-center smallText uk-margin-top">Creativity and programming principles are essential for the 
                                next generation. Our students have fun tapping into their creative side with everything from Minecraft to Python.</p>
                            </div>
                        </div>


                        <div>
                            <div class="uk-card height350 uk-card-default borderRadius uk-card-body">
                            <h6 className="uk-text-bold uk-text-center uk-margin-top  greenText">Learn @home or anywhere</h6>
                                <p className="uk-text-center smallText uk-margin-top">Flexibility is now essential. Education doesn’t have to
                                 stop. We bring you the chance to learn from anywhere.</p>   
                            </div>
                        </div>


                        <div>
                            <div class="uk-card height350 uk-card-default borderRadius uk-card-body">

                            <h6 className="uk-text-bold  uk-text-center uk-margin-top  greenText">Schools partner up with us</h6>
                                <p className="uk-text-center smallText uk-margin-top">Education providers support tech education. We teamed
                                 up with Schools to offer our computer courses and add value to your child’s academic path.</p>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>


           
            </div>
            </section>
    );
}