import React from "react";
import teachers from './../../../images/teachers.png'
import check from './../../../images/check.png'




const TeachersPane = () => (
     
          <div>
              <div className="uk-container">
                   <div className="uk-grid " data-uk-grid>
                        <div className="uk-width-1-5@s">
                              
                        </div>

                        <div className="uk-width-3-5@s uk-margin-top">
                              <h3 className="header3 uk-text-center"> Dedicated teachers inspire young minds and build a creative hub </h3>
                              <p className=" uk-text-center uk-margin-top">Before joining our team, we make sure the teachers go through an in-depth selection process. 
                                   First hand experience in virtual and in-person teaching makes them efficient, able to achieve
                                    fast results and keep students engaged.</p>
                        </div>

                        <div className="uk-width-1-5@">
                              
                        </div>
                   </div>



                   <div className="uk-grid" data-uk-grid>
                        <div className="uk-width-1-2@s">
                            <h4 className="uk-text-bold">CyberTeck’s best talent pool:</h4>


                            
                                  <div className="uk-margin-top uk-grid uk-grid-collapse" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                              <img src= {check} className="iconCheck"/>
                                          </div>
                                          <div className="uk-width-5-6">
                                                 <p className="check_text">Recruited from top tech universities like MIT or NYU</p>
                                          </div>
                                </div>    


                                 <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                              <img src= {check} className="iconCheck"/>
                                          </div>
                                          <div className="uk-width-5-6">
                                                 <p className="check_text">USA-based teachers selected after extensive background checks</p>
                                          </div>
                                </div> 




                                 <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                              <img src= {check} className="iconCheck"/>
                                          </div>
                                          <div className="uk-width-5-6">
                                                 <p className="check_text">Deliver results with proven teaching methods for all students</p>
                                          </div>
                                </div>   



                                <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                              <img src= {check} className="iconCheck"/>
                                          </div>
                                          <div className="uk-width-5-6">
                                                 <p className="check_text">Go through an extensive vetting process</p>
                                          </div>
                                </div>  



                                <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                              <img src= {check} className="iconCheck"/>
                                          </div>
                                          <div className="uk-width-5-6">
                                                 <p className="check_text">Continue to train their teaching skills and expand their knowledge</p>
                                          </div>
                                </div>  

                                <div className="uk-grid uk-grid-collapse uk-margin-small-top" data-uk-grid>
                                          <div className="uk-width-1-6 uk-text-left">
                                              <img src= {check} className="iconCheck"/>
                                          </div>
                                          <div className="uk-width-5-6">
                                                 <p className="check_text">Know how to connect to students and develop their creative curiosity</p>
                                          </div>
                                </div>  



                          



                         








                        </div>

                        <div className="uk-width-1-2@s">
                         <img src= {teachers}  />
                        </div>
                   </div>






              </div>
          </div>

    
)

export default TeachersPane