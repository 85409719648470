import { Dialog, DialogContent } from "@material-ui/core";
import { profileFetch, profileUpdate } from "../../DataStore/AuthReducer";
import { bookSchoolCourse } from "../../DataStore/SchoolCoursesReducer";
import { useUserInfo } from "../../features/Authentication/Authorize";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { addToCart } from "../../DataStore/CartReducer";
import {
  Button,
  Box,
  Grid,
  Stepper,
  StepLabel,
  Step,
  Snackbar,
  Backdrop,
  CircularProgress,
  DialogTitle,
} from "@material-ui/core";
import {
  HeaderBox,
  HeaderTitle,
  PageBlock,
  PrimaryButton,
  PrimaryText,
  SecondaryText,
  TextInputBox,
} from "../../features/Atoms";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaypalIntregration from "../CourseBookingPage/PaypalIntregrationButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {AsynCallLoading} from '../../features/Atoms';
import axios from "axios";
import { Autocomplete } from "formik-material-ui-lab";
import './form.css';
import Layout from "../Layout";
import bg from './bg.jpeg'
// import { useNavigate } from "react-router-dom";





export default function Form2(props) {
  const courseInfo = props.location.state.item;
  const schoolname = props.location.state.schoolName;
 const history = useHistory();

  const profileData = useSelector((state) => state?.auth?.profileFetch?.data);
  const bookSchoolCourseStatus = useSelector((state) => state?.schoolCourses?.bookSchoolCourse?.status);
  const profileUpdateStatus = useSelector(
    (state) => state?.auth?.profileUpdate?.status
  );
  const userInfo = useUserInfo();
  console.log("working well",userInfo)
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [studentDetails, setStudentDetails] = useState({});
  
  const [loading, setLoading] = useState(false);
  const [showCart, setShowCart ] = useState(false)
  useEffect(() => {
    dispatch(profileFetch(userInfo.userId));
   
  }, [mounted]);

  
  

  useEffect(() => {
    setStudentDetails(profileData);
   
  }, [profileData]);

  console.log("all", profileData)

  useEffect(() => {
    if(bookSchoolCourseStatus === 'FULFILLED'){
      setLoading(false);
      props?.handleClose();
      history.push("/dashboard")
    }
    if(bookSchoolCourseStatus === 'PENDING'){
      setLoading(true);
    }
    if(bookSchoolCourseStatus === 'REJECTED'){
      setLoading(false);
    }
  }, [bookSchoolCourseStatus]);

  const getSteps = () => {
    return ["Student Details", "Parent Details", "Make Payment"];
  };
  const steps = getSteps();
  const handleNext = (formData, action, type) => {
    if (type === "STUDENT") {
      setStudentDetails({ ...studentDetails, ...formData });
    } else if (type === "PARENT") {
      setStudentDetails({ ...studentDetails, ...formData });
      dispatch(profileUpdate({ ...formData, userId: userInfo.userId }));
    }else if (type === "PAYMENT") {
      setStudentDetails({ ...studentDetails, ...formData });
      dispatch(profileUpdate({ ...formData, userId: userInfo.userId }));
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const addhandleBack = () => {
    setShowCart(false)
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };
  const handlePaypalPayment = (token) => {
    const data = {
      ...studentDetails,
      tokenData: token,
      userId: userInfo && userInfo.userId,
      paymentMethod: "PAYPAL",
      courseId: props?.courseInfo?.id,
      schoolId: props?.schoolId,
    };
    // console.log("data: ", data);
    dispatch(bookSchoolCourse(data));
    
  };

  const addToCarts = () =>{
    setShowCart(true)
    const mydata = localStorage.getItem('data')
    const checkoutdata = {
      title:props?.courseInfo.title,
      grade:props?.courseInfo.grade,
      duration:props?.courseInfo.duration,
      price:props?.courseInfo.price,
      courseId:props?.courseInfo.courseId,
      schoolId:props?.schoolId,
      parentEmail:studentDetails.parentEmail,
      firstName:studentDetails.firstName,
      lastName: studentDetails.lastName,
      addressLine1:studentDetails.addressLine1,
      contactNo:studentDetails.contactNo,
      studentAge:studentDetails.studentAge,
      userId:userInfo.userId,
      id:Math.random()
    }

   
      var existingEntries = JSON.parse(localStorage.getItem("allEntries"));
    if(existingEntries == null) existingEntries = [];
   
    localStorage.setItem("checkout", JSON.stringify(checkoutdata));
    // Save allEntries back to local storage
    existingEntries.push(checkoutdata);
    localStorage.setItem("allEntries", JSON.stringify(existingEntries));
    console.log("this is my real data", existingEntries)
  }


  const handlePayment = () => {
     setLoading(true)
    const data = {
      "parentEmail":studentDetails.parentEmail,
      "firstName":studentDetails.firstName,
      "lastName": studentDetails.lastName,
      "userId":userInfo.userId,

      tokenData: {
        "cc": "5424000000000015",
        "cvc": "123",
        "exp": "2035-12",
        "name": "TEST"
      },
      paymentMethod: "AUTHORIZE"

    }

   // console.log("this is payment data", data)

    axios.post("https://test.cyberteckacademy.com/course-api/schools/4/courses/5/book-slot", data)
    .then(function (response){
      console.log("this is my response",response.data.meta)
      if(response.data.meta.statusCode == 200){
        setLoading(false)
        alert("Course Booked successfuly")
        props.handleClose()
      }

      if(response.data.meta.statusCode == 403){
        setLoading(false)
        alert("All seats have been taken")
        props.handleClose()
      }

    })

  }






const cartItems = useSelector( (state)=> state.cart.value)

console.log("this is my cart", cartItems)


const [firstName, setFirstName] = useState()
const [lastName, setLastName] = useState()
const [parentEmail, setParentEmail] = useState()
const [addressLine1, setAddressLine1] = useState()
const [contactNo, setContactNo] = useState()
const [pincode, setPincode] = useState()
const [studentAge, setStudentAge] = useState()
const [grade, setGrade] = useState()
const [gender, setGender] = useState()
const [data, setData] = useState(cartItems)

//console.log("this is my data", data)

useEffect(() => {
  setFirstName(studentDetails?.firstName)
  setLastName(studentDetails?.lastName)
  setParentEmail(studentDetails?.parentEmail)
  setAddressLine1(studentDetails?.addressLine1)
  setContactNo(studentDetails?.contactNo)
  setPincode(studentDetails?.pincode)
  setStudentAge(studentDetails?.studentAge)
  setGrade(studentDetails?.grade)
},[studentDetails]) 

//save cart items to local storage
useEffect(() => {
  localStorage.setItem('Cartdata', JSON.stringify(cartItems))
},[cartItems])

const checkoutData = {
  title:courseInfo?.title,
  grade:courseInfo?.grade,
  duration:courseInfo?.duration,
  price:courseInfo?.price,
  courseId:courseInfo?.courseId,
  schoolId:courseInfo?.schoolId,
  schoolName: schoolname,
  parentEmail,
  firstName,
  lastName,
  addressLine1,
  contactNo,
  studentAge,
  userId:userInfo.userId,
  id:Math.random()
}

//dispatch action to add to cart
const addItem = () => {
  //set timeinterval to 1 second
  dispatch(addToCart(checkoutData))
  setTimeout(() => {
    history.push('/shipping2')
  }, 2000);
 
 // history.push('/shipping2')
 
  //setData([...data, checkoutData])

}




return(
  <Layout className="detailsBlock">
   
    <HeaderBox style={{ paddingTop: '25%', }}
                    coverPic={courseInfo.coverImage}>
                   
                </HeaderBox>
              
                <section>
                <div class="title uk-text-center">Get Started with {courseInfo.title}</div>
                <div  className='uk-flex-center uk-flex uk-margin-large-top'>
                <div className='col-lg-3'></div>
                <div className='col-lg-6 uk-margin-large-bottom'>
                <div class="uk-margin-top subtitle uk-text-left">Parents Info</div>

                <div class="input-container ic2">
                <input value={parentEmail} onChange={(e)=>{setParentEmail(e.target.value)}}  id="email" class="input" type="text" placeholder=" " />
                <div class="cut cut-short"></div>
                <label for="email" class="placeholder">Your Email</label>
              </div>

              <div class="input-container ic2">
                <input  id="email" value={firstName}  onChange={(e)=>{setFirstName(e.target.value)}}class="input" type="text" placeholder='' />
                <div class="cut cut-short"></div>
                <label for="FirstName" class="placeholder">First Name</label>
              </div>

              <div class="input-container ic2">
                <input value={lastName} onChange={(e)=>{setLastName(e.target.value)}} id="email" class="input" type="text" placeholder=" " />
                <div class="cut cut-short"></div>
                <label for="lastName" class="placeholder">Last Name</label>
              </div>
              <div className='uk-grid' data-uk-grid>
                   <div className='uk-width-1-2'>
                   <div class="input-container ic2">
                <input value={studentAge} onChange={(e)=>{setStudentAge(e.target.value)}} id="Phone" class="input" type="text" placeholder=" " />
                <div class="cut cut-short"></div>
                <label for="Phone" class="placeholder">Student Age</label>
              </div>
                   </div>

                   <div className='uk-width-1-2'>
                   <div class="input-container ic2">
                <input value={grade} onChange={(e)=>{setGrade(e.target.value)}}  id="zipcode" class="input" type="number" placeholder=" " />
                <div  class="cut cut-short"></div>
                <label for="zipcode" class="placeholder">Grade</label>
              </div>
                </div>
              </div>

              <div className='uk-grid' data-uk-grid>
                   <div className='uk-width-1-2'>
                   <div class="input-container ic2">
                <input value={contactNo} onChange={(e)=>{setContactNo(e.target.value)}} id="Phone" class="input" type="text" placeholder=" " />
                <div class="cut cut-short"></div>
                <label for="Phone" class="placeholder">Phone</label>
              </div>
                   </div>

                   <div className='uk-width-1-2'>
                   <div class="input-container ic2">
                <input value={pincode}  onChange={(e)=>{setPincode(e.target.value)}} id="zipcode" class="input" type="text" placeholder=" " />
                <div  class="cut cut-short"></div>
                <label for="zipcode" class="placeholder">Zip code</label>
              </div>
                </div>
              </div>

              <div class="input-container ic2">
                <input value={addressLine1} onChange={(e)=>{setAddressLine1(e.target.value)}} id="address" class="input" type="text" placeholder=" " />
                <div  class="cut cut-short"></div>
                <label for="address" class="placeholder">Address</label>
              </div>

              <div className=''>
    
    <div class="uk-margin-medium-top subtitle uk-text-left">Student Info</div>
    
    <div class="uk-margin-small-top subtitle uk-text-left"> Gender</div>
     <div className='uk-grid' data-uk-grid>
         
       <div className='uk-width-1-2 uk-margin-top'>
          
              <input  onChange={(e)=>{setGender(e.target.value)}} type="radio" name="gender" id="male" /> 
              <label for="male">Male</label>
              
              <input onChange={(e)=>{setGender(e.target.value)}} type="radio" name="gender" id="female" className='uk-margin-left'/> 
              <label for="female">Female</label> 
              
              <input onChange={(e)=>{setGender(e.target.value)}}  type="radio" name="gender" id="female" className='uk-margin-left'/> 
              <label for="female">non-binary</label> 

       </div>

       {/* <div className='uk-width-1-2 uk-margin-top'>

           <div>
              <label className='uk-margin-right' for="drop-down">Student's Grade:</label>
              <select onChange={(e)=>{setAddressLine1(e.target.value)}}  name="drop-down" id="drop-down">
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
              </select>
          </div>

           </div> */}

        
       
    
     </div>

    </div>


    <button onClick={
      addItem
    } type="submit" value="Submit" class="submit">Next</button>

                </div>
                <div className='col-lg-3'></div>
                  
                </div>
                   
           

          
                </section>
                
               
   

  </Layout>
)









}
