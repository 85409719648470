import Layout from "../Layout";
//import "./schoolDetailsPage.css";

import {
  BodyText,
  HeaderBox,
  HeaderDescription,
  HeaderTitle,
  PageBlock,
  PrimaryButton,
  PrimaryText,
  SecondaryText,
} from "./../../features/Atoms";

import { Box, Grid, Paper, Typography, Container } from "@material-ui/core";
import coverImg from "./../../images/minecraft-animation.jpg";
import { useParams, Link} from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from "react-redux";
import { loadSchool } from "./../../DataStore/SchoolReducer";
import { useEffect, useState } from "react";
import Data from "../../Data";
import Api from "../../Api";
import tick_icon from "./../../images/tick_icon.png";
import school_aboutus from "./../../images/school_aboutus.png";
import ctaImg from "./../../images/ctaImg.png";
import TodayIcon from "@material-ui/icons/Today";
import { loadSchoolCourse } from "./../../DataStore/SchoolCoursesReducer";
import Moment from "react-moment";
import moment from "moment";
import CourseBookingModal from "../../pages/PlanPage/Block/courseBookingModal";
import EditIcon from "@material-ui/icons/Edit";
import { useAuthorize } from "./../../features/Authentication/Authorize";
import Fourth_Pane from "../PlanPage/Block/Fourth_Pane";
import check from "../../images/check.png"

import secured from "./../../images/secured.png"
import { SettingsOutlined } from "@material-ui/icons";
import { SelectionState } from "draft-js";
import skill_level_icon from "../../images/skill-level-150x150.png"
//import skill_level_icon from "../../../../images/skill-level-150x150.png"
import grade_icon from "../../images/grade-150x150.png"
import price_icon from "../../images/price-150x150.png"
import path_icon from "../../images/path-150x150.png"
import prerequisite_icon from "../../images/prerequisite-150x150.png"
import tools_icon from "../../images/tools-150x150.png"
import locationType from "../../images/experiencem.png";
import cal_icon from "../../images/calenderm.png";
import timem from "../../images/timem.png"

export default function PlanPage(props) {
  const isTeacher = useAuthorize('TEACHER');

const mealldata = props.location.state


  const { schoolId } = useParams();
  const isStudent = useAuthorize("STUDENT");
  const[ title, setTitle] = useState()
  const [price, setPrice] = useState(59)
  const [discount, setDiscount] = useState()
  const [alldata, setAlldata] = useState(mealldata)
  const [dis, setDis] = useState()
  
  const schoolDetails = useSelector(
    (state) => (state.school.loadSchool && state.school.loadSchool.data) || {}
  );
  const isRepresentative = useAuthorize("REPRESENTATIVE");
  const loadSchoolCourseDetails = useSelector(
    (state) =>
      (state.schoolCourses &&
        state.schoolCourses.loadSchoolCourse &&
        state.schoolCourses.loadSchoolCourse.data) ||
      []
  );
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  useEffect( async () => {
    // const newexistingEntries = await JSON.parse(localStorage.getItem("allEntries"));

    // const myarray =  await newexistingEntries
    // const  realdata=  await myarray[myarray.length - 1]
    // setAlldata(realdata)
   
  
    

    if (!mounted && schoolId && alldata) {
      dispatch(loadSchool(schoolId));
      dispatch(loadSchoolCourse(schoolId));
      setMounted(true);
    }

    // var coursedata = JSON.parse(localStorage.getItem("allCourseEntries"));
    // const ldata = JSON.parse([coursedata])
    // console.log("my course data", ldata)

   

  }, []);
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);
  const [courseInfoToBook, setCourseInfoTobook] = useState(null);
  
  const bookCourse = (courseInfo) => {
    if (isStudent) {
      setCourseInfoTobook(courseInfo);
      setIsOpenBookingModal(true);
    } else {
      document.getElementById("authButton")?.click();
    }
  };

  const showme = (qty, dis) => {
   setIsOpenBookingModal(true)
   setDiscount(price * qty.qty)
   setDis(dis.dis)
        //setTimeout(()=> {
        // setDiscount(price * qty.qty)
        // setDis(dis.dis)
       // }, 1000);
  }

  


  
   


 


  const loadBookingModal = () => {
    return (
      <CourseBookingModal
        schoolId={schoolId}
        courseInfo={courseInfoToBook}
        isOpen={isOpenBookingModal}
        handleClose={() => setIsOpenBookingModal(false)}
        alldata = {alldata}
        discount = {discount}
        dis = {dis}
      />
    );
  };
  return (
    <Layout className="detailsBlock">
     
     
        <Paper elevation={0} style={{ padding: "2%" }}>
          <Grid container spacing={2}>
            {loadSchoolCourseDetails?.map((course) => (
              <CourseBlock courseInfo={course} bookCourse={bookCourse} />
            ))}
          </Grid>
        </Paper>
        {isOpenBookingModal && loadBookingModal()}

          
   <div >

            <div className="uk-container uk-margin-large-top">


            <Grid item xs={12}>
                <Box component={Grid} container pb={15}>
                    <Grid className="uk-margin-top" item xs={12} md={6} >
                        <Paper component={Grid} container elevation={2}
                            style={{ textAlign:'center', padding: "5% 1%", width: '90%', margin: 'auto' }} spacing={9}>
                          


                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">GRADE</BodyText>
                                <img className="highlight-image" src={grade_icon} />
                                <BodyText>{alldata.grade}</BodyText>
                            </Grid> 
                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">SKILL LEVEL</BodyText>
                                <img className="highlight-image" src={skill_level_icon} />
                                <BodyText>{alldata.skillLevel}</BodyText>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">TOOLS</BodyText>
                                <img className="highlight-image" src={tools_icon} />
                                <BodyText>{alldata.tools || 'None'}</BodyText>
                            </Grid>
                             <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title" >PREREQUISITE</BodyText>
                                <img className="highlight-image" src={prerequisite_icon} />
                                <BodyText>{alldata.prerequisite || 'None'}</BodyText>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">PATH</BodyText>
                                <img className="highlight-image" src={path_icon} />
                                <BodyText>{alldata.path || 'None'}</BodyText>
                            </Grid> 

                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">LOCATION TYPE</BodyText>
                                <img className="highlight-image" src={locationType} />
                                <BodyText>{alldata.location_type || 'None'}</BodyText>
                            </Grid>

                            {
                                alldata.location_type === "OFFLINE" ? (
                                    <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">LOCATION</BodyText>
                                <img className="highlight-image" src={locationType} />
                                <BodyText>{alldata.location || 'None'}</BodyText>
                            </Grid>
                                ): (null)
                            }

                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">START DATE</BodyText>
                                <img className="highlight-image" src={cal_icon} />
                                <BodyText>{alldata.start_date || 'None'}</BodyText>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">END DATE</BodyText>
                                <img className="highlight-image" src={cal_icon} />
                                <BodyText>{alldata.end_date || 'None'}</BodyText>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">DAYS</BodyText>
                                <img className="highlight-image" src={timem} />
                                   {
                                    alldata && alldata.days &&
                                     alldata.days.map(item => {
                                    const dataReq = Data.Config.Days[item]
                                    return dataReq &&
                            <Grid item xs={12} sm={12}>
                               
                                <BodyText className="" style={{textAlign:'center'}}>{dataReq.displayName}</BodyText>
                            </Grid>

                    })
                }
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <BodyText style={{fontWeight:600}} className="highlight-title">START TIME</BodyText>
                                <img className="highlight-image" src={timem} />
                                <BodyText>{alldata.coursetime || 'None'}</BodyText>
                            </Grid>


                            {
                                alldata &&
                                    alldata.courseType !== 'AT_SCHOOL_LOCATION'
                                    ? <Grid item xs={12} style={{ textAlign: 'left' }}>
                                        {
                                            isTeacher &&
                                            <>
                                                <Link rounded component={PrimaryButton}
                                                    to={`/courses/${alldata?.id}/${(alldata.title || "").replaceAll(' ', '-')}/edit`}>Edit Course</Link>

                                                {/* <CourseScheduleBlock courseId={props.courseInfo.id} /> */}
                                            </>
                                        }
                                       
                                    </Grid>
                                    : <Grid item xs={12} style={{ textAlign: 'left' }}>
                                        {
                                            isTeacher &&
                                            <>
                                                <Link rounded component={PrimaryButton}
                                                    to={`/courses/${alldata?.id}/${(alldata?.title || "").replaceAll(' ', '-')}/edit`}>Edit Course</Link>
                                            </>
                                        }
                                    </Grid>
                            }


                        </Paper>
                        
                    </Grid>
                    <Grid  className= "uk-margin-top" item xs={12} md={6} style={{ position: 'relative' }}>
                        

                        <div className="uk-card uk-card-body uk-card-default" style={{bordeRaduis:5}}>
                        <SecondaryText style={{ fontSize: '18px' }} className="marginGapBottom">Course Description</SecondaryText>
                        <BodyText className="uk-text-left" style={{ lineHeight: '30px' }}>
                                {alldata.description}
                            </BodyText>

                            <SecondaryText  style={{ fontSize: '18px' }} className="marginGapBottom uk-margin-top">In this course, your child will learn</SecondaryText>
                            <BodyText className="uk-text-left" style={{ lineHeight: '30px' }}>
                                {alldata.highlightPoints}
                            </BodyText>


                        </div>

                        

                        
                    </Grid>

                    
                </Box>
            </Grid>
             
            </div>
             <div className="uk-container uk-margin-top">

                  <div className=" uk-flex uk-flex-center">
                        <div className="">
                             
                            <h1 className="uk-text-center header1">Choose your lesson <span className="greenText">package</span></h1>
                        </div>
                  </div>
              </div>



               <div className=" ">
                   <div className="uk-padding-large">
               <div className="uk-margin-large-bottom">
                       <div className ="uk-grid-medium" data-uk-grid>




                             {/**First Table**/}
                            <div className="uk-width-1-4@s">
                                   <div className="bundleContainer">
                                       <div className="lessonHeaderBg">
                                            <h4 className=" uk-text-center light"><span className="uk-text-bold">{alldata.title}</span></h4>
                                            <h4 className="uk-margin-small-top uk-text-center light">1 lesson <span className="uk-text-bold">per month</span></h4>
                                       </div>

                                       <div className="numberDiscDiv uk-padding-small">
                                            <div className="uk-grid-collapse" data-uk-grid>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className="uk-text-bold strikeText uk-text-center"><strike >65</strike></h3>
                                                      </div>
                                                   </div>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className=" blueText priceText uk-text-center"><span className="uk-text-bold">$59</span> <span className="uk-text-light">/ Lesson</span></h3>
                                                      </div>
                                                   </div>
                                            </div>
                                              {/***Second_div**/}
                                            <div className="uk-grid-collapse uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <p className="uk-text-center smallText" > Once a month</p>
                                                   </div>
                                                </div>

                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <h3 className="smallText greenBgBrder uk-text-center">Save 11% </h3>
                                                   </div>
                                                </div>
                                         </div>


                                       </div>

                                       {/***Item list container**/}
                                       <div className="uk-padding-small">
                                           <div>
                                               <p className="uk-text-bold  uk-text-center">Explore your own choice in tech</p>
                                               
                                           </div>

                                           <div className="uk-grid-collapse uk-margin-small-top uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Learn from anywhere with virtual 1-on-1 course</p>
                                                 </div>

                                           </div>

                                           <div className="uk-grid-collapse  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Many tech topics to choose from at the best rates</p>
                                                 </div>

                                           </div>


                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconSecure" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Exclusive attention from teacher adapted to student’s pace and knowledge </p>
                                                 </div>

                                           </div>

                                     
                                       </div>{/***Item list container ends HERE!!!!**/}



                                             {/**CTA button**/}
                                             <div className="uk-flex uk-flex-center">
                                               
                                                      <h4 onClick={()=>showme({qty:'1'}, {dis:'one lesson(s) per month'})
                                                          
                                                    } className="CTA_select_btn">
                                                           Select
                                                           </h4>
                                                      
                                             </div>

                                            


                                                  {/***SECURE**/}
                                            <div className="uk-grid-collapse uk-padding-small uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-3">
                                                   <div className="uk-margin-left">
                                                       <img src= {secured}  className="iconSecure"/>
                                                   </div>
                                                </div>

                                                <div className="uk-width-2-3">
                                                   <div className="">
                                                       <p className="smallText uk-text-bold uk-text-center"> Nos risk, cancel any time </p>
                                                   </div>
                                                </div>
                                         </div>

                                   </div>
                            </div >







                           
                             {/**SECOND Table**/}
                            <div className="uk-width-1-4@s">
                                   <div className="bundleContainer">
                                       <div className="lessonHeaderBgBlue">
                                       <h4 className=" uk-text-center light"><span className="uk-text-bold">{alldata.title}</span></h4>
                                            <h4 className="uk-margin-small-top uk-text-center light">4 lessons <span className="uk-text-bold">per month</span></h4>
                                       </div>

                                       <div className="numberDiscDiv uk-padding-small">
                                            <div className="uk-grid-collapse" data-uk-grid>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className="uk-text-bold strikeText uk-text-center"><strike >65</strike></h3>
                                                      </div>
                                                   </div>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className=" blueText priceText uk-text-center"><span className="uk-text-bold">$59</span> <span className="uk-text-light">/ Lesson</span></h3>
                                                      </div>
                                                   </div>
                                            </div>
                                              {/***Second_div**/}
                                            <div className="uk-grid-collapse uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <p className="uk-text-center smallText" > 4 times a month</p>
                                                   </div>
                                                </div>

                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <h3 className="smallText greenBgBrder uk-text-center">Save 11% </h3>
                                                   </div>
                                                </div>
                                         </div>


                                       </div>

                                       {/***Item list container**/}
                                       <div className="uk-padding-small">
                                           <div>
                                               <p className="uk-text-bold  uk-text-center">Build tech fundamentals </p>
                                               
                                           </div>

                                           <div className="uk-grid-collapse uk-margin-small-top uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="s" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Learn from anywhere with virtual 1-on-1 courses </p>
                                                 </div>

                                           </div>

                                           <div className="uk-grid-collapse  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Many tech topics to choose from at the best rates</p>
                                                 </div>

                                           </div>


                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Exclusive attention from teacher adapted to student’s pace and knowledge </p>
                                                 </div>

                                           </div>

                                                    
                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Start learning coding principles </p>
                                                 </div>

                                           </div>








                                     
                                       </div>{/***Item list container ends HERE!!!!**/}



                                             {/**CTA button**/}
                                             <div className="uk-flex uk-flex-center">
                                                  
                                             <h4 onClick={()=>showme({qty:'4'}, {dis:'4 lesson(s) per month'})
                                                          
                                                        } className="CTA_select_btn">
                                                               Select
                                                               </h4>
                                                  
                                             </div>

                                            


                                                  {/***SECURE**/}
                                            <div className="uk-grid-collapse uk-padding-small uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-3">
                                                   <div className="uk-margin-left">
                                                       <img src= {secured}  className="iconSecure"/>
                                                   </div>
                                                </div>

                                                <div className="uk-width-2-3">
                                                   <div className="">
                                                       <p className="smallText uk-text-bold uk-text-center"> Nos risk, cancel any time </p>
                                                   </div>
                                                </div>
                                         </div>




                                   </div>
                            </div >









                           
                             {/**THIRD Table**/}
                            <div className="uk-width-1-4@s">
                                   <div className="bundleContainer">
                                       <div className="lessonHeaderBg">
                                       <h4 className=" uk-text-center light"><span className="uk-text-bold">{alldata.title}</span></h4>
                                            <h4 className="uk-margin-small-top uk-text-center light">8 lessons <span className="uk-text-bold">per month</span></h4>
                                       </div>

                                       <div className="numberDiscDiv uk-padding-small">
                                            <div className="uk-grid-collapse" data-uk-grid>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className="uk-text-bold strikeText uk-text-center"><strike >65</strike></h3>
                                                      </div>
                                                   </div>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className=" blueText priceText uk-text-center"><span className="uk-text-bold">$59</span> <span className="uk-text-light">/ Lesson</span></h3>
                                                      </div>
                                                   </div>
                                            </div>
                                              {/***Second_div**/}
                                            <div className="uk-grid-collapse uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <p className="uk-text-center smallText" > 8 times a month</p>
                                                   </div>
                                                </div>

                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <h3 className="smallText greenBgBrder uk-text-center">Save 11% </h3>
                                                   </div>
                                                </div>
                                         </div>


                                       </div>

                                       {/***Item list container**/}
                                       <div className="uk-padding-small">
                                           <div>
                                               <p className="uk-text-bold  uk-text-center">Advance in tech projects design </p>
                                               
                                           </div>

                                           <div className="uk-grid-collapse uk-margin-small-top uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Learn from anywhere with virtual 1-on-1 course</p>
                                                 </div>

                                           </div>

                                           <div className="uk-grid-collapse  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Exclusive attention from teacher adapted to student’s pace and knowledge </p>
                                                 </div>

                                           </div>


                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Best rates for tech education  </p>
                                                 </div>

                                           </div>


                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Advance your coding principles </p>
                                                 </div>

                                           </div>



                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall"> A wide range of topics and project designsn  </p>
                                                 </div>

                                           </div>

                                     
                                       </div>{/***Item list container ends HERE!!!!**/}



                                             {/**CTA button**/}
                                             <div className="uk-flex uk-flex-center">
                                                
                                             <h4 onClick={()=>showme({qty:'8'}, {dis:'8 lesson(s) per month'})
                                                          
                                                        } className="CTA_select_btn">
                                                               Select
                                                               </h4>
                                                  
                                             </div>

                                            


                                                  {/***SECURE**/}
                                            <div className="uk-grid-collapse uk-padding-small uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-3">
                                                   <div className="uk-margin-left">
                                                       <img src= {secured}  className=""/>
                                                   </div>
                                                </div>

                                                <div className="uk-width-2-3">
                                                   <div className="">
                                                       <p className="smallText uk-text-bold uk-text-center"> Nos risk, cancel any time </p>
                                                   </div>
                                                </div>
                                         </div>




                                   </div>
                            </div >







                            
                            




                            
                             {/**FOURTH Table**/}
                            <div className="uk-width-1-4@s">
                                   <div className="bundleContainer">
                                       <div className="lessonHeaderBg">
                                       <h4 className=" uk-text-center light"><span className="uk-text-bold">{alldata.title}</span></h4>
                                            <h4 className="uk-margin-small-top uk-text-center light">12 lessons <span className="uk-text-bold">per month</span></h4>
                                       </div>

                                       <div className="numberDiscDiv uk-padding-small">
                                            <div className="uk-grid-collapse" data-uk-grid>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className="uk-text-bold strikeText uk-text-center"><strike >65</strike></h3>
                                                      </div>
                                                   </div>

                                                   <div className="uk-width-1-2">
                                                      <div>
                                                          <h3 className=" blueText priceText uk-text-center"><span className="uk-text-bold">$59</span> <span className="uk-text-light">/ Lesson</span></h3>
                                                      </div>
                                                   </div>
                                            </div>
                                              {/***Second_div**/}
                                            <div className="uk-grid-collapse uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <p className="uk-text-center smallText" > 12 times a month</p>
                                                   </div>
                                                </div>

                                                <div className="uk-width-1-2">
                                                   <div>
                                                       <h3 className="smallText greenBgBrder uk-text-center">Save 11% </h3>
                                                   </div>
                                                </div>
                                         </div>


                                       </div>

                                       {/***Item list container**/}
                                       <div className="uk-padding-small">
                                           <div>
                                               <p className="uk-text-bold  uk-text-center">Fastrack to tech expert level </p>
                                               
                                           </div>

                                           <div className="uk-grid-collapse uk-margin-small-top uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Learn from anywhere with virtual 1-on-1 courses</p>
                                                 </div>

                                           </div>

                                           <div className="uk-grid-collapse  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Exclusive attention from teacher adapted to student’s pace and knowledge</p>
                                                 </div>

                                           </div>


                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Best rates for tech education  </p>
                                                 </div>

                                           </div>



                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Advance your coding principles</p>
                                                 </div>

                                           </div>



                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">A wide range of topics and project designs </p>
                                                 </div>

                                           </div>


                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Mix and match your own tech topics </p>
                                                 </div>

                                           </div>


                                           <div className="uk-grid-collapse uk-margin-top-remove  uk-padding-small" data-uk-grid>
                                                <div className="uk-width-1-5">
                                                      <img src= {check} className="iconCheck" />
                                                 </div>
                                                 
                                                 <div className="uk-width-4-5">
                                                      <p className="iconParagraphSmall">Save on the best value package </p>
                                                 </div>

                                           </div>

                                     
                                       </div>{/***Item list container ends HERE!!!!**/}



                                             {/**CTA button**/}
                                             <div className="uk-flex uk-flex-center">
                                                
                                             <h4 onClick={()=>showme({qty:'12'}, {dis:'12 lesson(s) per month'})
                                                          
                                                        } className="CTA_select_btn">
                                                               Select
                                                               </h4>
                                                
                                             </div>

                                            


                                                  {/***SECURE**/}
                                            <div className="uk-grid-collapse uk-padding-small uk-margin-remove-top" data-uk-grid>
                                                
                                                <div className="uk-width-1-3">
                                                   <div className="uk-margin-left">
                                                       <img src= {secured}  className="iconCheck"/>
                                                   </div>
                                                </div>

                                                <div className="uk-width-2-3">
                                                   <div className="">
                                                       <p className="smallText uk-text-bold uk-text-center"> Nos risk, cancel any time </p>
                                                   </div>
                                                </div>
                                         </div>




                                   </div>
                            </div >





                       </div>
                       
                    </div>
            </div>
            </div>


           

</div>

        
      

    
    </Layout>
  );
}

const TickLabels = (props) => (
  <Typography
    variant="body1"
    {...props}
    className="bodyText"
    component="p"
    style={{ display: "flex", marginBottom: 15, marginTop: 10, ...props.style }}
  >
    <img
      src={tick_icon}
      style={{ width: "9%", marginRight: 10, objectFit: "contain" }}
    />
    <BodyText style={{ fontSize: "16px", lineHeight: "18px" }}>
      {props.label}
    </BodyText>
  </Typography>
);

const CourseBlock = (props) => {
  if(props?.courseInfo?.isActive)
  return (
    <Grid item xs={12} md={6}>
      <Paper style={{ padding: "2%", border: "2px solid #72d66d" }}>
        <Grid container>
          <Grid item xs={12}>
            <h3 style={{ textAlign: "center" }}>{props?.courseInfo?.title}</h3>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ padding: "2%" }}
            >
              <Grid item xs={6}>
                <h3>Grade {props?.courseInfo?.grade}</h3>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                {/* HIDDEN AS PER REQ */}
                {/* <PrimaryButton style={{ backgroundColor: "#ff3e6c" }}>
                  Slot:{" "}
                  {props?.courseInfo?.totalSlot - props?.courseInfo?.bookedSlot}{" "}
                  left
                </PrimaryButton> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/* schedule data info */}
            {props?.courseInfo?.slotDateTimes?.map((slotTime) => (
              <ClassDateSection timings={slotTime} />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "right" }}
            className="marginGapBottom"
          >
            <PrimaryButton
              className="marginGapBottom"
              onClick={() => props?.bookCourse(props?.courseInfo)}
            >
              Register
            </PrimaryButton>
          </Grid>
          <Grid item xs={12} style={{ padding: "5%" }}>
            <h3>Course Description</h3>
            <BodyText className="marginGapTop">
              {props?.courseInfo?.description}
            </BodyText>
            <Paper style={{ padding: "5%", marginTop: 20 }}>
              <h3>
                In this Course{" "}
                <span style={{ color: "#72d66d" }}>you will</span>{" "}
              </h3>
              {props?.courseInfo?.highlightPoints?.map((point) => (
                <TickLabels label={point} />
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <PrimaryButton
              style={{ padding: 20, minWidth: "40ch", borderRadius: 40 }}
              onClick={() => props?.bookCourse(props?.courseInfo)}
            >
              Register For this Course
            </PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
  return (<></>)
};

const ClassDateSection = (props) => {
  const classTime = moment(props?.timings);
  return (
    <Grid
      container
      style={{ background: "#106a75", padding: "2%", margin: "10px 0" }}
    >
      <Grid item xs={8}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            fontSize: 17,
            color: "#fff",
            fontWeight: 600,
          }}
        >
          <TodayIcon style={{ color: "#72d66d", fontSize: 33 }} />
          {/* <span style={{ marginLeft: 10 }}>10-12-2021</span> */}
          <span style={{ marginLeft: 10 }}>
            <Moment format="MMM DD, YYYY">{props?.timings}</Moment>
          </span>
        </div>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        <div
          style={{
            display: "flex",
            alignItems: "right",
            flexWrap: "wrap",
            fontSize: 17,
            color: "#fff",
            fontWeight: 600,
            justifyContent: 'flex-end'
          }}
        >
          <span style={{ marginLeft: 10, marginTop: 5 }}>
            <div style={{ textAlign: "right" }}>
              <Moment format="hh:mm A">{classTime}</Moment>
            </div>
          </span>
        </div>
      </Grid>
    </Grid>
  );
};
