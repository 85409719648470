import { Field, Formik } from "formik";
import {
  PrimaryButton,
  AutoCompleteInputBox,
  TextInputBox,
  SecondaryText,
} from "./../../../features/Atoms";
import Data from "./../../../Data";
import {
  Backdrop,
  Grid,
  Paper,
  CircularProgress,
  Modal,
  Container,
  Dialog,
  DialogContent,
  Switch
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import MediaUpload from "./../../../features/MediaUpload/media";

import { saveSchoolCourse } from "./../../../DataStore/SchoolCoursesReducer";
import axios from 'axios'
export default (props) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const [title, setTitle] = useState()
  const [highlightPoints, setHighlightPoints] = useState()
  const [courseImage, setCourseImage] = useState()


  const CreateCourse = ()=> {
    const data = {
      schoolId: props?.schoolId,

    }
    console.log("this is test data",data)
  
    // axios.post('http://localhost:27019/course-api/schools/course',data).then((res)=>{
    //   console.log(res)
    // })
  }


  useEffect(()=>{

     
    if(title === ""){
        return
    }else{
       
        const newfilter = course_name.filter((item)=> item.id == title).map(item => item.highlight)
        setHighlightPoints(newfilter)
       

    }
     
 },[title])
  const dispatch = useDispatch();
  const initialData = {
    title: "",
    description: "",
    subTitle: "",
    subDescription: "",
    price: "",
    mrpPrice: "",
    grade: "",
    skillLevel: "",
    highlightPoints:highlightPoints,
    courseCategory: "",
    courseType: "",
    duration: "",
    coverImage: courseImage,
    totalSlot: "",
    slotDateTimes: [],
    endslotDateTimes:[],
    isActive: 1,
    path: "",
    tools: "",
    locationType:"AT_SCHOOL_LOCATION",
    days:[]
  };
  const [slotDateTimes, setSlotDateTimes] = useState([]);
  const [endslotDateTimes, setEndslotDateTimes] = useState([]);
  const [courseIsActiveState, setCourseIsActiveState] = useState((props?.courseInfo?.isActive) ?? 0);
  const classes = useStyles();
  const [isOpenTimeEdit, setIsOpenTimeEdit] = useState({
    state: false,
    index: -1,
  });


  const [isOpenEndTimeEdit, setIsOpenEndTimeEdit] = useState({
    state: false,
    index: -1,
  });
 
  const [course_name, setCourse_name] = useState([
    {
      id: 'Lego Robotics Grades ( k-2)',
      displayName: 'Lego Robotics Grades ( k-2)',
      highlight:''
      
    },
    {
      id: 'Visual Programming with Scratch Grades (3-6)',
      displayName: 'Visual Programming with Scratch Grades (3-6)',
      highlight:''
      
    },
    {
    id: '3D Animation with Minecraft 1-3',
    displayName: '3D Animation with Minecraft (Grade 1-3)',
    highlight:'Develop their 3D motion and observation skills, identify scene composition elements,Develop their 3D motion and observation skills, identify scene composition elements, Have fun and keep their animation project to share with other Minecraft fans'
    
  },
  {
  id: '3D Animation with Minecraft 4-7',
  displayName: '3D Animation with Minecraft (Grade 4-7)',
  highlight:'Improve 3D animation design skills and understanding of scene composition, Gain confidence by translating motion to reality through animation with their own characters and moving objects, Have fun and get their own animated project to share online with other Minecraft fans'
  
},
{
    id: '3D Character Modeling & Sculpting 1-3',
    displayName: '3D Character Modeling & Sculpting (Grade 1-3)',
    highlight:'Develop observation skills and learn how to interpret and modify digital 3D space, Create their own characters and objects, build character sheets and master using a tablet, Get introduced to digital sculpting and painting and have fun designing their own projects'
    
    
  },

  {
    id: '3D Character Modeling & Sculpting 4-7',
    displayName: '3D Character Modeling & Sculpting (Grade 4-7)',
    highlight:'Improve digital sculpting and painting skills and 3D space manipulation, Learn visual anatomy notions and how to draft character sheets, Develop creativity and problem solving abilities by designing their own characters and moving objects'
    
    
  },

  {
    id: '3D Game Production & Design 1-3',
    displayName: '3D Game Production & Design (Grade 1-3)',
    highlight:'Learn about basic game theory and planning a level while building problem solving skills, Let imagination run wild and understand concepts in video art design & composition, Get an intro to game design elements and challenges inside the video game universe'
    
    
  },

  {
    id: '3D Game Production & Design 4-7',
    displayName: '3D Game Production & Design (Grade 4-7)',
    highlight:'Design items and game obstacles and puzzles to develop their strategic thinking abilities, Learn about video art design & composition concepts to boost creativity and design skills,Understand  principles of video game design, game theory and implementing AI and enemies'
    
    
  },

  {
    id: 'Adventure Game Coding with Scratch 1-3',
    displayName: 'Adventure Game Coding with Scratch (Grade 1-3)',
    highlight:'Develop keyboard confidence, problem solving abilities and coding logic, Be introduced to programming concepts, topics like variables and program loops, Observe coding efficiency as they design their own games in popular Super Mario style '
    
    
  },

  {
    id: 'Adventure Game Coding with Scratch 4-7',
    displayName: 'Adventure Game Coding with Scratch (Grade 4-7)',
    highlight:'Improve coding logic and problem solving, increase coding efficiency and understanding of principles, Apply programming concepts and learn all about physics based movement, hit detection, animation, Create their own platform adventure game complete with animations, physics simulations, UI'
    
    
  },
  {
    id: 'Arcade Game Coding with Scratch 1-3',
    displayName: 'Arcade Game Coding with Scratch (Grade 1-3)',
    highlight:'Get an intro to programming concepts and develop problem solving abilities and keyboard confidence, Cover topics like variables and program loops to gain coding efficiency, Design their own games in the style of classic arcade games to share with other fans '

  },
  {
    id: 'Arcade Game Coding with Scratch 4-7',
    displayName: 'Arcade Game Coding with Scratch (Grade 4-7)',
    highlight:'Improve problem solving abilities, increase coding efficiency and boost tech skills, Understand and apply programming concepts, test variables and program loops, Design their own games and get to share it with other arcade game fans'

  },
  {
    id: 'Game Design and C# Coding in Unity 4-7',
    displayName: 'Game Design and C# Coding in Unity (Grade 4-7)',
    highlight:'Design a functional game, by using Unity, a real game engine and design in-game objects in C# (C sharp), Increase coding efficiency, strategic thinking, problem solving and gain confidence from having developed their project from A to Z, Apply programming concepts and logic, test variables, program loops and game design principles'
    

  },
  {
    id: 'Minecraft Modding 1-3',
    displayName: 'Minecraft Modding (Grade 1-3)',
    highlight:'Start building tech skills, improve problem solving and coding efficiency ,Use Camp time to create from scratch their own project and gain keyboard confidence ,Have a lot of fun and get a design they can take home and share with other friends'
    

  },
  {
    id: 'Minecraft Modding 4-7',
    displayName: 'Minecraft Modding (Grade 4-7)',
    highlight:'Advance their tech skills, increase coding efficiency and knowledge, Apply critical thinking to create from scratch their own project or design, Have a fun Camp time and go home with a design they can share with other friends '
    

  },

  {
    id: 'Roblox Adventure Game Creation 1-3',
    displayName: 'Roblox Adventure Game Creation (Grade 1-3)',
    highlight:'Learn about topics like game design, variables and program loops to understand game creation, Gain coding efficiency and keyboard confidence, and develop problem solving abilities ,Get an intro to programming concepts and design elements and challenges inside Roblox game universe'
    

  },
  {
    id: 'Roblox Adventure Game Creation 4-7',
    displayName: 'Roblox Adventure Game Creation (Grade 4-7)',
    highlight:'Design items and game elements to develop their problem solving abilities, Increase coding efficiency and keyboard confidence while bringing to life a unique version of games in Roblox, Apply learned programming concepts, test variables, program loops and game design principles '
    

  },
  {
    id: 'Roblox Obby Creation 1-3',
    displayName: 'Roblox Obby Creation (Grade 1- 3)',
    highlight:'Challenge and work together with friends in building obbies in Roblox by coding in LUA, Familiarize himself with programming concepts and coding principles for game design ,Bring their own idea for an obstacle to life with newly learned coding skills which strengthens also keyboard confidence'
    

  },
  {
    id: 'Roblox Obby Creation 4-7',
    displayName: 'Roblox Obby Creation (Grade 4- 7)',
    highlight:'Apply programming fundamentals, test variables and program loops to create their own obbies in Roblox ,Improve coding efficiency  and keyboard confidence, build problem solving abilities ,Get to share and challenge other friends with obstacles and power-ups programmed with LUA during camp time'
    

  },
])
  useEffect(() => {
    if (props?.courseInfo?.slotDateTimes) {
      setSlotDateTimes(props?.courseInfo?.slotDateTimes);
    } else {
      setSlotDateTimes([]);
    }


    if (props?.courseInfo?.endslotDateTimes) {
      setEndslotDateTimes(props?.courseInfo?.endslotDateTimes);
    } else {
      setEndslotDateTimes([]);
    }
  }, []);
  const setSelectedDateTime = (data, index) => {
    if (!data) return;
    if (index < 0) {
      setSlotDateTimes([...slotDateTimes, data]);
    } else {
      let updatedTimes = [...slotDateTimes];
      updatedTimes[index] = data;
      setSlotDateTimes(updatedTimes);
    }
  };

  const setSelectedEndDateTime = (data, index) => {
    if (!data) return;
    if (index < 0) {
      setEndslotDateTimes([...endslotDateTimes, data]);
    } else {
      let updatedTimes = [...endslotDateTimes];
      updatedTimes[index] = data;
      setEndslotDateTimes(updatedTimes);
    }
  };
  const validateForm = (values) => {
    const errors = {};
    if (!values.title) errors.title = "Required";
    if (!values.description) errors.description = "Required";
    if (!values.grade) errors.grade = "Required";
    if (!values.duration) errors.duration = "Required";
    if (!values.price) errors.price = "Required";
    // if (!values.mrpPrice) errors.mrpPrice = "Required";
    return errors;
  };
  const doSubmit = (values, action) => {
    const formData = {
      ...initialData,
      ...values,
      slotDateTimes: slotDateTimes,
      endslotDateTimes: endslotDateTimes,
    };
    dispatch(saveSchoolCourse({ ...formData, schoolId: props?.schoolId, isActive: courseIsActiveState }));
  };

  const setTimeEdit = (index) => {
    setIsOpenTimeEdit({ state: true, index: index });
  };

  const setEndTimeEdit = (index) => {
    setIsOpenEndTimeEdit({ state: true, index: index });
  };
  const deleteTimeSlot = (index) => {
    let updatedTimeSlot = [];
    updatedTimeSlot = slotDateTimes.filter((timeSlot, i) => {
      return i != index;
    });
    setSlotDateTimes([...updatedTimeSlot]);
  };

  const deleteEndTimeSlot = (index) => {
    let updatedTimeSlot = [];
    updatedTimeSlot = endslotDateTimes.filter((timeSlot, i) => {
      return i != index;
    });
    setEndslotDateTimes([...updatedTimeSlot]);
  };
  return (
    <Dialog
      open={props.open}
      onClose={props?.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={{
            ...initialData,
            ...(props.courseInfo || {}),
          }}
          validate={validateForm}
          onSubmit={doSubmit}
          className={classes.paper}
        >
          {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={4}
                component={Paper}
                variant="outlined"
                style={{ padding: "2%" }}
                direction="row"
              >
                <Field type="hidden" name="id" />
                <Grid item xs={12} md={12}>
                <AutoCompleteInputBox
                    options={Object.keys(Data.Config.Course_name)}
                    getOptionLabel={(option) => Data.Config.get(Data.Config.Course_name, option, 'displayName',setTitle(option.course_name))}
                    setFieldValue={setFieldValue}
                    required
                    label="Course Title"
                    name="title"
                  />
                  {/* <TextInputBox
                    name="title"
                    label="Title"
                    required
                    style={{ width: "100%" }}
                  /> */}
                  <TextInputBox
                    style={{ margin: 10, marginLeft: 0, marginRight: 10 }}
                    type="number"
                    name="price"
                    label="Offered Price"
                    required
                  />
                  {/* <TextInputBox
                    style={{ margin: 10, marginLeft: 0, marginRight: 10 }}
                    type="number"
                    name="mrpPrice"
                    label="Mrp Price"
                    
                  /> */}
                  <TextInputBox
                    style={{ margin: 10, marginLeft: 0, marginRight: 10 }}
                    name="duration"
                    label="Duration"
                    required
                  />
                  {/* <TextInputBox
                    style={{ margin: 10, marginLeft: 0, marginRight: 10 }}
                    type="number"
                    name="totalSlot"
                    label="Total Slots"
                    required
                  /> */}
                  <AutoCompleteInputBox
                    options={Object.keys(Data.Config.Grade)}
                    getOptionLabel={(option) =>
                      Data.Config.get(Data.Config.Grade, option, "displayName")
                    }
                    setFieldValue={setFieldValue}
                    required
                    label="Grade"
                    name="grade"
                  />

                   <AutoCompleteInputBox
                    options={Object.keys(Data.Config.SkillLevel)}
                    getOptionLabel={(option) =>
                      Data.Config.get(Data.Config.SkillLevel, option, "displayName")
                    }
                    setFieldValue={setFieldValue}
                    required
                    label="Skill Level"
                    name="skillLevel"
                  />
                   <TextInputBox
                    style={{width:'100%', margin: 10, marginLeft: 0, marginRight: 10 }}
                    name="path"
                    label="path"
                    required
                  />
                   <AutoCompleteInputBox multiple
                                        options={Object.keys(Data.Config.Days)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Days, option, 'displayName')}
                                        setFieldValue={setFieldValue}
                                        label="Days"
                                        name="days" />

                 



{/* 
                  <AutoCompleteInputBox
                    multiple
                    style={{ width: "100%", margin: "10px 0" }}
                    options={[]}
                    getOptionLabel={(option) => option}
                    setFieldValue={setFieldValue}
                    label="Highlight Points"
                    multiple
                    freeSolo
                    name="highlightPoints"
                  /> */}
                  <TextInputBox
                    style={{ width: "100%", margin: "10px 0" }}
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                    required
                  />
                  {/* ================ IS ACTIVE ======================= */}
                  <SecondaryText>Course Enable</SecondaryText>
                  <Switch
                    name="isActive"
                    label="Course Enable"
                    value={courseIsActiveState}
                    checked={courseIsActiveState}
                    onChange={(event, checked) => {
                      setCourseIsActiveState(checked ? 1 : 0);
                    }}
                  />
                  <br />
                  {/* ================ IS ACTIVE ======================= */}
                  <Link
                    variant="contained"
                    component={PrimaryButton}
                    onClick={() => setTimeEdit(-1)}
                    style={{ margin: "10px 0" }}
                  >
                    ADD COURSE START TIME
                  </Link>

                  <Link
                    variant="contained"
                    component={PrimaryButton}
                    onClick={() => setEndTimeEdit(-1)}
                    style={{ margin: "10px 10px" }}
                  >
                    ADD COURSE END TIME
                  </Link>
                </Grid>
                <Grid item xs={12} md={12}>
                  {
                    <Dialog
                      open={isOpenTimeEdit.state}
                      onClose={() =>
                        setIsOpenTimeEdit({ state: false, index: -1 })
                      }
                    >
                      <DialogContent>
                        <ClasstimeSchedule
                          onSubmit={setSelectedDateTime}
                          index={isOpenTimeEdit.index}
                          slotDateTimes={slotDateTimes[isOpenTimeEdit.index]}
                        />
                      </DialogContent>
                    </Dialog>
                  }



                  {/* endtime */}


                  {
                    <Dialog
                      open={isOpenEndTimeEdit.state}
                      onClose={() =>
                        setIsOpenEndTimeEdit({ state: false, index: -1 })
                      }
                    >
                      <DialogContent>
                        <ClasstimeSchedule
                          onSubmit={setSelectedEndDateTime}
                          index={isOpenEndTimeEdit.index}
                          endslotDateTimes={endslotDateTimes[isOpenEndTimeEdit.index]}
                        />
                      </DialogContent>
                    </Dialog>
                  }




                  {/* COURSE TIMING SLOTS */}
                  {slotDateTimes.map((timings, index) => (
                    <Paper style={{ padding: 5, float: "left", margin: 5 }}>
                      <SecondaryText>
                        <b> Start Date: </b>
                        <Moment format="MMM DD, YYYY">{timings}</Moment>
                      </SecondaryText>
                      <SecondaryText>
                        <b> Start Time: </b>
                        <Moment format="hh:mm A">{timings}</Moment>
                      </SecondaryText>
                      <Link
                        component={PrimaryButton}
                        onClick={() => setTimeEdit(index)}
                      >
                        Edit
                      </Link>
                      <Link
                        component={PrimaryButton}
                        onClick={() => deleteTimeSlot(index)}
                      >
                        Delete
                      </Link>
                    </Paper>
                  ))}




                  {/* END TIMING */}

                  {endslotDateTimes.map((timings, index) => (
                    <Paper style={{ padding: 5, float: "left", margin: 5 }}>
                      <SecondaryText>
                        <b> End Date: </b>
                        <Moment format="MMM DD, YYYY">{timings}</Moment>
                      </SecondaryText>
                      <SecondaryText>
                        <b> End Time: </b>
                        <Moment format="hh:mm A">{timings}</Moment>
                      </SecondaryText>
                      <Link
                        component={PrimaryButton}
                        onClick={() => setEndTimeEdit(index)}
                      >
                        Edit
                      </Link>
                      <Link
                        component={PrimaryButton}
                        onClick={() => deleteEndTimeSlot(index)}
                      >
                        Delete
                      </Link>
                    </Paper>
                  ))}





                </Grid>
                <MediaUpload
                 imageUploadCallback={(img) => setCourseImage(img)}
                  label="Cover Image"
                  coverImage={courseImage}
                  required
                />


                <PrimaryButton
                  style={{
                    width: "100%",
                    background: "#72d56e",
                    color: "white",
                  }}
                   type="submit"
                  // onClick={CreateCourse()}
                >
                  Save Course
                </PrimaryButton>

               
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const ClasstimeSchedule = (props) => {
  const [dateTime, setDateTime] = useState(props?.slotDateTimes ?? new Date());
  useEffect(() => {
    setDateTime(props.slotDateTimes ?? "");
  }, []);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <br />
      <KeyboardDateTimePicker
        value={dateTime}
        onChange={setDateTime}
        label="Date and Time"
        disablePast
        format="yyyy/MM/dd hh:mm"
        style={{ margin: "10px 0" }}
      />
      <Link
        component={PrimaryButton}
        variant="contained"
        style={{ margin: 10 }}
        onClick={() => props?.onSubmit(dateTime, props.index)}
      >
        ADD
      </Link>
    </MuiPickersUtilsProvider>
  );
};
