import react from 'react'
//import staticImg from './../../images/teen_boy.jpg'
import { Link } from 'react-router-dom'
import './form.css'
import { useSelector,  useDispatch } from 'react-redux'
import Layout from "../Layout";
import { removeFromCart } from "../../DataStore/CartReducer";
import { useState, useEffect } from 'react';
import bg from './teen_boy.jpg';
import {
   HeaderBox,
   HeaderTitle,
   PageBlock,
   PrimaryButton,
   PrimaryText,
   SecondaryText,
   TextInputBox,
 } from "../../features/Atoms";





export default function Form3 (){
 
 
   const CartData = JSON.parse(localStorage.getItem('Cartdata')) || []

   const dispatch = useDispatch();
   const cartItems = useSelector( (state)=> state.cart.value)
  
   const [data, setData] = useState(CartData)

   console.log("this is data", data)
   const item = data

   // sum up price from data array
   const total = data.reduce((acc, item) => acc + item.price, 0);

   // useEffect(()=>{
   //    async function GetItems() {
   //      const  response = await cartItems
   //       setData(response)
   //     }
   
   //     GetItems()
    
     
   // })

   // if cartData is an empty array set show item to false
   const showItem = data.length > 0 ? true : false

   // a founction to delete item from cart and update local storage
   const deleteItem = (id) => {
      const newCartData = data.filter((item) => item.id !== id);
      setData(newCartData);
      localStorage.setItem("Cartdata", JSON.stringify(data));
   }

   // useeffect function to watch for changes in data array and update local storage
   useEffect(() => {
      localStorage.setItem("Cartdata", JSON.stringify(data));
   }, [data]);





    return(
            <Layout>
                <HeaderBox style={{ paddingTop: '25%', }}
                    coverPic={bg}>
                   
                </HeaderBox>



             {
               showItem ?  <section>
                   <div className='uk-container uk-margin-medium-large-bottom uk-margin-large-top'>
                <h3 className='uk-text-bold uk-text-left'>Your Cart</h3>
              </div>


              <div className='uk-container uk-margin-top uk-margin-large-bottom'>
                  <div className=' uk-flex'>
                 <div className='formGrayBg  coursInfo uk-margin-bottom'>
                      <h4 className='uk-text-bold  uk-text-left'>Student & Course Info</h4>

                {
                     data.map((item ,index)=>{
                        return(
                          <div key={index}>
                            <div class="uk-flex uk-margin-top">
                    <div class=" coursInfo"><h6 className='uk-text-bold'>Name: </h6></div>
                    <div class=" uk-margin-small-left  coursInfo"><p> {item.firstName} {item.lastName} </p></div>
                 </div>

                 <div class="uk-flex uk-margin-top">
                    <div class=" coursInfo"><h6 className='uk-text-bold'>Student Age: </h6></div>
                    <div class="uk-margin-small-left   coursInfo"><p> {item.studentAge} </p></div>
                 </div>

                 <div class="uk-flex uk-margin-top">
                    <div class=" coursInfo"><h6 className='uk-text-bold'>Course:</h6></div>
                    <div class="uk-margin-small-left   coursInfo"><p> {item.title}  </p></div>
                 </div>

                 <div class="uk-flex uk-margin-top">
                    <div class="coursInfo "><h6 className='uk-text-bold '>Grade: </h6></div>
                    <div class=" uk-margin-small-left coursInfo"> <p>{item.grade} </p></div>
                 </div>


                  {/* <div className='uk-margin-medium-top'>
                     <h6 className='editBtn'>Edit</h6>
                  </div> */}

                   <div  className='uk-text-left hidden-lg uk-margin-top'>
                        <h6 className=''>{item.firstName} {item.lastName} Total:  ${item.price}</h6> 

                  </div>

                  <div className='uk-margin-medium-top'>
                     <h6 
                     onClick={()=>deleteItem(item.id)}
                      className='removeBtn'>Remove Course</h6>
                  </div>


                  <div style={{marginTop:-20,}} className='uk-text-center hidden-xs '>
                        <h6 className=''>{item.firstName} {item.lastName} Total:  ${item.price}</h6> 

                  </div>
                  <hr className='formLine '></hr>
                           </div>
                        )
                     })
                        
                        
                     
                     
                }

                  

                   
                  <div className='uk-margin-large-top uk-text-center'>
                        <h6 className=''> Your Total:  ${total}</h6>

                  </div>

                 


                  <div className='row hidden-xs' style={{display:'flex', justifyContent:'center', marginTop:10, marginBottom:10}}>
                  <Link 
                         to={{ 
                           pathname: `/schools`,
                          
                         }}
                         
                         >
                  <button className="uk-button uk-button-default uk-text-capitalize " style={{backgroundColor:'#73d56e', color:'white', borderRadius:20, marginRight:5}}>Add Another Course</button>
                  </Link>

                  <Link to='/checkout'> 
                 < button className="uk-button uk-button-default uk-text-capitalize" style={{backgroundColor:'#73d56e', color:'white', borderRadius:20, marginLeft:5}}>Check out</button>
                 </Link>
                  </div>

                  <div className='row hidden-lg' style={{display:'flex', justifyContent:'center', marginTop:10, marginBottom:10}}>
                  <Link 
                         to={{ 
                           pathname: `/schools`,
                          
                         }}
                         
                         >
                  <button className="uk-button uk-button-default uk-text-capitalize " style={{ fontSize:10, backgroundColor:'#73d56e', color:'white', borderRadius:20, marginRight:5}}>Add Another Course</button>
                  </Link>
                  <Link to='/checkout'> 
                 < button className="uk-button uk-button-default uk-text-capitalize" style={{ fontSize:10, backgroundColor:'#73d56e', color:'white', borderRadius:20, marginLeft:5}}>Check out</button>
                 </Link>
                  </div>




                   
                  
                      
              
                  
                  



                 </div>
                 </div>
              </div>
               </section>
               : 
               // if showItem is false say no item in cart
               <div className='uk-container uk-margin-large-bottom uk-margin-large-top'>
                  <h3 className='uk-text-bold uk-text-center'>Your Cart is Empty</h3>
               </div>
             }

         



            </Layout>
    )
}