// import {
//     PrimaryButton, AutoCompleteInputBox, TextInputBox
// } from "./../../../features/Atoms"
// import {
//     Backdrop,
//     Grid, Paper, CircularProgress
// } from "@material-ui/core";
// import { Field, Formik } from "formik";
// import { connect } from 'react-redux'
// import Data from './../../../Data'
// import { saveCourse } from "./../../../DataStore/CourseReducer"
// import { Alert } from "@material-ui/lab";
// import { Link } from "react-router-dom"

// import MediaUpload from './../../../features/MediaUpload/media';
// import { useState } from "react";

// const initialData = {
//     title: "",
//     description: "",
//     subTitle: "",
//     subDescription: "",
//     grade: "",
//     skillLevel: "",
//     duration: "",
//     courseType: "",
//     courseCategory: "",
//     tools: "",
//     price: "",
//     mrpPrice: "",
//     path: "",
//     requirements: [],
//     prerequisite: "",
//     highlightPoints: [],
//     thumbnil: "",
//     start_date: "",
//     end_date: "",
//     location: "",
//     time_date: [],
//     location_type: "",
//     status: "",
//     days: ""

// }


// function CourseForm(props) {
//     const [courseImage, setCourseImage] = useState(null);
//     const [thumbnil, setThumbnil] = useState(null);
//     const [uploadStatus, setUploadStatus] = useState(false);
//     const [location_type, setLocation_type] = useState("")


//     const Uploadt = (event) => {
//         //setUploadedfile(event.target.files[0].name);
//         const formData = new FormData();
//         formData.append('file', event.target.files[0]);
//         formData.append("upload_preset", "cybertack")
//         const options = {
//           method: 'POST',
//           body: formData,
//         };
//         setUploadStatus(true);
//         fetch('https://api.cloudinary.com/v1_1/uhembe/image/upload', options)
//           .then(data => data.json())
//           .then(res => {
//             console.log(res);
//             setUploadStatus(false);
//             if (res.original_filename) {
//              // setUploadedfile(res.original_filename);
//              // console.log("data: ", res?.data?.file_name);
//              // props.imageUploadCallback(res.secure_url);
//               setThumbnil(res.secure_url)
              
//             } else {
//               //setUploadedfile(false)
//             }
//           })
//       }

//     const validateForm = (values) => {
//         const errors = {};
//         if (!values.title) errors.title = "Required";
//         if (!values.description) errors.description = "Required";
//         if (!values.subTitle) errors.subTitle = "Required";
//         if (!values.subDescription) errors.subDescription = "Required";
//         if (!values.grade) errors.grade = "Required";
//         if (!values.skillLevel) errors.skillLevel = "Required";
//         if (!values.duration) errors.duration = "Required";
//         if (!values.courseType) errors.courseType = "Required";
//         if (!values.courseCategory) errors.courseCategory = "Required";
//        // if (!values.price) errors.price = "Required";
//        // if (!values.mrpPrice) errors.mrpPrice = "Required";
//         // if (!values.thumbnil) errors.thumbnil = "Required";
//         if (!courseImage && !values.coverImage) errors.coverImage = "Cover Image is Required";
//         if (!thumbnil && !values.thumbnil) errors.thumbnil = "thumbnil Image is Required";
//         return errors;
//     }


//     const doSubmit = (values, action) => {
//         props.doSave({
//             ...values,
//             coverImage: courseImage || values.coverImage,
//             thumbnil: thumbnil || values.thumbnil,
//         });
//         action.setSubmitting(false);
//     }
//     return (
//         <Formik
//             enableReinitialize
//             initialValues={{
//                 ...initialData, ...(props.courseInfo || {}),
//             }}
//             validate={validateForm}
//             onSubmit={doSubmit}
//         >
//             {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
//                 <form onSubmit={handleSubmit} >
//                     {
//                         props.saveCourse.status === "FULFILLED"
//                             ? <Alert
//                                 severity="success"
//                                 style={{ display: "flex", adding: "3px 9px" }}
//                             >
//                                 Your Course is saved successfully.
//                                 <Link to={`/courses/${props.saveCourse.data.courseId}/new`}>View Course</Link>
//                             </Alert>
//                             : <Grid container spacing={4} component={Paper} variant="outlined"
//                                 style={{ padding: '2%' }} direction="row-reverse">
//                                 <Field type="hidden" name="id" />
//                                 <Grid item xs={12} md={4}>
//                                 {/* <AutoCompleteInputBox
//                                         options={Object.keys(Data.Config.Location_type)}
//                                         getOptionLabel={(option) => Data.Config.get(Data.Config.Location_type, option, 'displayName', setLocation_type(option))}
//                                         setFieldValue={setFieldValue}
//                                         label="Course Location Type"
//                                         name="location_type" 
//                                         required
//                                        // onChange={ setLocation_type("offline")}
                                        
//                                         /> */}
                                      
//                                     {/* {
                                     
//                                     location_type === "OFFLINE" ?  (<TextInputBox name="location" label="Location" />) : (null)
                                    
//                                     } */}

//                                      <AutoCompleteInputBox multiple
//                                         options={Object.keys(Data.Config.Days)}
//                                         getOptionLabel={(option) => Data.Config.get(Data.Config.Days, option, 'displayName')}
//                                         setFieldValue={setFieldValue}
//                                         label="Days"
//                                         name="days" />
//                                         <TextInputBox type="date" name="start_date" label="Start Date" />
//                                         <TextInputBox type="date" name="end_date" label="End dats" />
//                                         <TextInputBox type="time" name="time_date" label="Time" />

                                        
                                  
                                        
                                       
                                       
//                                     <AutoCompleteInputBox

//                                         options={Object.keys(Data.Config.CourseType)}
//                                         getOptionLabel={(option) => Data.Config.get(Data.Config.CourseType, option, 'displayName')}
//                                         setFieldValue={setFieldValue}
//                                         required
//                                         label="Course Type"
//                                         name="courseType" />
//                                     <AutoCompleteInputBox
//                                         options={Object.keys(Data.Config.Grade)}
//                                         getOptionLabel={(option) => Data.Config.get(Data.Config.Grade, option, 'displayName')}
//                                         setFieldValue={setFieldValue}
//                                         required
//                                         label="Grade"
//                                         name="grade" />
//                                     <AutoCompleteInputBox
//                                         options={Object.keys(Data.Config.SkillLevel)}
//                                         getOptionLabel={(option) => Data.Config.get(Data.Config.SkillLevel, option, 'displayName')}
//                                         setFieldValue={setFieldValue}
//                                         required
//                                         label="Skill Level"
//                                         name="skillLevel" />
//                                     <AutoCompleteInputBox
//                                         options={Object.keys(Data.Config.CourseCategory)}
//                                         getOptionLabel={(option) => Data.Config.get(Data.Config.CourseCategory, option, 'displayName')}
//                                         setFieldValue={setFieldValue}
//                                         required
//                                         label="Course Category"
//                                         name="courseCategory" />
//                                     <TextInputBox type="number" name="duration" label="Duration" required />
//                                     <TextInputBox name="tools" label="Tools" />
//                                     <TextInputBox type="number" name="price" label="Offered Price"  />
//                                     <TextInputBox type="number" name="mrpPrice" label="Mrp Price"  />
//                                     <TextInputBox name="path" label="Path" />
//                                     <TextInputBox name="prerequisite" label="Prerequisite" />
//                                     <MediaUpload imageUploadCallback={img => setCourseImage(img)}
//                                         label="Cover Image"
//                                         coverImage={courseImage || props.courseInfo.coverImage} />
//                                        <h5>Upload course thumbnil</h5>
//                                        <input
//                                         accept="image/*"
//                                         id="thumbnil"
//                                         name="thumbnil"
//                                         type="file"
                                       
//                                         onChange={Uploadt}
//                                           />
                                      


//                                 </Grid>
//                                 <Grid item xs={12} md={8}>
//                                     <TextInputBox name="title" label="Title" required />
//                                     <TextInputBox name="description" label="Description" multiline rows={4} required />
//                                     <TextInputBox name="subTitle" label="Sub Title" required />
//                                     <TextInputBox name="subDescription" label="Sub Description" multiline
//                                         rows={4} required />
//                                     <AutoCompleteInputBox multiple
//                                         options={Object.keys(Data.Config.Requirements)}
//                                         getOptionLabel={(option) => Data.Config.get(Data.Config.Requirements, option, 'displayName')}
//                                         setFieldValue={setFieldValue}
//                                         label="Requirements"
//                                         name="requirements" />


//                                     <AutoCompleteInputBox multiple
//                                         options={[]}
//                                         getOptionLabel={(option) => option}
//                                         setFieldValue={setFieldValue}
//                                         label="Highlight Points"
//                                         multiple
//                                         freeSolo
//                                         name="highlightPoints" />
//                                     <PrimaryButton style={{ width: '100%' }} type="submit">Save Course</PrimaryButton>
//                                     {
//                                         (props.saveCourse.status === "REJECTED"
//                                             || errors.coverImage)
//                                         &&
//                                         <Alert
//                                             severity="error"
//                                             style={{ display: "flex", adding: "3px 9px" }}
//                                         >
//                                             {errors.coverImage || props.saveCourse.error.message}
//                                         </Alert>
//                                     }
//                                     {
//                                         <Backdrop
//                                             style={{ zIndex: "1000" }}
//                                             open={props.saveCourse?.status === "PENDING"}
//                                         >
//                                             <CircularProgress color="inherit" />
//                                         </Backdrop>
//                                     }

//                                 </Grid>
//                             </Grid>
//                     }

//                 </form>)}
//         </Formik>
//     );
// }
// const mapStateToProps = state => {
//     return {
//         saveCourse: state.course && state.course.saveCourse,
//     };
// };
// const mapDispatchToProps = dispatch => {
//     return {
//         doSave: (payload) => dispatch(saveCourse(payload)),
//     };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(CourseForm);








import {
    PrimaryButton, AutoCompleteInputBox, TextInputBox
} from "./../../../features/Atoms"
import {
    Backdrop,
    Grid, Paper, CircularProgress
} from "@material-ui/core";
import { Field, Formik } from "formik";
import { connect } from 'react-redux'
import Data from './../../../Data'
import { saveCourse } from "./../../../DataStore/CourseReducer"
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom"

import MediaUpload from './../../../features/MediaUpload/media';
import { useEffect, useState } from "react";

// const initialData = {
//     title: "",
//     description: "",
//     subTitle: "",
//     subDescription: "",
//     grade: "",
//     skillLevel: "",
//     duration: "",
//     courseType: "",
//     courseCategory: "",
//     tools: "",
//     price: "",
//     mrpPrice: "",
//     path: "",
//     requirements: [],
//     prerequisite: "",
//     highlightPoints: [],
//     thumbnil:""

// }






function CourseForm(props) {
    // const [courseImage, setCourseImage] = useState(null);
    // const [thumbnil, setThumbnil] = useState(null);
    // const [uploadStatus, setUploadStatus] = useState(false);

    const [courseImage, setCourseImage] = useState(null);
    const [thumbnil, setThumbnil] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(false);
    const [location_type, setLocation_type] = useState("")
    const [location, setLocation] = useState("")
    const [title, setTitle] = useState("")
    const [highlightPoints, setHighlightPoints] = useState()
    const [course_name, setCourse_name] = useState([
        {
        id: '3D Animation with Minecraft 1-3',
        displayName: '3D Animation with Minecraft (Grade 1-3)',
        highlight:'Develop their 3D motion and observation skills, identify scene composition elements,Develop their 3D motion and observation skills, identify scene composition elements, Have fun and keep their animation project to share with other Minecraft fans'
        
      },
      {
      id: '3D Animation with Minecraft 4-7',
      displayName: '3D Animation with Minecraft (Grade 4-7)',
      highlight:'Improve 3D animation design skills and understanding of scene composition, Gain confidence by translating motion to reality through animation with their own characters and moving objects, Have fun and get their own animated project to share online with other Minecraft fans'
      
    },
    {
        id: '3D Character Modeling & Sculpting 1-3',
        displayName: '3D Character Modeling & Sculpting (Grade 1-3)',
        highlight:'Develop observation skills and learn how to interpret and modify digital 3D space, Create their own characters and objects, build character sheets and master using a tablet, Get introduced to digital sculpting and painting and have fun designing their own projects'
        
        
      },

      {
        id: '3D Character Modeling & Sculpting 4-7',
        displayName: '3D Character Modeling & Sculpting (Grade 4-7)',
        highlight:'Improve digital sculpting and painting skills and 3D space manipulation, Learn visual anatomy notions and how to draft character sheets, Develop creativity and problem solving abilities by designing their own characters and moving objects'
        
        
      },

      {
        id: '3D Game Production & Design 1-3',
        displayName: '3D Game Production & Design (Grade 1-3)',
        highlight:'Learn about basic game theory and planning a level while building problem solving skills, Let imagination run wild and understand concepts in video art design & composition, Get an intro to game design elements and challenges inside the video game universe'
        
        
      },

      {
        id: '3D Game Production & Design 4-7',
        displayName: '3D Game Production & Design (Grade 4-7)',
        highlight:'Design items and game obstacles and puzzles to develop their strategic thinking abilities, Learn about video art design & composition concepts to boost creativity and design skills,Understand  principles of video game design, game theory and implementing AI and enemies'
        
        
      },

      {
        id: 'Adventure Game Coding with Scratch 1-3',
        displayName: 'Adventure Game Coding with Scratch (Grade 1-3)',
        highlight:'Develop keyboard confidence, problem solving abilities and coding logic, Be introduced to programming concepts, topics like variables and program loops, Observe coding efficiency as they design their own games in popular Super Mario style '
        
        
      },

      {
        id: 'Adventure Game Coding with Scratch 4-7',
        displayName: 'Adventure Game Coding with Scratch (Grade 4-7)',
        highlight:'Improve coding logic and problem solving, increase coding efficiency and understanding of principles, Apply programming concepts and learn all about physics based movement, hit detection, animation, Create their own platform adventure game complete with animations, physics simulations, UI'
        
        
      },
      {
        id: 'Arcade Game Coding with Scratch 1-3',
        displayName: 'Arcade Game Coding with Scratch (Grade 1-3)',
        highlight:'Get an intro to programming concepts and develop problem solving abilities and keyboard confidence, Cover topics like variables and program loops to gain coding efficiency, Design their own games in the style of classic arcade games to share with other fans '

      },
      {
        id: 'Arcade Game Coding with Scratch 4-7',
        displayName: 'Arcade Game Coding with Scratch (Grade 4-7)',
        highlight:'Improve problem solving abilities, increase coding efficiency and boost tech skills, Understand and apply programming concepts, test variables and program loops, Design their own games and get to share it with other arcade game fans'

      },
      {
        id: 'Game Design and C# Coding in Unity 4-7',
        displayName: 'Game Design and C# Coding in Unity (Grade 4-7)',
        highlight:'Design a functional game, by using Unity, a real game engine and design in-game objects in C# (C sharp), Increase coding efficiency, strategic thinking, problem solving and gain confidence from having developed their project from A to Z, Apply programming concepts and logic, test variables, program loops and game design principles'
        

      },
      {
        id: 'Minecraft Modding 1-3',
        displayName: 'Minecraft Modding (Grade 1-3)',
        highlight:'Start building tech skills, improve problem solving and coding efficiency ,Use Camp time to create from scratch their own project and gain keyboard confidence ,Have a lot of fun and get a design they can take home and share with other friends'
        

      },
      {
        id: 'Minecraft Modding 4-7',
        displayName: 'Minecraft Modding (Grade 4-7)',
        highlight:'Advance their tech skills, increase coding efficiency and knowledge, Apply critical thinking to create from scratch their own project or design, Have a fun Camp time and go home with a design they can share with other friends '
        

      },

      {
        id: 'Roblox Adventure Game Creation 1-3',
        displayName: 'Roblox Adventure Game Creation (Grade 1-3)',
        highlight:'Learn about topics like game design, variables and program loops to understand game creation, Gain coding efficiency and keyboard confidence, and develop problem solving abilities ,Get an intro to programming concepts and design elements and challenges inside Roblox game universe'
        

      },
      {
        id: 'Roblox Adventure Game Creation 4-7',
        displayName: 'Roblox Adventure Game Creation (Grade 4-7)',
        highlight:'Design items and game elements to develop their problem solving abilities, Increase coding efficiency and keyboard confidence while bringing to life a unique version of games in Roblox, Apply learned programming concepts, test variables, program loops and game design principles '
        

      },
      {
        id: 'Roblox Obby Creation 1-3',
        displayName: 'Roblox Obby Creation (Grade 1- 3)',
        highlight:'Challenge and work together with friends in building obbies in Roblox by coding in LUA, Familiarize himself with programming concepts and coding principles for game design ,Bring their own idea for an obstacle to life with newly learned coding skills which strengthens also keyboard confidence'
        

      },
      {
        id: 'Roblox Obby Creation 4-7',
        displayName: 'Roblox Obby Creation (Grade 4- 7)',
        highlight:'Apply programming fundamentals, test variables and program loops to create their own obbies in Roblox ,Improve coding efficiency  and keyboard confidence, build problem solving abilities ,Get to share and challenge other friends with obstacles and power-ups programmed with LUA during camp time'
        

      },

      {
        id: 'Roblox Imaginative Game Design 1-3',
        displayName: 'Roblox Imaginative Game Design (Grade 1-3)',
        highlight:`In this course, students will learn how to use coding
        fundamentals to strategically engineer an immersive,
        platforms in the world. “Roblox, Imaginative Game Design”
        provides students with hands-on experiences in innovation
        and the underlying mechanics of 3D gaming. This is the
        perfect course for any student looking to advance their skills in
        science, technology, engineering, art, and mathematics
        (STEAM). Do not miss your chance to publish, share, and play
        games with your friends.`
        

      },


      {
        id: 'Roblox Imaginative Game Design 4-7',
        displayName: 'Roblox Imaginative Game Design (Grade 4-7)',
        highlight:`In this course, students will advance their coding, design, and
        game development skills, tinkering with Roblox
        Studio and the Lua programming language it employs.
        “Roblox, Imaginative Game Design” provides students
        between the ages of 10 -14 with fundamental coding concepts
        and techniques that will allow them to eventually move on to
        AP Computer Science. This course is perfect for any student
        looking for an opportunity to advance their careers as
        computer engineers, game developers, and tech-savvy
        entrepreneurs. Do not miss your chance to code alongside a
        group of aspiring computer scientists.`
        

      },
    ])

    const initialData = {
        title: "",
        description: "",
        subTitle: "",
        subDescription: "",
        grade: "",
        skillLevel: "",
        duration: "",
        courseType: "",
        courseCategory: "",
        tools: "",
        price: "",
        mrpPrice: "",
        path: "",
        requirements: [],
        prerequisite: "",
        highlightPoints: highlightPoints,
        thumbnil: "",
        start_date: "",
        end_date: "",
        location: "",
        coursetime: "",
        endtime:"",
        location_type: "",
        status: "",
        days: []
    
    }

   
  


   // const newfilter = course_name.filter((item)=> item.id == title).map(item => item.highlight)
    // console.log("this is working", newfilter)
    // if(highlightPoints !== ""){
    //     setHighlightPoints(newfilter)
    // }
  console.log("this is my title", title)
    useEffect(()=>{
       if(title === ""){
           console.log("no title")
           return null
       }else{
           console.log("there is title")
           const newfilter = course_name.filter((item)=> item.id === title).map(item => item.highlight)
           setHighlightPoints(newfilter)
           console.log("this is high",highlightPoints)
           console.log("this is data",initialData)

       }
        
    },[title])
    
       

        //setHighlightPoints(newfilter)
        //console.log("this is newfilter", newfilter)

    


    
     
    //   const condata = Data.Config.Course_name.id
    //   const newfilter = condata.filter((item)=> item.id == title)
    //   console.log("this is it", newfilter)
    
    // const newdata =   condata.filter(item => item.id == title)
    // console.log("this is it",newdata)
    
   


    
   


    const Uploadt = (event) => {
        //setUploadedfile(event.target.files[0].name);
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append("upload_preset", "cybertack")
        const options = {
          method: 'POST',
          body: formData,
        };
        setUploadStatus(true);
        fetch('https://api.cloudinary.com/v1_1/uhembe/image/upload', options)
          .then(data => data.json())
          .then(res => {
            console.log(res);
            setUploadStatus(false);
            if (res.original_filename) {
             // setUploadedfile(res.original_filename);
             // console.log("data: ", res?.data?.file_name);
             // props.imageUploadCallback(res.secure_url);
              setThumbnil(res.secure_url)
              
            } else {
              //setUploadedfile(false)
            }
          })
      }

    const validateForm = (values) => {
        const errors = {};
        if (!values.title) errors.title = "Required";
       // if (!values.description) errors.description = "Required";
        // if (!values.subTitle) errors.subTitle = "Required";
        // if (!values.subDescription) errors.subDescription = "Required";
        if (!values.grade) errors.grade = "Required";
        if (!values.skillLevel) errors.skillLevel = "Required";
        if (!values.duration) errors.duration = "Required";
        if (!values.courseType) errors.courseType = "Required";
        if (!values.courseCategory) errors.courseCategory = "Required";
       // if (!values.price) errors.price = "Required";
       // if (!values.mrpPrice) errors.mrpPrice = "Required";
        // if (!values.thumbnil) errors.thumbnil = "Required";
        if (!courseImage && !values.coverImage) errors.coverImage = "Cover Image is Required";
        if (!thumbnil && !values.thumbnil) errors.thumbnil = "thumbnil Image is Required";
        return errors;
    }


    const doSubmit = (values, action) => {
        props.doSave({
            ...values,
            coverImage: courseImage || values.coverImage,
            thumbnil: thumbnil || values.thumbnil,
        });
        action.setSubmitting(false);
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...initialData, ...(props.courseInfo || {}),
            }}
            validate={validateForm}
            onSubmit={doSubmit}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
                <form onSubmit={handleSubmit} >
                    {
                        props.saveCourse.status === "FULFILLED"
                            ? <Alert
                                severity="success"
                                style={{ display: "flex", adding: "3px 9px" }}
                            >
                                Your Course is saved successfully.
                                <Link to={`/courses/${props.saveCourse.id}/${props.saveCourse.title}`}>View Course</Link>
                            </Alert>
                            : <Grid container spacing={4} component={Paper} variant="outlined"
                                style={{ padding: '2%' }} direction="row-reverse">
                                <Field type="hidden" name="id" />
                                <Grid item xs={12} md={4}>
                                <AutoCompleteInputBox
                                        options={Object.keys(Data.Config.Location_type)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Location_type, option, 'displayName', setLocation_type(option))}
                                        setFieldValue={setFieldValue}
                                        label="Course Experince"
                                        name="location_type" 
                                        required/>

                                    {
                                     location_type === "OFFLINE" ?  (
                                     
                                     //<TextInputBox name="location" label="Location" />
                                    <AutoCompleteInputBox
                                        options={Object.keys(Data.Config.Schools)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Schools, option, 'displayName', setLocation(option))}
                                        setFieldValue={setFieldValue}
                                        label="Location"
                                        name="location" 
                                        />
                                     
                                     ) : 
                                     (null)
                                    }

                                    {/* <AutoCompleteInputBox multiple
                                        options={Object.keys(Data.Config.Days)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Days, option, 'displayName')}
                                        setFieldValue={setFieldValue}
                                        label="Days"
                                        name="days" 
                                        /> */}
                                        <AutoCompleteInputBox multiple
                                        options={Object.keys(Data.Config.Days)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Days, option, 'displayName')}
                                        setFieldValue={setFieldValue}
                                        label="Days"
                                        name="days" />


                                        <TextInputBox type="date" name="start_date" label="Start Date" />
                                        <TextInputBox type="date" name="end_date" label="End Date" />
                                        <TextInputBox type="time" name="coursetime" label="Start time" />
                                        <TextInputBox type="time" name="endtime" label="End time" />
                                    <AutoCompleteInputBox
                                        options={Object.keys(Data.Config.CourseType)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.CourseType, option, 'displayName')}
                                        setFieldValue={setFieldValue}
                                        required
                                        label="Course Type"
                                        name="courseType" />
                                    <AutoCompleteInputBox
                                        options={Object.keys(Data.Config.Grade)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Grade, option, 'displayName')}
                                        setFieldValue={setFieldValue}
                                        required
                                        label="Grade"
                                        name="grade" />
                                    <AutoCompleteInputBox
                                        options={Object.keys(Data.Config.SkillLevel)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.SkillLevel, option, 'displayName')}
                                        setFieldValue={setFieldValue}
                                        required
                                        label="Skill Level"
                                        name="skillLevel" />
                                    <AutoCompleteInputBox
                                        options={Object.keys(Data.Config.CourseCategory)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.CourseCategory, option, 'displayName',setLocation(option))}
                                        setFieldValue={setFieldValue}
                                        required
                                        label="Course Category"
                                        name="courseCategory" />
                                    <TextInputBox type="time" name="duration" label="Duration in hours" required />
                                    <TextInputBox name="tools" label="Tools" />
                                    <TextInputBox type="number" name="price" label="Offered Price"  />
                                    <TextInputBox type="number" name="mrpPrice" label="Mrp Price"  />
                                    <TextInputBox name="path" label="Path" />
                                    <TextInputBox name="prerequisite" label="Prerequisite" />
                                    <MediaUpload imageUploadCallback={img => setCourseImage(img)}
                                        label="Cover Image"
                                        coverImage={courseImage || props.courseInfo.coverImage} />


                                       <h5>Upload course thumbnil</h5>
                                       <input
                                        accept="image/*"
                                        id="thumbnil"
                                        name="thumbnil"
                                        type="file"
                                       
                                        onChange={Uploadt}
                                          />
                                      


                                </Grid>
                                <Grid item xs={12} md={8}>

                                {/* <AutoCompleteInputBox
                                    
                                        options={Object.keys({course_name})}
                                        getOptionLabel={(option) => (course_name.id, option, 'displayName', setTitle(option) )}
                                        setFieldValue={setFieldValue}
                                        label="Course name"
                                        name="title" 
                                        required
                                        />  */}
                                 {/* <TextInputBox name="title" label="Course name" required /> */}

                                    <AutoCompleteInputBox
                                        options={Object.keys(Data.Config.Course_name)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Course_name, option, 'displayName',setTitle(option.course_name))}
                                        setFieldValue={setFieldValue}
                                        required
                                        label="Course Title"
                                        name="title" />

                                    {/* <TextInputBox name="description" label="Description" multiline rows={4} required /> */}
                                    {/* <TextInputBox name="subTitle" label="Sub Title" required />
                                    <TextInputBox name="subDescription" label="Sub Description" multiline
                                        rows={4} required /> */}
                                    <AutoCompleteInputBox multiple
                                        options={Object.keys(Data.Config.Requirements)}
                                        getOptionLabel={(option) => Data.Config.get(Data.Config.Requirements, option, 'displayName')}
                                        setFieldValue={setFieldValue}
                                        label="Requirements"
                                        name="requirements" />
                                       
                                    {/* <AutoCompleteInputBox multiple
                                        options={[]}
                                        getOptionLabel={(option) => option}
                                        setFieldValue={setFieldValue}
                                        label="Highlight Points"
                                        multiple
                                        freeSolo
                                        name="highlightPoints" /> */}
                                    <PrimaryButton style={{ width: '100%' }} type="submit">Save Course</PrimaryButton>
                                    {
                                        (props.saveCourse.status === "REJECTED"
                                            || errors.coverImage)
                                        &&
                                        <Alert
                                            severity="error"
                                            style={{ display: "flex", adding: "3px 9px" }}
                                        >
                                            {errors.coverImage || props.saveCourse.error.message}
                                        </Alert>
                                    }
                                    {
                                        <Backdrop
                                            style={{ zIndex: "1000" }}
                                            open={props.saveCourse?.status === "PENDING"}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }

                                </Grid>
                            </Grid>
                    }

                </form>)}
        </Formik>
    );
}
const mapStateToProps = state => {
    return {
        saveCourse: state.course && state.course.saveCourse,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        doSave: (payload) => dispatch(saveCourse(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseForm);