import React, { useEffect, useState } from "react";
import {
  Box,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  TableRow,
  Table,
  TableBody,
  Paper,
  TableContainer,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { PrimaryButton, PrimaryButtonOutlined, SecondaryText } from "../Atoms";
import { Link } from "react-router-dom";
import {
  loadAllSchool,
  loadAllEnrolledCourses,
} from "./../../DataStore/SchoolReducer";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import Data from "../../Data";
import { useAuthorize, useUserInfo } from "../Authentication/Authorize";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Api from "../../Api";
import {updateSchoolCourse} from "./../../DataStore/SchoolCoursesReducer"

export default function SchoolTableGrid(props) {
  const isReresentative = useAuthorize("REPRESENTATIVE");
  const userInfo = useUserInfo();
  const schoolListStatus = useSelector(
    (state) => state.school.loadAllSchool
  );
  const [allschools, setAllschools] = useState([])
  console.log("schoolListStatus: ", schoolListStatus);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  

  useEffect(() => {

    Api.School.GetAllSchools().then((res)=>{
      console.log("this is working", res)
      setAllschools(res.data)

    })
      
    if (!mounted && userInfo) {
      dispatch(loadAllSchool);
      setMounted(true);
    }
  }, [userInfo, allschools]);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>School Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            // schoolListStatus.status === "FULFILLED" &&
            //   schoolListStatus.data &&
              allschools.map((schoolItem) => (
                <Row schoolInfo={schoolItem}  isReresentative={'REPRESENTATIVE'}/>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Row(props) {
const dispatch = useDispatch();
  const changeCourseStatus = (props) => {
      if(props.isActive){
        props.isActive = 0
      }else{
        props.isActive = 1
      }
      console.log("props", props)
      dispatch(updateSchoolCourse(props))
  
  }



  const { schoolInfo,} = props;
  const [open, setOpen] = React.useState(false);
  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
  });
  const classes = useRowStyles();
  const [participantModalIsOpen, setParticipantModalIsOpen] = useState(false);
  const [participantList, setParticipantList] = useState([]);

  const ParticipantModal = (props) => {

    const exportPdf =()=>{
      const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = "Participant List";
    const headers = [["Parents name", "Phone number", "Students First name ", "Students last ", "Grade", "Students Age", "Address"]];
    const data = participantList.map(elt=> [elt.lastName, elt.contactNo, elt.firstName, elt.lastName, elt.grade, elt.studentAge, elt.addressLine1  ]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`Participant List.pdf`)

    }


  

    return (
      
      <Dialog
        maxWidth="xl"
        open={participantModalIsOpen}
        onClose={() => setParticipantModalIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Participant List 
         
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                <TableCell>Parents Name</TableCell>
                                                <TableCell>Parent Email</TableCell>
                                                <TableCell>Phone Number</TableCell>
                                                <TableCell>Student Name</TableCell>
                                                <TableCell>Grade</TableCell>
                                                <TableCell>Student Age</TableCell>
                                                <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participantList.map((participant, index) => (
                  <TableRow>
                    <TableCell>
                      {`${participant.lastName}`}
                    </TableCell>
                    <TableCell>{participant.parentEmail}</TableCell>
                    <TableCell>{participant.contactNo}</TableCell>
                    <TableCell>{participant.firstName} {participant.lastName}</TableCell>
                    <TableCell>{participant.grade}</TableCell>
                    <TableCell>{participant.studentAge}</TableCell>
                    <TableCell>{participant.addressLine1}</TableCell>
                  </TableRow>
                ))}
                
              </TableBody>
            </Table>
            
          </TableContainer>
          <Link   component={PrimaryButtonOutlined} onClick={exportPdf}>Export as Pdf</Link>
        </DialogContent>
      </DialogTitle>
      </Dialog>
    );
  };
  const showParticipant = (list) => {
    setParticipantList(list);
   
    setParticipantModalIsOpen(true);
  };

  const Deleteschool = (id)=> {
    
  }
  const isReresentative = useAuthorize("REPRESENTATIVE");
  const [allschools, setAllschools] = useState([])
  
  return (
    
    <React.Fragment>
      
      {participantModalIsOpen && ParticipantModal()}
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* MAIN ROW */}
        <TableCell component="th" scope="row">
          {schoolInfo.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {schoolInfo.address}
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          <Link
             component={PrimaryButtonOutlined}
             to={`/schools/${schoolInfo.schoolId}/${schoolInfo.name.replaceAll(
               " ",
               "-"
             )}`}
          >
            View
          </Link>
       
           {
           isReresentative ? (
               <Link
              component={PrimaryButtonOutlined}
              to={{
                pathname: `schools/${schoolInfo.schoolId}/${schoolInfo.name}/edit`,
              }}>
              {" "}
              Edit
            </Link> 
             ) : ( null)
           }

            {
              isReresentative ? (<Link
                component={PrimaryButtonOutlined}
               onClick={()=>{
                 const data ={
                   schoolId: schoolInfo.schoolId
                 }
               
                 axios.post('https://cybat.herokuapp.com/course-api/schools/delete', data).then((res)=>{
                 console.log(res.data.data)
                 window.location.reload()
     setAllschools(res.data.data)
  })
                 
               }}
             >
               Delete
             </Link>) : (null)
            }

            {/* <botton
             component={PrimaryButtonOutlined}
            >
              Delete
            </botton> */}

            {/* <Link
            
              component={PrimaryButtonOutlined}
              to={`/schools/${schoolInfo.schoolId}/${schoolInfo.name.replaceAll(
                " ",
                "-"
              )}/edit`}
            >
              {" "}
              Delete
            </Link> */}
          
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Course Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Total Slot</TableCell>
                    <TableCell align="right">Participants</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolInfo.courses.map((course) => (
                    <TableRow key={course.id}>
                      <TableCell component="th" scope="row">
                        {course.title}
                      </TableCell>
                      <TableCell>{course.price}</TableCell>
                      <TableCell>{
                      course.isActive ? (<span style={{color: 'green'}}>Active</span>) : (<span style={{color: 'red'}}>Inactive</span>) 
                      }</TableCell>
                      <TableCell>{}</TableCell>

                     


                      <TableCell align="right">

                      

                        {
                          isReresentative ? (<Link component={PrimaryButtonOutlined}
                            onClick={() => showParticipant(course.participants)}
                          >
                            View participants
                          </Link>) : (null)
                        }

                         {
                          isReresentative ? (<Link component={PrimaryButtonOutlined}
                            onClick={() => changeCourseStatus(course)}
                          >
                            {
                              course.isActive ? (<span style={{color: 'green'}}>Deactivate</span>) : (<span style={{color: 'green'}}>Activate</span>)
                            }
                        
                          </Link>) : (null)
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
