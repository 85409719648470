import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Dialog, Tabs, Tab, Paper, Backdrop } from "@material-ui/core";
import { PrimaryButton } from "./../Atoms/index";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import { a11yProps, TabPanel, TextInputBox, PasswordInputBox } from "./blocks";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./authentication.css";

import Api from "../../Api";

function AuthenticationModal(props) {
  const { loginStatus } = props;
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [showEmailTextBox, setShowEmailTextBox] = useState(false);

  useEffect(() => {
    if (!loading && loginStatus.status === "PENDING") {
      setLoading(true);
    }
  }, [loginStatus]);
  const Checkemail = () => {
    alert(email);
  };

  const passwordreset = () => {
    setShowEmailTextBox(true);
  };

  // const ForgotPassword =(props)=>{

  //   return(
  //     <div style={{ textAlign:'center'}} className="formForgotLink" to="/forgotpassword">
  //           <div onClick={props.passwordreset}>
  //             forgot password? jfjddfjshj
  //           </div>

  //     </div>
  //   )
  // }

  const [showRestCode, setShowResetCode] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showNewpassword, setShowNewpassword] = useState(false);
  const [msg, setMsg] = useState();
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");

  const Sendcode = () => {
    const data = {
      email: email,
    };
    Api.Auth.SendCode(data).then(function (res) {
      console.log(res);
      if (res.meta.statusCode === "200") {
        setMsg(res.meta.message);
        setShowResetCode(true);
      } else {
        setMsg("User with emaill address does not exist");
      }
    });
  };

  const VerifyCode = () =>{
    const data = {
      email:email,
      resetCode:resetCode
    }
    Api.Auth.verifyCode(data).then(function (res) {
     if(res.meta.message === "reset code is correct"){
      setShowNewpassword(true);
     }else{
      setMsg(res.meta.message)
     }
  })
}

const ResetPassword = () =>{
  const data = {
    email:email,
    resetCode:resetCode,
    password:password
  }
  Api.Auth.resetPassword(data).then(function (res) {
   if(res.meta.message === "reset was successful"){
    //setMsg('Login with your new password')
    // setShowNewpassword(false);
    setShowEmail(false);
    // setShowEmail(false);
   }else{
    setMsg(res.meta.message)
   }
})
}



  return (
    <>
      <Backdrop open={loginStatus.status === "PENDING"}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullWidth
        open={props.show && loginStatus.status !== "PENDING"}
        className="authDialog App"
        onClose={(event, reason) => {
          props.onClose(false);
        }}
      >
        <Paper style={{ width: "100%" }}>
          <Tabs
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: "white" } }}
            value={value}
            onChange={(e, newval) => {
              setValue(newval);
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              style={{
                padding: "15px",
                background: "#73d56e",
                color: "white",
              }}
              label="LOG IN"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                padding: "15px",
                background: "#73d56e",
                color: "white",
              }}
              label="REGISTER"
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            {showEmail === false ? (
              <>
                <AuthForm
                msg={msg}
                  type="LOGIN"
                  error={props.error}
                  initialValues={{ email: "", password: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.password) {
                      errors.password = "Required";
                    }
                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    return errors;
                  }}
                  onSubmit={props.loginSubmit}
                />
                {/* <div
                  style={{
                    textAlign: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  onClick={() => {
                    setShowEmail(true);
                  }}
                >
                  Forget password?
                </div>

                <SocialLogin
                  label={"Google Login"}
                  icon={""}
                  onClick={google}
                  loginType={google}
                />

                <SocialLogin
                  label={"Facebook Login"}
                  icon={""}
                  onClick={facebook}
                  loginType={facebook}
                /> */}
              </>
            ) : (
              <>
                {showRestCode === false ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      style={{ color: "red" }}
                      class="uk-form-label"
                      for="form-stacked-text"
                    >
                      {msg}
                    </label>
                    <label class="uk-form-label" for="form-stacked-text">
                      Email Address
                    </label>
                    <div>
                      <input
                        className=" uk-input"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                      <div className="uk-margin">
                        <button
                          onClick={() => {
                            Sendcode();
                          }}
                          class="uk-button uk-button-default uk-width-1-1"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {showNewpassword === false ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <label class="uk-form-label" for="form-stacked-text">
                            {msg}
                          </label>
                          <div>
                            <input
                              className=" uk-input"
                              placeholder="Enter 6 digit code"
                              value={resetCode}
                               onChange={(event) => setResetCode(event.target.value)}
                            />
                            <div className="uk-margin">
                              <button
                                onClick={() => {
                                 VerifyCode()
                                }}
                                class="uk-button uk-button-default uk-width-1-1"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <label class="uk-form-label" for="form-stacked-text">
                            New Password
                          </label>
                          <div>
                            <input
                              className=" uk-input"
                              placeholder="Enter your new password"
                              value={password}
                               onChange={(event) => setPassword(event.target.value)}
                            />
                            <div className="uk-margin">
                              <button
                                onClick={() => {
                                  ResetPassword()
                                }}
                                class="uk-button uk-button-default uk-width-1-1"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {/* <ForgotPassword
            passwordreset={passwordreset}
            
            /> */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AuthForm
              error={props.error}
              type="REGISTER"
              initialValues={{
                name: "",
                email: "",
                password: "",
                confirmPassword: "",
                userType: "STUDENT",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                if (!values.password) {
                  errors.password = "Required";
                }
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                if (values.password !== values.confirmPassword) {
                  errors.confirmPassword = "Password doesnt match";
                }
                return errors;
              }}
              onSubmit={props.loginSubmit}
            />
          </TabPanel>
        </Paper>
      </Dialog>
    </>
  );
}

const google = () => {
  window.open("http://localhost:27019/auth/google/", "_self_");
};

const facebook = () => {
  window.open("http://localhost:27019/auth/facebook/", "_self_");
};

const SocialLogin = ({ label, icon, loginType }) => {
  return (
    <div onClick={loginType} style={{ textAlign: "center" }}>
      {label}
    </div>
  );
};

const AuthForm = (props) => (
  <Formik
    enableReinitialize
    initialValues={{ formType: props.type, ...props.initialValues }}
    validate={props.validate}
    onSubmit={props.onSubmit}
    isValid={props.error && props.error.formType === props.type}
  >
    
    {({ handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit} style={{ width: "80%", margin: "auto" }}>
        {props.error && props.error.formType === props.type && (
          <Alert
            severity="error"
            style={{ display: "flex", adding: "3px 9px" }}
          >
            {props.error.message}
          </Alert>
        )}
        {props.type === "REGISTER" && (
          <TextInputBox
            disabled={isSubmitting}
            name="name"
            placeholder="Name"
            leftIcon={<PersonIcon className="inputBoxLeftIcon" />}
          />
        )}
        <TextInputBox
          disabled={isSubmitting}
          name="email"
          placeholder="Email"
          leftIcon={<EmailIcon className="inputBoxLeftIcon" />}
        />
        <Field type="hidden" name="formType" />
        <PasswordInputBox
          placeholder="Password"
          name="password"
          disabled={isSubmitting}
        />
        {props.type === "REGISTER" && (
          <PasswordInputBox
            placeholder="Confirm Password"
            name="confirmPassword"
          />
        )}
        <PrimaryButton
          type="submit"
          style={{ width: "100%" }}
          disabled={isSubmitting}
        >
          {props.type}
        </PrimaryButton>
      </form>
    )}
  </Formik>
);
export default AuthenticationModal;
