import {
    BodyText, PageBlock, PrimaryText, TickLabels,
    HeaderBox, HeaderTitle, PrimaryButton, HeaderDescription, SecondaryText, CourseBlock
} from "./../../../features/Atoms";
import react from 'react';
import { Link } from "react-router-dom";
import mathsTutoring from './../../../images/Maths_tutoring.jpg'

import mathsTutoringSmall from './../../../images/Maths_tutoringSmall.jpg'




const First_Pane = () => {

        return (

            <div>

                {/****DESKTOP VIEW***/}
            <div className="uk-visible@s"
            
            style={{ backgroundImage: `url(${mathsTutoring})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '100px'
        
            
        
              }}
            
            >
            
            <div className="uk-container" >
            <div className="uk-grid  " data-uk-grid>
                 <div className="uk-width-3-4@s">
                      <div className="uk-margin-xlarge-top " >
                          <h1 className="header1 light withPadding" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> Maths Tutoring.</h1>
                          <p className="subTitleText" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"></p>
                      </div>

                      <div class="uk-flex withPadding " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <Link component={PrimaryButton} to='/contactus'
                               className="marginGapBottom">Free 30 MINS LESSONS
                            </Link>
                        
                      </div>
  
                 </div>

                 <div className="uk-width-1-4@s">
                     
                 </div>
            </div>
            </div>
        </div>



           
                {/****MOBILE VIEW***/}
                <div className="uk-hidden@s"
            
            style={{ backgroundImage: `url(${mathsTutoringSmall})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '100px'
        
            
        
              }}
            
            >
            
            <div className="uk-container" >
            <div className="uk-grid  " data-uk-grid>
                 <div className="uk-width-3-4@s">
                      <div className="uk-margin-xlarge-top " >
                          <h1 className="header1 light withPadding" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> Maths Tutoring.</h1>
                          <p className="subTitleText" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"></p>
                      </div>

                      <div class="uk-flex withPadding " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <Link component={PrimaryButton} to='/contactus'
                               className="marginGapBottom">Free 30 MINS LESSONS
                            </Link>
                        
                      </div>
  
                 </div>

                 <div className="uk-width-1-4@s">
                     
                 </div>
            </div>
            </div>
        </div>







        </div>
        )

}


export default First_Pane;