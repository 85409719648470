import react from 'react';
import staff from './../../../images/staff.PNG';
import courseImg from './../../../images/course1.JPG';


import Api from './../../../Api'
import { Box, Grid, Link, Paper, Typography, Hidden, Container, Divider } from "@material-ui/core";
import {
    PrimaryButton, PrimaryText,
    CourseBlock,SecondaryText
} from "./../../../features/Atoms"

import useFetch from 'react-fetch-hook';
import Data from './../../../Data'






const Fourth_Pane = () => {
  
     const { isLoading, error, data } = useFetch(Api.School.loadAtSchoolCourses());

    if (isLoading) {
        return <h1 style={{ textAlign: 'center' }}>Loading</h1>
    }
    const courseList = data.data
    return (
        <div className="uk-container uk-margin-large-top ">
                     <div>
                         <div className='uk-grid' data-uk-grid>
                              <div className='uk-width-1-5@s'>

                              </div>

                              <div className='uk-width-3-5@s'>
                              <h3 className="header3  uk-text-center">Choose your Virtual Tech Camp Courses</h3>
                           <p className="subText uk-text-center uk-margin-top">Everything from game adventure design and animation courses
                            in Scratch or Minecraft or coding own obstacles with LUA. Top teachers deliver courses Virtual 
                            or In-person to fit everyone’s preferences.</p>
                             </div>

                             <div className='uk-width-1-5@s'>

                             </div>



                         </div>
                      
                          

                           <div className="uk-margin-large-top">
                           <Grid container direction="row"  spacing={2} style={{ marginTop: 20 }}>
                                                {
                                    courseList?.map(item => <Grid item xs={12} md={4} lg={4} style={{ textAlign: 'center' }}>
                                        <CourseBlock
                                            to={`/courses/${item.id}/${item.title.replaceAll(' ', '-')}`}
                                            label={item.title}
                                            // grade={Data.Config.get(Data.Config.Grade, item.grade, 'displayName')}
                                            courseType={Data.Config.get(Data.Config.CourseType, item.courseType, 'displayName')}
                                            price={item.price}
                                            mrpPrice={item.mrpPrice}
                                            coverPic={item.thumbnil}
                                        />
                                    </Grid>)
                                }
                            </Grid>






                           {/* <ul class=" uk-subnav uk-subnav-pill" uk-switcher="animation: uk-animation-fade">
                                                <li ><a href="#" className="activeTabBtn">All Courses</a></li>
                                            
                             </ul>

                                       
                                        <ul class="uk-switcher uk-margin">

                              
                                 <li>
                                            <Grid container direction="row"  spacing={2} style={{ marginTop: 20 }}>
                                                {
                                    courseList?.map(item => <Grid item xs={12} md={4} lg={4} style={{ textAlign: 'center' }}>
                                        <CourseBlock
                                            to={`/courses/${item.id}/${item.title.replaceAll(' ', '-')}`}
                                            label={item.title}
                                            grade={Data.Config.get(Data.Config.Grade, item.grade, 'displayName')}
                                            courseType={Data.Config.get(Data.Config.CourseType, item.courseType, 'displayName')}
                                            price={item.price}
                                            mrpPrice={item.mrpPrice}
                                            coverPic={item.thumbnil}
                                        />
                                    </Grid>)
                                }
                            </Grid>    
                                            </li>


                                        </ul> */}




                                        
                                        </div>

      




                     </div>
        </div>
    )
        

                            };

export default Fourth_Pane