import react from "react";
import inter from "./../../../images/inter.png"
import ass2 from "./../../../images/ass2.png"
import ass from "./../../../images/ass.png"
import {Link } from 'react-router-dom'

const Second_Pane = () => {

    return (
        <section className="">
            <div className="uk-container">
               <div className="uk-grid " data-uk-grid>
                    <div className="uk-width-1-5@s">
                               
                    </div>
                    <div className="uk-width-3-5@s">
                                    <h3 className="uk-text-center uk-text-bold  header3"  uk-scrollspy="cls: uk-animation-fade; repeat: true">Our best asset is our team of great teachers</h3>
                                    <p className="uk-text-center uk-margin-top" uk-scrollspy="cls: uk-animation-fade; repeat: true">We pick and choose those with a passion for the subject and
                                     a way to inspire students to follow. Those with experience in teaching go to the top of the list.
                                        </p>
                             </div>

                            <div className="uk-width-1-5@s">

                            </div>
               </div>

                 

                 <div className="uk-margin-large-top uk-flex uk-flex-center ">
                     <div className="mediumContainerLarge">
               <div className="uk-grid-medium " data-uk-grid>
                          <div className="uk-width-1-3@s" uk-scrollspy="cls: uk-animation-slide-top; repeat: true">
                                    <div className="uk-card uk-card-default uk-card-body">
                                        <div className="uk-flex uk-flex-center">
                                          <img src={inter} />
                                      </div>
                                      <div>
                                              <h6 className="uk-text-center uk-margin-small-top uk-text-bold">Extensive background check</h6>
                                              <p className="uk-text-center iconParagraphSmall uk-margin-top">We take our time to make sure only those with a clean background 
                                              and a teaching history get to guide our students.</p>
                                      </div>                                       
                                </div>
                         </div>


                         <div className="uk-width-1-3@s uk-margin-large-top" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                                    <div className="uk-card uk-card-default uk-card-body">
                                        <div className="uk-flex uk-flex-center">
                                          <img src={ass} />
                                      </div>
                                      <div>
                                              <h6 className="uk-text-center uk-margin-small-top uk-text-bold">Mastery of the subject</h6>
                                              <p className="uk-text-center iconParagraphSmall uk-margin-top">Our future teachers have to pass an assessment of their mastery 
                                              of the subject they teach. We rely on the best to lead the best on their future academic or alternative learning path.</p>
                                      </div>                                       
                                </div>
                         </div>


                         <div className="uk-width-1-3@s" uk-scrollspy="cls: uk-animation-slide-top; repeat: true">
                                    <div className="uk-card uk-card-default uk-card-body">
                                        <div className="uk-flex uk-flex-center">
                                          <img src={ass2} />
                                      </div>
                                      <div>
                                              <h6 className="uk-text-center uk-margin-small-top uk-text-bold">Select those especially gifted</h6>
                                              <p className="uk-text-center iconParagraphSmall uk-margin-top">Top teachers have that special something to make students curious and keep them engaged. Extra points for making learning fun.</p>
                                      </div>                                       
                                </div>
                         </div>


                     </div>

                     {/**DESKTOP**/}
                     <div className="uk-margin-large-top uk-visible@s" data-uk-grid>
                    <div className=" uk-margin-top">
                    <h3 className=" uk-text-bold  header3"  uk-scrollspy="cls: uk-animation-fade; repeat: true">
                        Feel like you could be in our team? <Link to ="/contactus"><span className="joinTeamCTA uk-margin-left">Contact Us</span></Link></h3>
                                        
                    </div>
                        
               </div>

                 {/**MOBILE**/}
               <div className="uk-margin-large-top uk-hidden@s" data-uk-grid>
                    <div className=" uk-margin-top">
                    <h3 className=" uk-text-bold  header3"  uk-scrollspy="cls: uk-animation-fade; repeat: true">
                        Feel like you could be in our team? <Link to ="/contactus"><span className="joinTeamCTA " >Contact Us</span></Link></h3>
                                        
                    </div>
                        
               </div>

                  </div>

              </div>


                </div>

                  






        </section>
    )
}


export default Second_Pane;