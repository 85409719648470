import {
  BodyText,
  PageBlock,
  PrimaryText,
  TickLabels,
  HeaderBox,
  HeaderTitle,
  PrimaryButton,
  HeaderDescription,
  SecondaryText,
  CourseBlock,
} from "../../features/Atoms";
import Layout from "../Layout";
import "./techCamp.css";
import {
  Grid,
  Box,
  Paper,
  CardMedia,
  Card,
  CardContent,
  Divider,
  Hidden,
  Typography,
} from "@material-ui/core";

import computer_courses_icon from "./../../images/computer-courses-icon.png";
import tech_camps_icon from "./../../images/tech-camps-icon.png";
import courses_at_school_location_icon from "./../../images/courses-at-school-location-icon.png";
import indexImg from "./../../images/Indexbackground.jpg";

import First_Pane from "./Block/First_pane";
import Second_Pane from "./Block/Second_Pane";
import Fourth_Pane from "./Block/Fourth_Pane";
import Fifth_Pane from "./Block/Fifth_Pane";
import Sixth_Pane from "./Block/Sixth_Pane";
import Seventh_Pane from "./Block/Seventh_Pane";
import axios from "axios";

import indexBg from "./../../images/indexBg.jpg";
import whychooseicon5 from "./../../images/whychooseicon5.png";
import Teachers_page from "./../../images/Teachers_page.jpg";
import teacher_lap from "./../../images/ty-860x573.jpg";
import Teachers_page2 from "./../../images/Teachers_page2.jpg";
import blog3 from "./../../images/blog3-585x390.png";
import best_selection from "./../../images/best_selection.png";
import indepth_ass from "./../../images/indepth_ass.png";
import interviews from "./../../images/interviews.png";
import staff from "./../../images/staff.PNG";
import logos from "./../../images/logos.PNG";
import logoBack from "./../../images/logo-back.PNG";
import { Link } from "react-router-dom";
import { CourseTypeButton } from "../HomePage/Blocks/CourseTypesBlock";
import EliteTeachersBlock from "../HomePage/Blocks/AmazingTeachersBlock";
import TestimonialBlock from "../HomePage/Blocks/TestimonialBlock";
import onlineClassroom from "./../../images/online-classroom-47-2-540x482.png";
import tech_landing_bg from "./../../images/tech_landing_bg-860x486.jpg";
import student_bg from "./../../images/student_bg-380x335.jpg";
import techcamp_pic from "./../../images/techcamp_pic.png";
import tech_laptop_graph_pic from "./../../images/laptop-graph-left3-small-380x380.png";
import techBg from "./../../images/techCampBg.jpg";
import techBgSmall from "./../../images/techCampBgSmall.jpg";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadAllVirtualTechCamp } from "../../DataStore/CourseReducer";
import Data from "../../Data";
import Api from "../../Api";
import { useAuthorize } from "../../features/Authentication/Authorize";

export default function TechCampCoursePage(props) {
  const isTeacher = useAuthorize("TEACHER");
  const isStudent = useAuthorize("STUDENT");
  const courseListStatus = useSelector(
    (state) => state.course.loadAllVirtualTechCamp
  );

  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const [courseList, setCourseList] = useState();
  const [codingList, setCodingList] = useState();
  const [gameList, setGameList] = useState();
  const [atschool, setAtschool] = useState([]);
  useEffect(() => {
    if (!mounted) {
      dispatch(
        loadAllVirtualTechCamp({
          courseCategory: [Data.Config.CourseCategory.TECH_CAMP.id],
        })
      );
      setMounted(true);
    }

    axios
      .get(Api.School.loadAtSchoolCourses())
      .then(function (response) {
        const mydata = response.data;
        // console.log("this is my data",mydata)
        setAtschool(mydata);
        console.log("this should work", mydata);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  const registerHandle = () => {
    document.getElementById("authButton")?.click();
  };

  return (
    <Layout>
      <section
        className="uk-visible@s"
        style={{
          backgroundImage: `url(${techBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "100px",
        }}
      >
        <div className="uk-container uk-padding">
          <div className="uk-grid " data-uk-grid>
            <div className="uk-width-2-3@s  ">
              <div className="uk-margin-xlarge-top ">
                <h1
                  className="header1 light withPadding uk-padding"
                  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                >
                  {" "}
                  Experience fun and learn with CyberTeck Academy Camps{" "}
                </h1>
                <p
                  className="subTitleText "
                  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                >
                  Put that school-free time to good use and get your child
                  excited about tech. Technology-focused courses to make your
                  child part of a smart group of friends.
                </p>
                <div class="uk-flex withPadding ">
                  <Link to="/contactus">
                    <div
                      class="btn uk-text-center "
                      uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                    >
                      Register Now{" "}
                    </div>{" "}
                  </Link>
                </div>
              </div>

              <div class="uk-flex uk-margin-large-top ">
                <div>
                  {!isStudent && !isTeacher && (
                    <Link
                      rounded
                      component={PrimaryButton}
                      onClick={registerHandle}
                    >
                      Register
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="uk-width-1-3@s"></div>
          </div>
        </div>
      </section>

      {/**Mobile***/}
      <section
        className="uk-hidden@s"
        style={{
          backgroundImage: `url(${techBgSmall})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "50px",
        }}
      >
        <div className="uk-container uk-padding">
          <div className="uk-grid " data-uk-grid>
            <div className="uk-width-2-3@s  ">
              <div className="uk-margin-xlarge-top ">
                <h1
                  className="header1 light withPadding uk-padding"
                  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                >
                  {" "}
                  Experience fun and learn with CyberTeck Academy Camps{" "}
                </h1>
                <p
                  className="subTitleText "
                  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                >
                  Put that school-free time to good use and get your child
                  excited about tech. Technology-focused courses to make your
                  child part of a smart group of friends.
                </p>
                <div class="uk-flex withPadding ">
                  <Link to="/contactus">
                    <div
                      class="btn uk-text-center "
                      uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                    >
                      Register Now{" "}
                    </div>{" "}
                  </Link>
                </div>
              </div>

              <div class="uk-flex uk-margin-large-top ">
                <div>
                  {!isStudent && !isTeacher && (
                    <Link
                      rounded
                      component={PrimaryButton}
                      onClick={registerHandle}
                    >
                      Register
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="uk-width-1-3@s"></div>
          </div>
        </div>
      </section>

      <First_Pane />
      <Second_Pane />
      <div className=" uk-container">
        <div className="uk-grid" data-uk-grid>
          <div className="uk-width-1-5@s"></div>

          <div className="uk-width-3-5@s">
            <h1 className="header1 uk-text-center">
              Tech Camps during <span className="greenText">Tech Camp </span>{" "}
              Summer and Spring Break
            </h1>
            <p className="uk-text-center ">
              Make your child’s free time exciting and useful. Browse our
              courses and choose a favorite tech camp with fun animation and
              game design courses.
            </p>
          </div>

          <div>
            <div className="uk-margin-large-top">
              <ul
                class=" uk-subnav uk-subnav-pill uk-margin-large-left"
                uk-switcher="animation: uk-animation-fade"
                style={{ marginTop: 20, textAlign: "center" }}
              >
                <li>
                  <a href="#" className="activeTabBtn">
                    Virtual Tech Camps Courses
                  </a>
                </li>
                <li>
                  <a href="#" className="InactiveTabBtn">
                    At School Location Courses
                  </a>
                </li>
                
              </ul>

              <ul class="uk-switcher uk-margin">
                {/*First Tab All courses*/}
                <li>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ marginTop: 20, textAlign: "center" }}
                  >
                    {courseListStatus.status === "FULFILLED" &&
                      courseListStatus.data &&
                      Object.values(Data.Config.Grade).map((grade) => (
                        <>
                          <Grid xs={12} item disableGutter>
                            <SecondaryText
                              className="greenText"
                              style={{ fontSize: "28px" }}
                            >
                              Grade {grade.displayName}
                            </SecondaryText>
                          </Grid>
                          <Grid xs={12} item>
                            <Box
                              container
                              spacing={5}
                              component={Grid}
                              style={{ padding: "0px 7%" }}
                            >
                              {courseListStatus.data
                                .filter((course) => course.grade === grade.id)
                                .slice(0, 6)
                                .map((item) => (
                                  <Grid item xs={12} sm={6} md={4}>
                                    <CourseBlock
                                      to={`/courses/${
                                        item.id
                                      }/${item.title.replaceAll(" ", "-")}`}
                                      label={item.title}
                                      grade={Data.Config.get(
                                        Data.Config.Grade,
                                        item.grade,
                                        "displayName"
                                      )}
                                      courseType={Data.Config.get(
                                        Data.Config.CourseType,
                                        item.courseType,
                                        "displayName"
                                      )}
                                      price={item.price}
                                      mrpPrice={item.mrpPrice}
                                      coverPic={item.thumbnil}
                                    />
                                  </Grid>
                                ))}
                            </Box>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </li>

                
                {/*Second Tab All courses at school location */}
                <li>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ marginTop: 20, textAlign: "center" }}
                  >
                    {
                      atschool.data &&
                      Object.values(Data.Config.Grade).map((grade) => (
                        <>
                          <Grid xs={12} item disableGutter>
                            <SecondaryText
                              className="greenText"
                              style={{ fontSize: "28px" }}
                            >
                              Grade {grade.displayName}
                            </SecondaryText>
                          </Grid>
                          <Grid xs={12} item>
                            <Box
                              container
                              spacing={5}
                              component={Grid}
                              style={{ padding: "0px 7%" }}
                            >
                              {atschool.data
                                .filter((course) => course.grade === grade.id)
                                .slice(0, 6)
                                .map((item) => (
                                  <Grid item xs={12} sm={6} md={4}>
                                    <CourseBlock
                                      to={`/courses/${
                                        item.id
                                      }/${item.title.replaceAll(" ", "-")}`}
                                      label={item.title}
                                      grade={Data.Config.get(
                                        Data.Config.Grade,
                                        item.grade,
                                        "displayName"
                                      )}
                                      courseType={Data.Config.get(
                                        Data.Config.CourseType,
                                        item.courseType,
                                        "displayName"
                                      )}
                                      price={item.price}
                                      mrpPrice={item.mrpPrice}
                                      coverPic={item.thumbnil}
                                    />
                                  </Grid>
                                ))}
                            </Box>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </li>

               

               
              </ul>
            </div>

            <div className="uk-margin-large-top uk-margin-large-bottom">
              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-1-5@s"></div>
                <div className="uk-width-3-5@s">
                  <h3 className="header1 uk-text-center">In-Person</h3>
                  <p className="uk-text-center ">
                    {" "}
                    To reach everyone’s needs, our Computer Courses will be
                    available with a live instructor. We make sure our teachers
                    are present at your location, both for In-Person courses and
                    for Virtual settings.
                  </p>
                  <div class="uk-flex uk-flex-center  ">
                    <div
                      class="btn uk-text-center uk-margin-medium-top"
                      uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                    >
                      Virtual Courses
                    </div>
                  </div>
                </div>

                <div className="uk-width-1-5@s"></div>
              </div>
            </div>
          </div>

          <div className="uk-width-1-5@s"></div>
        </div>
      </div>

      {/* <PageBlock style={{position: "relative", top: "-90px"}}>
              
                <Grid item xs={12}>
                </Grid>{
                    courseListStatus.status === 'FULFILLED' &&
                    courseListStatus.data &&
                    Object.values(Data.Config.Grade).map(grade =>
                        <>
                            <Grid xs={12} item disableGutter>
                                <SecondaryText className="greenText" style={{ fontSize: '28px' }}>
                                    Grade {grade.displayName}
                                </SecondaryText>
                            </Grid>
                            <Grid xs={12} item>
                                <Box container spacing={5} component={Grid} style={{ padding: '0px 7%' }}>
                                    {
                                        courseListStatus.data
                                            .filter(course => course.grade === grade.id)
                                            .slice(0, 6)
                                            .map(item =>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <CourseBlock
                                                        to={`/courses/${item.id}/${item.title.replaceAll(' ', '-')}`}
                                                        label={item.title}
                                                        grade={Data.Config.get(Data.Config.Grade, item.grade, 'displayName')}
                                                        courseType={Data.Config.get(Data.Config.CourseType, item.courseType, 'displayName')}
                                                        price={item.price}
                                                        mrpPrice={item.mrpPrice}
                                                        coverPic={item.thumbnil}
                                                    />
                                                </Grid>
                                            )
                                    }
                                </Box>
                            </Grid>
                        </>
                    )
                }
                <Grid xs={12} item>
                    <Link to="/courses" component={PrimaryButton}>View All Courses & Plan</Link>
                </Grid>
            </PageBlock> */}

      {/* <Fourth_Pane /> */}
      <div className="uk-margin-medium-top">
        {" "}
        <TestimonialBlock />{" "}
      </div>
      <EliteTeachersBlock />
      <Fifth_Pane />
      <Sixth_Pane />
      <Seventh_Pane />
    </Layout>
  );
}
